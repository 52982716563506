import { PROPERTY, APPLICATION, SUBSCRIBER } from '../constants/actionTypes/pageConstants';

export const initialState = {
    pageApp: 1,
    pageProp: 1,
    pageSubs: 1,
};

export function reducer(state: any = initialState, action: any) {
  switch (action.type) {
    case PROPERTY:
        return {
            pageProp: action.page,
            pageApp: 1,
            pageSubs: 1,
        };
    case APPLICATION:
        return {
            pageApp: action.page,
            pageProp: 1,
            pageSubs: 1,
        };
    case SUBSCRIBER:
        return {
            pageSubs: action.page,
            pageApp: 1,
            pageProp: 1,
        }
    default:
        return initialState;
  }
}
