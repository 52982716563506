import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Form, Input, notification, Row, Select, Tag } from 'antd';
import SubscribersService from '../../services/SubscribersService';
import DictionariesServices from '../../services/DictionariesServices';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { SubscriberModel, SubscriberModelDto } from '../../models/SubscriberModel';
import { validateMessages } from '../../constants/validateMessages';
import { SubscriberTypes } from '../../constants/SubscriberTypes';
import ListPageServices from '../../services/PropertiesService';
import moment from 'moment';
import { BACKEND_DATE_TIME_FORMAT } from '../../constants/formats';
import { PropertyShort } from '../../models/PropertyModel';
import { emailInitiatingStatuses, subscriberStatusColors } from '../../constants/SubscribersStatuses';

const { Option } = Select;
const { TextArea } = Input;

const SubscriberEditPage = (props: any) => {
  const [form] = Form.useForm();

  const service = new SubscribersService();
  const dictionariesService = new DictionariesServices();
  const propertiesService = new ListPageServices();

  const [item, setItem] = useState<SubscriberModel | null>(null);
  const [isSaving, setIsSaving] = useState(false);

  const [types, setTypes] = useState<SubscriberModel['type'][]>([]);
  const [statuses, setStatuses] = useState<SubscriberModel['status'][]>([]);
  const [properties, setProperties] = useState<PropertyShort[]>([]);

  useEffect(() => {
    props?.match?.params?.id ? getData() : setItem(null);
    getTypes();
    getStatuses();
    getPropertiesShortlist();
  }, []);

  useEffect(() => {
    form.resetFields();
  }, [item]);

  const getTypes = () => {
    dictionariesService.getDictionary<Array<SubscriberModel['type']>>('subscriberTypes').then(data => {
      setTypes(data);
    });
  };

  const getStatuses = () => {
    dictionariesService.getDictionary<Array<SubscriberModel['status']>>('subscribersStatuses').then(data => {
      setStatuses(data);
    });
  };

  const getPropertiesShortlist = () => {
    propertiesService.getPropertyShort().then(data => {
      setProperties(data)
    });
  }

  const getData = () => {
    service.getItem(props.match.params.id).then(data => {
        setItem({
          ...data
        });
        setIsContactForm(!!(data.type.code === SubscriberTypes.contactForm));
    });
  };

  const onFinish = async (values: any) => {
    setIsSaving(true);
    const toUpdate: SubscriberModelDto = {
      id: item?.id,
      propertyId: values.property.id || null,
      type: values.type.code,
      email: values.email,
      phone: values.phone || null,
      message: values.message || null,
      note: values.note || null,
      fullName: values.fullName || null,
      status: values.status,
    }

    service.saveItem(toUpdate).then(async (res: any) => {
        notification.success({message: `Subscriber ${props?.match?.params?.id ? 'updated' : 'created'}`});
        !props?.match?.params?.id ? props.history.push(`/subscribers/${res.id}`) : getData();
        setIsSaving(false);
    }).catch(() => {
      notification.error({ message: 'Error while updating subscriber.' });
      setIsSaving(false);
    });
  }

  const isUpdate = !!props.match.params.id;
  const [isContactForm, setIsContactForm] = useState(false);
  const handleTypeSelect = (value: SubscriberTypes) => setIsContactForm(!!(value === SubscriberTypes.contactForm))

  return (
      <Form 
        layout="vertical" 
        onFinish={onFinish} 
        form={form}
        name="subscriber"
        initialValues={item || {}}
        validateMessages={validateMessages}
      >
        <HeaderTable 
          title={`${props?.match?.params?.id ? 'Edit' : 'Create'}`}
          breadcrumb={{
            '/subscribers': 'Subscribers',
            '/subscriber': `${props?.match?.params?.id ? 'Edit' : 'Create'}`
          }}>
          <div>
            <Button name="submitButton" type="primary" htmlType="submit" loading={isSaving}>
              Save changes
            </Button> 
          </div>
        </HeaderTable>
        <div className="application-tab-container">
        <Card bordered={false} loading={!item && !!props?.match?.params?.id}>
          <Row gutter={32}>
            <Col span={6}>
              <div className="application-col">
                <Form.Item label="Full name" name={['fullName']}>
                  <Input />
                </Form.Item>
                <Form.Item label="Email" name={['email']} rules={[{ required: true }]}>
                  <Input />
                </Form.Item>
                <Form.Item label="Type" name={['type', 'code']} rules={[{ required: true }]}>
                <Select style={{width: '100%'}} disabled={isUpdate} onChange={handleTypeSelect}>
                  {types.map((type) => <Option key={type.code} value={type.code}>{type.description}</Option>)}
                </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={6}>
              <div className="application-col">
                <Form.Item label="Phone" name={['phone']}>
                  <Input />
                </Form.Item>
                <Form.Item label="Submit date" name={['submitDate']}>
                {!!item?.submitDate && moment(item.submitDate).format(BACKEND_DATE_TIME_FORMAT)}
                </Form.Item>
                <Form.Item label="Property" name={['property', 'id']} rules={[{required: isContactForm}]}>
                  <Select
                    style={{ width: '100%' }}
                    showSearch
                    optionFilterProp='children'
                    disabled={!isContactForm}
                    filterOption={(input, option) =>
                      !!option ? option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0 : false
                    }
                    filterSort={(optionA, optionB) =>
                      optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    }
                    >
                    {properties.map((property) => <Option key={property.id} value={property.id}>{property.code}</Option>)}
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col span={6}>
              <div className="application-col">
                <Form.Item label="Status" name={['status', 'code']}>
                  <Select>
                    {statuses.map((status: any) => (
                      <Option
                        key={status.code}
                        value={status.code}
                      >
                        <Tag
                          style={{
                            backgroundColor: subscriberStatusColors[status.code as keyof typeof subscriberStatusColors]?.color || '#fff',
                            color: subscriberStatusColors[status.code as keyof typeof subscriberStatusColors]?.textColor || '#000',
                          }}
                        >
                          {status.description}{emailInitiatingStatuses.includes(status.code) && '\t\u2709'}
                        </Tag>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>

              </div>
            </Col>
        </Row>
          <Row gutter={12}>
            <Col span={18}>
              <Form.Item label="Message" name={['message']}>
                <TextArea rows={2} />
              </Form.Item>
              <Form.Item label="Notes" name={['note']}>
                <TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
        </Card>
        </div>
      </Form>
  );
};

export default SubscriberEditPage;
