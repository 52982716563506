import React, {createContext, useReducer} from 'react';
import {AppState, initialState, reducer} from '../reducers/appReducer';

export const AppContext = createContext(({} as any));

const AppContextProvider: React.FC = (props: any) => {
    const [app, appDispatch] = useReducer(reducer, initialState);

    return (
        <AppContext.Provider value={{ app, appDispatch }}>
            {props.children}
        </AppContext.Provider>
    );
};

export default AppContextProvider;