import React from 'react';
import { Badge, Divider, Button, Modal } from 'antd';
import {NavLink} from "react-router-dom";
import PropertyStatuses from "../../../models/enums/PropertyStatuses";
import PropertyTechTypes from "../../../models/enums/PropertyTechTypes";
import { ExclamationCircleOutlined } from '@ant-design/icons';

const { confirm } = Modal;

const PREVIEW_URL = process.env.REACT_APP_PREVIEW;

const getStatusTag = (code: string) => {
  if (code === PropertyStatuses.sold) return 'error';
  return 'success';
}

export const getColumns = (deleteProperty: (id: number) => Promise<void>) => ([
  {
    title: 'Number',
    sorter: (a: any, b: any) => Number(a.id) - Number(b.id),
    dataIndex: ['id'],
  },
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'name',
  },
  {
    title: 'Technical type',
    dataIndex: ['techType'],
    key: 'techType',
    render: (cell: string) => cell.toLocaleUpperCase(),
  },
  {
    title: 'Type',
    dataIndex: ['type', 'description'],
    key: 'type',
  },
  {
    title: 'Property price',
    dataIndex: ['price'],
    key: 'price',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (cell: any) => !!cell && <Badge status={getStatusTag(cell.code)} text={cell.description} />
  },
  {
    title: 'Visibility',
    dataIndex: 'published',
    key: 'published',
    render: (cell: any) => <Badge status={cell ? 'success' : 'error'} text={cell ? 'Publish' : 'Unpublish'} />
  },
  {
    title: 'Action',
    key: 'action',
    render: (cell: any, row: any) => {
      const hanleDeleteBtn = () => {
        confirm({
          title: `Do you want to change this property?`,
          icon: <ExclamationCircleOutlined />,
          content: 'Children of this property will be also deleted if exist',
          onOk() {
            deleteProperty(row.id);
          },
        });
      }
      return (
        <span>
          <>
            <Button type="link" target="_blank" href={`${PREVIEW_URL}/${row.id}`}>Preview</Button>
            <Divider type="vertical" />
            <NavLink to={`/property/${cell.id}?type=${cell.techType}`}>Edit</NavLink>
            <Divider type="vertical" />
            <NavLink to={`/property?duplicate=${cell.id}&type=${cell.techType}`}>Duplicate</NavLink>
            <Divider type="vertical" />
            <Button type="link" onClick={hanleDeleteBtn}>Delete</Button>
          </>
        </span>
      )
    },
  },
]);
