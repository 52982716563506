import React from 'react';
import { Row, Col, Button, Select, Input, Form, DatePicker } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;
import { getRangePickerDefault } from '../../../helpers/getRangePickerDefault';

interface ISubscribersSearchProps {
    types: any
    filters: any,
    setFilters: any,
}

export const SubscribersSearch = ({ types, filters, setFilters }: ISubscribersSearchProps) => {


    const [form] = Form.useForm();

    const ResetFilters = () => {
        setFilters({});
        form.resetFields();
    }

    const SetFilters = () => {
        setFilters({ ...filters, ...(form.getFieldsValue()) });
    }

    return (
        <>
            <Form form={form}>
                <Row>
                    <Col span={5} style={{ paddingRight: '30px' }}>
                        <Form.Item name="fullName" label="Full name" >
                            <Input allowClear onPressEnter={SetFilters} />
                        </Form.Item>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ paddingRight: '30px' }}>
                        <Form.Item name="email" label="Email" >
                            <Input allowClear onPressEnter={SetFilters} />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingRight: '30px' }}>
                        <Form.Item name="type" label="Type">
                            <Select allowClear>
                                {types.map((type: any) => (
                                    <Option
                                        key={type.code}
                                        value={type.code}
                                    >
                                        {type.description}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={6} style={{ paddingRight: '30px' }}>
                        <Form.Item name="submitDateRange" label="Submit date">
                            <RangePicker defaultValue={getRangePickerDefault()} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={4}>
                        <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button className="secondary-button" onClick={ResetFilters}>
                                Reset
                            </Button>
                            <Button className="primary-button" onClick={SetFilters} style={{ marginLeft: '10px' }}>
                                Search
                            </Button>
                        </span>
                    </Col>
                </Row>
            </Form>
        </>
    );
};