import { ApplicationModel } from "../models/ApplicationModel";

export function getHomebuyerFullname(homebuyer: ApplicationModel['homebuyer']) {
    if(!homebuyer.lastName) {
        return homebuyer.firstName || '';
    }

    if(!homebuyer.firstName) {
        return homebuyer.lastName || '';
    }
    
    return `${homebuyer.firstName} ${homebuyer.lastName}`;
}