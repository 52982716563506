import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {Layout} from 'antd';

import {AppContext} from '../../contexts/AppContextProvider';

import HeaderComponent from '../../components/common/HeaderComponent';
import FooterComponent from '../../components/common/FooterComponent';
import NavComponent from '../../components/common/NavComponent';
import DashboardPage from '../../views/Dashboard/DashboardPage';
import NotFoundPage from '../../views/common/NotFoundPage';
import EmptyPage from '../../views/common/EmptyPage';
import PropertiesPage from '../../views/Properties/PropertiesPage';
import PropertyEditPage from '../../views/Properties/PropertyEditPage';
import NavContextProvider from '../../contexts/NavContextProvider';
import ListContextProvider from '../../contexts/ListContextProvider';
import PageContextProvider from '../../contexts/PageContextProvider';
import ApplicationsPage from '../../views/Applications/ApplicationsPage';
import ApplicationsEditPage from '../../views/Applications/ApplicationsEditPage';
import BlogEditPage from '../../views/Blog/BlogEditPage';
import BlogPage from '../../views/Blog/BlogPage';
import FaqEditPage from '../../views/Faq/FaqEditPage';
import FaqPage from '../../views/Faq/FaqPage';
import SubscribersPage from '../../views/Subscribers/SubscribersPage';
import SubscriberEditPage from '../../views/Subscribers/SubscriberEditPage';
import { useHasCurrentRule } from '../../services/hooks';
import { Roles } from '../../constants/userRoles';
import CustomerApplicationPage from '../../views/Customer/CustomerApplicationPage';

const {Content} = Layout;

const DefaultLayout: React.FC = props => {
  const { app } = useContext(AppContext);
  const isCustomer = useHasCurrentRule(Roles.customer);

  return (
    <NavContextProvider>
      <Layout style={{minHeight: '100vh'}}>
        <NavComponent {...props} />
        <Layout>
          <HeaderComponent {...props} />
          <Content
            style={{
              margin: '24px 16px',
              padding: 24,
              background: '#fff',
              minHeight: 280,
            }}
          >
            {app.isAuthenticated ? (
              isCustomer ? (
                <Switch>
                    <Route exact path="/application/:id?" name="Customer application" component={CustomerApplicationPage}/>
                    <Route component={() => <Redirect to="/application" />}/>
                </Switch>
              )
              :
              (
                <Switch>
                  <Route exact path="/dashboard" name="Dashboard" component={DashboardPage}/>
                  <Route exact path="/empty" name="Empty" component={EmptyPage}/>
                  <Route path="/blog" name="Blog" component={BlogPage}/>
                  <Route path="/blogItem/:id?" name="BlogEntity" component={BlogEditPage}/>
                  <Route path="/faq" name="Faq" component={FaqPage}/>
                  <Route path="/faqItem/:id?" name="FaqEntity" component={FaqEditPage}/>
                  <PageContextProvider>
                    <Route path="/subscribers" name="Subscribers" component={SubscribersPage} />
                    <Route path="/subscriber/:id?" name="Subscriber" component={SubscriberEditPage} />
                    <Route path="/applications" name="Applications" component={ApplicationsPage} />
                    <Route path="/application/:id?" name="Application" component={ApplicationsEditPage}/>
                    <ListContextProvider>
                      <Route exact path="/property/:id?" name="CreateProperty" component={PropertyEditPage}/>
                      <Route path="/properties" name="Properties" component={PropertiesPage}/>
                    </ListContextProvider>
                  </PageContextProvider>
                  <Route component={NotFoundPage}/>
                </Switch>
              )
            ) : (
              <Switch>
                <Redirect to="/login"/>
              </Switch>
            )}
          </Content>
          <FooterComponent/>
        </Layout>
      </Layout>
    </NavContextProvider>
  );
};

export default DefaultLayout;
