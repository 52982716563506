import fetchIntercept from 'fetch-intercept';
import {failedTofetch, tokenExpired} from '../../constants/tokenFailConstants';
import { CUSTOMER_LANG_KEY } from '../../utils/i18n';

export function callInterceptor() {
  fetchIntercept.register({
    request: function (url: any, config: any) {
      const id_token = localStorage.getItem('id_token') ? JSON.parse(localStorage.getItem('id_token') as string).access_token : null;
      if (id_token) {
        if (config.headers) {
          Object.assign(config.headers, config.headers, {
            'Authorization': 'Bearer ' + id_token
          });
        } else {
          config.headers = {
            'Authorization': 'Bearer ' + id_token
          };
        }
      }

      const customerLanuage = sessionStorage.getItem(CUSTOMER_LANG_KEY);
      if (customerLanuage) {
        if (config.headers) {
          Object.assign(config.headers, config.headers, {
            'Accept-Language': customerLanuage
          });
        } else {
          config.headers = {
            'Accept-Language': customerLanuage
          };
        }
      }

      return [url, config];
    },

    responseError: function (error: any) {
      if(error.message === 'Failed to fetch') {
        window.location.href = `${window.location.origin}/login?type=${failedTofetch}`;
      }
      return Promise.reject(error);
    },

    response: function (res: any) {
      if (res.status === 401 || (res.status === 500 && res.message === 'Token expired')) {
        window.location.href = `${window.location.origin}/login?type=${tokenExpired}`;
        return null;
      } else {
        return res;
      }
    },
  });
}