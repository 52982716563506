import React from 'react';
import {Card, Cascader, Col, DatePicker, Form, Input, Row, Select, Spin, Switch} from 'antd';
import { GroupModel } from '../../../models/GroupModel';
import {InputDecimal, InputInteger} from '../../../components/common/antdFormatter';
import DynamicFields from './DynamicFields';
import { redirectToGoogleMaps } from '../../../helpers/OpenUrlInNewTab';
import {
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons';

const { Option } = Select;

export interface AppartmentCardModel {
    isLoading: boolean,
    statuses: any[],
    types: any[],
    brokers: any[],
    groups: GroupModel[],
    orientations: any[],
    features: any[],
    wobblers: any[],
    isShowAddSelect: boolean,
    handleShowSelect: (isShow?: any) => void;
    propertyFields: any[],
    item: any,
    isGroupsTreeLoading: boolean;
    setAddressModalData: React.Dispatch<any>;
}

const AppartmentCard: React.VFC<AppartmentCardModel> = ({
    isLoading,
    statuses,
    types,
    brokers,
    groups,
    orientations,
    features,
    wobblers,
    isShowAddSelect,
    handleShowSelect,
    propertyFields,
    item,
    isGroupsTreeLoading,
    setAddressModalData,
}) => {
    return <Card style={{marginBottom: '24px'}} loading={isLoading}>
    <Row gutter={12}>
      <Col span={12}>
        <Form.Item label="Status" name={["status", "code"]}>              
          <Select>
            {statuses.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Type" name={["type", "code"]}
              rules={[{
                required: true,
                message: 'Please select property type',
              }]}>
            <Select style={{width: '100%'}}>
              {types.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
            </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Code" name="code" 
          rules={[{required: true, message: 'Please input property code'}]}>
          <Input/>
        </Form.Item>
      </Col>
      <Col span={9}>
        <Form.Item label="Broker:" name={["broker", "id"]}>
            <Select style={{width: '100%'}}>
              {brokers.map((item: any) => <Option key={item.id} value={item.id}>{[item.firstName, item.lastName].join(' ')}</Option>)}
            </Select>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label={<br/>} valuePropName="checked" name={["showBrokerInfo"]}>
          <Switch style={{width: '100%'}} checkedChildren="Show" unCheckedChildren="Hide"/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Spin spinning={isGroupsTreeLoading}>
          <Form.Item label="Group / Building" name={["parent"]}>
              <Cascader
                options={groups}
                changeOnSelect
                fieldNames={{ label: 'code', value: 'id', children: 'childrens' }}
              />
          </Form.Item>
        </Spin>
      </Col>
      <Col span={12}>
        <Form.Item label="Price" name="price"rules={[
              {
                required: true,
                message: 'Please input property price',
              }]}>
          <InputInteger length={10} prefix={<span>₣</span>} suffix={<span>CHF</span>}/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Location" name={["address", "addressFull"]}>
          <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["address"])} />}
            {...(item?.address?.lat && item?.address?.lng ? {
              addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                item?.address?.lat,
                item?.address?.lng
              )} />
            } : {})} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Apartment №" name="aptNo">
            <Input/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Url" name="url">
            <Input/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Floor" name="floor" >
            <Input/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Rooms" name="rooms"
          rules={[{
            required: true,
            message: 'Please input property rooms number',
          }]}>
            <InputDecimal length={3} precision={1}/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Total surface" name="totalSurface" 
          rules={[{
            required: true,
            message: 'Please input property total surface',
          }                    
          ]}>
            <InputDecimal length={4} precision={2} suffix={<span>m<sup>2</sup></span>}/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Orientation" name="orientationTypes"
          rules={[
            {
              required: false,
              message: 'Please select orientation',
            }
          ]}>
            <Select style={{width: '100%'}}>
              {Object.entries(orientations).map(([key, value]) => <Option key={key} value={key}>{value}</Option>)}
            </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Availability date" name="availabilityDate">
            <DatePicker style={{width: '100%'}}/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Features" name={["features", "code"]}>
            <Select mode="multiple">
              {features.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
            </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Wobbler" name={["wobbler", "code"]}>
            <Select style={{width: '100%'}} allowClear>
              {wobblers.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
            </Select>
        </Form.Item>
      </Col>
      <DynamicFields
        name="fields"
        isSelect={isShowAddSelect}
        handleSelect={handleShowSelect}
        fields={propertyFields}
        values={item?.fields || {}}
      />
    </Row>
  </Card>
}

export default AppartmentCard;