import React from 'react';
import { Badge, Button, Divider } from 'antd';
import {NavLink} from "react-router-dom";
import{ BACKEND_DATE_TIME_FORMAT } from '../../../constants/formats';
import {getStatusColor} from '../BlogEditPage';
import moment from 'moment';
import {redirectBlogPreview} from '../../../helpers/OpenUrlInNewTab';

export const columns = [
  {
    title: 'Number',
    sorter: (a: any, b: any) => Number(a.id) - Number(b.id),
    dataIndex: ['id'],
  },
  {
    title: 'Title',
    render: (cell: any, row: any) => row.translation.find((el: any) => el.language.code == 'fr').title,
  },
  {
    title: 'Excerpt',
    render: (cell: any, row: any) => row.translation.find((el: any) => el.language.code == 'fr').excerpt,
  },
  {
    title: 'Statuses',
    width: 235,
    dataIndex: 'status',
    render: (cell: any, row: any) => row.translation.sort((a: any, b: any) => a.id - b.id).map((item: any) => <>
      {item.language.code.toUpperCase()}: <b style={{color: getStatusColor(item?.status?.code)}}>{item?.status?.description}</b>{!!item.publishedDate && <>, {moment(item.publishedDate).format(BACKEND_DATE_TIME_FORMAT)}</>}<br/>
    </>)
  },
  {
    title: 'Preview',
    dataIndex: 'preview',
    render: (cell: any, row: any) => row.translation.sort((a: any, b: any) => a.id - b.id).map((item: any) => <>
      <span className="span-link" onClick={() => redirectBlogPreview(row.id, item.slug, item.language.code)}>{item.language.description}</span><br/>
    </>)
  },
  {
    title: 'Action',
    key: 'action',
    render: (cell: any, row: any) => {
      return (
        <span>  
          <NavLink to={`/blogItem/${row.id}`}>Edit</NavLink>           
        </span>
      )
    },
  }
];
