import {useContext} from 'react';
import {AppContext} from '../contexts/AppContextProvider';

export const useHasCurrentRule = (rule: string) => {
  const { app } = useContext(AppContext);
  if (!app.roles) return false;
  return app.roles.indexOf(rule) !== -1;
};

export const getUserLogin = () => {
  const { app } = useContext(AppContext);
  if (!app.user.firstName) return 'User name';
  return `${app.user.firstName} ${app.user.lastName}`;
};