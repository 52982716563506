import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {
    Button,
    Row,
    Col,
    Card,
    Form,
    Input,
    Select,
    DatePicker,
    Checkbox,
    notification,
    Timeline,
    Statistic,
    Tabs,
    Tag,
    Popconfirm,
    Modal,
    Descriptions,
    Upload,
    Spin,
    Divider
} from 'antd';
import ApplicationService from '../../services/ApplicationService';
import DictionariesServices from '../../services/DictionariesServices';
import { redirectToGoogleMaps } from '../../helpers/OpenUrlInNewTab';
import { calculateFields } from './components/ApplicationsCalculations';
import Icon, {
    EyeOutlined,
    EditOutlined,
    FileDoneOutlined,
    CheckOutlined,
    CloseOutlined,
    ClockCircleOutlined,
    CloseCircleOutlined,
    CheckCircleOutlined,
    ExclamationCircleOutlined,
} from '@ant-design/icons';
import { objectToValidFormat } from '../../helpers/objectToValidFormat';
import { validateMessages } from '../../constants/validateMessages';
import { applicationRates as defaultApplicationRates } from './../../constants/applicationRates';
import AddressModal from './../../components/AddressModal/AddressModal';
import { BACKEND_DATE_TIME_FORMAT, FRONT_DATE_TIME_FORMAT } from './../../constants/formats';
import { emailInitiatingStatuses, ongoingDripStatuses, StatusColors } from '../../constants/applicationStatuses';
import ConsumerRegistrationService from '../../services/RegistrationService';
import { DictionaryModel } from '../../models/DictionaryModel';
import { ApplicationHomebuyerDocumentTypes } from '../../models/enums/ApplicationHomeBuyerDocumentTypes';
import { ApplicationHomebuyerDocument, ApplicationHomebuyerDocumentFile, ApplicationModel } from '../../models/ApplicationModel';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { getHomebuyerFullname } from '../../helpers/getHomebuyerFullname';
import type { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';
import Tooltip, { TooltipPropsWithTitle } from 'antd/lib/tooltip';

const { Option } = Select;
const { TabPane } = Tabs;
const { confirm } = Modal;

const CustomCloseSvg = () => (
    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.4158 6.00409L11.7158 1.71409C11.9041 1.52579 12.0099 1.27039 12.0099 1.00409C12.0099 0.73779 11.9041 0.482395 11.7158 0.294092C11.5275 0.105788 11.2721 0 11.0058 0C10.7395 0 10.4841 0.105788 10.2958 0.294092L6.0058 4.59409L1.7158 0.294092C1.52749 0.105788 1.2721 -1.9841e-09 1.0058 0C0.739497 1.9841e-09 0.484102 0.105788 0.295798 0.294092C0.107495 0.482395 0.0017066 0.73779 0.0017066 1.00409C0.0017066 1.27039 0.107495 1.52579 0.295798 1.71409L4.5958 6.00409L0.295798 10.2941C0.20207 10.3871 0.127676 10.4977 0.0769072 10.6195C0.0261385 10.7414 0 10.8721 0 11.0041C0 11.1361 0.0261385 11.2668 0.0769072 11.3887C0.127676 11.5105 0.20207 11.6211 0.295798 11.7141C0.388761 11.8078 0.499362 11.8822 0.621222 11.933C0.743081 11.9838 0.873786 12.0099 1.0058 12.0099C1.13781 12.0099 1.26852 11.9838 1.39038 11.933C1.51223 11.8822 1.62284 11.8078 1.7158 11.7141L6.0058 7.41409L10.2958 11.7141C10.3888 11.8078 10.4994 11.8822 10.6212 11.933C10.7431 11.9838 10.8738 12.0099 11.0058 12.0099C11.1378 12.0099 11.2685 11.9838 11.3904 11.933C11.5122 11.8822 11.6228 11.8078 11.7158 11.7141C11.8095 11.6211 11.8839 11.5105 11.9347 11.3887C11.9855 11.2668 12.0116 11.1361 12.0116 11.0041C12.0116 10.8721 11.9855 10.7414 11.9347 10.6195C11.8839 10.4977 11.8095 10.3871 11.7158 10.2941L7.4158 6.00409Z" fill="white" />
    </svg>
);

const CustomCheckSvg = () => (
    <svg width="15" height="12" viewBox="0 0 15 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.6684 0.382584C12.9276 0.13448 13.2725 -0.00268272 13.6304 3.97663e-05C13.9883 0.00276225 14.3311 0.145157 14.5865 0.397177C14.842 0.649196 14.9901 0.991129 14.9995 1.35082C15.009 1.71052 14.8791 2.05984 14.6372 2.32508L7.29535 11.5565C7.16911 11.6933 7.01674 11.803 6.84735 11.8791C6.67797 11.9553 6.49505 11.9963 6.30954 11.9998C6.12402 12.0032 5.93972 11.969 5.76764 11.8992C5.59557 11.8294 5.43927 11.7255 5.30807 11.5935L0.439241 6.69846C0.303652 6.57144 0.194899 6.41826 0.119471 6.24806C0.0440431 6.07786 0.00348419 5.89413 0.000214773 5.70783C-0.00305464 5.52153 0.0310325 5.33648 0.100442 5.16371C0.169851 4.99094 0.273161 4.834 0.404209 4.70225C0.535257 4.57049 0.691357 4.46663 0.863198 4.39684C1.03504 4.32706 1.2191 4.29279 1.4044 4.29608C1.5897 4.29936 1.77245 4.34014 1.94173 4.41598C2.11102 4.49181 2.26337 4.60115 2.38972 4.73747L6.24283 8.60951L12.6334 0.423284C12.6449 0.409041 12.6572 0.395453 12.6702 0.382584H12.6684Z" fill="white"/>
    </svg>
);

const CustomCloseIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CustomCloseSvg} {...props} />
);

const CustomCheckIcon = (props: Partial<CustomIconComponentProps>) => (
  <Icon component={CustomCheckSvg} {...props} />
);

const emailsTypes = {
    'drip-first': 'First drip',
    'drip-second': 'Second drip',
    'drip-third': 'Third drip',
    'simple-drip-first': 'Simple first drip',
    'simple-drip-second': 'Simple second drip',
    'simple-drip-third': 'Simple third drip',
    'not-qualified': 'Not qualified',
    'extended-confirmation': 'Extended confirmation',
    'confirmation': 'Confirmation',
    'notification': 'Notification',
    'cancel': 'Cancel',
    'certificate-prep': 'Certificate preparation',
    'drip-d': 'Drip D',
    'customer-registration': 'Customer registration',
    'customer-remind': 'Customer remind',
    'customer-reset-password': 'Customer reset password',
    'all-documents-notification-to-the-lead': 'All documents uploaded',
    'part-of-documents-notidication-to-the-lead': 'Part of documents uploaded'
}

interface emailItem {
    id: number;
    createdDate: Date;
    email: String;
    emailId: String;
    emailType: keyof typeof emailsTypes;
    emailStatus: String;
    emailSendAt: Date;
}

export const getEffortRateColor = (value: number) =>
    value <= 33 ? "green" : value <= 45 ? "#ffba00" : "#ee220c";

export const getPropertyToRevenueRatioColor = (value: number) =>
    value <= 5.5 ? "green" : value <= 6.5 ? "#ffba00" : "#ee220c";

export const getDepositRateColor = (value: number) =>
    value >= 10 ? "green" : "#ee220c";


const consumerRegistrationService = new ConsumerRegistrationService();
const service = new ApplicationService();
const dictionariesService = new DictionariesServices();

const tooltipProps: TooltipPropsWithTitle = {
    placement: 'right',
    title: '',
    overlayClassName: 'document-status-tooltip',
}

const ApplicationsEditPage = (props: any) => {
    const [form] = Form.useForm();

    const [item, setItem] = useState(null as any);
    const isOngoingDrip = item?.status.code && ongoingDripStatuses.includes(item.status.code);

    const [dictionaries, setDictionaries] = useState({
        applicationHomebuyerStatus: [],
        applicationOtherInformationSource: [],
        applicationOtherInformationMoveIn: [],
        applicationPropertyClassification: [],
        applicationPropertyConstructionType: [],
        applicationPropertyType: [],
        applicationCivilStatus: [],
        applicationFinancesIncomeType: [],
        applicationMortgageAcquisitionFeeCoverers: [],
        applicationHomebuyerMatrimonialPropertyRegimes: [],
        applicationHomebuyerSalutations: [],
        applicationHomebuyerEmploymentStatuses: [],
        applicationTypes: [],
        localeLanguages: [],
        applicationCategories: [],
        applicationHomebuyerDocumentsTypes: [],
    } as any);

    const [isSendingInvite, setIsSendingInvite] = useState(false);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [isHomebuyerChild, setIsHomebuyerChild] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    const [statisticColors, setStatisticColors] = useState({ effortRate: 'green', propertyToRevenueRatio: 'green', depositRate: 'green' });

    const [addressModalData, setAddressModalData] = useState(null as any);

    const updateStatisticColors = () => {
        setStatisticColors({
            effortRate: getEffortRateColor(form.getFieldValue(["effortRate"])),
            propertyToRevenueRatio: getPropertyToRevenueRatioColor(form.getFieldValue(["propertyToRevenueRatio"])),
            depositRate: getDepositRateColor(form.getFieldValue(["depositRate"]))
        });
    };

    useEffect(() => {
        form.setFieldsValue(item);
        updateStatisticColors();
    }, [item]);

    useEffect(() => {
        getDictionaries();
    }, []);

    const applicationId = props?.match?.params?.id;
    useEffect(() => {
        if (applicationId) {
            getData();
            return;
        }

        setItem({
            rates: defaultApplicationRates,
            status: { code: 'new' },
            type: { code: 'backOffice', description: 'Back-office' },
            homebuyer: {},
            property: {},
            otherInformation: {applyingWithOtherPerson: false}
        })
    }, [applicationId])

    const getData = () => {
        service.getItem(applicationId).then(data => {
            const calculatedFields = calculateFields(data);
            setItem({
                ...data,
                ...calculatedFields.application,
                homebuyer: {
                    ...data.homebuyer,
                    birthdate: data?.homebuyer?.birthdate ? moment(data?.homebuyer?.birthdate) : null,
                    childrens: data?.homebuyer?.childrens?.map((child: ApplicationModel['homebuyer']) => {
                        return { ...child, birthdate: child.birthdate ? moment(child.birthdate) : null }
                    })
                },
                finances: {
                    ...data.finances,
                    ...calculatedFields.finances
                },
                eva: {
                    ...data.eva,
                    ...calculatedFields.eva
                },
                mortgage: {
                    ...data.mortgage,
                    ...calculatedFields.mortgage
                },
                otherInformation: {
                    ...data.otherInformation,
                    applyingWithOtherPerson: !!(data.homebuyer?.childrens && data.homebuyer.childrens[0])
                }
            });
            setIsHomebuyerChild(!!(data.homebuyer?.childrens && data.homebuyer.childrens[0]))
        });
    };

    const addChildHomebuyer = () => {
        if (item?.id) {
            setIsSaving(true);
            service.createChildHomebuyer(item.id)
                .then(() => {
                    return service.getItem(item.id)
                })
                .then((res) => {
                    updateItem(res);
                    notification.success({
                        message: 'You can edit new applicant now!',
                    })
                })
                .catch((e) => {
                    notification.error({
                        message: e.message || 'Error while creating applicant'
                    })
                })
                .finally(() => {
                    setIsSaving(false);
                })
        }
        setIsHomebuyerChild(true);
    }

    const removeChildHomebuyer = () => {
        if (item?.id && item?.homebuyer.childrens[0]) {
            setIsSaving(true);
            service.deleteChildHomebuyer(item?.homebuyer.childrens[0].id)
                .then(() => {
                    return service.getItem(item.id)
                })
                .then((res) => {
                    updateItem(res);
                    notification.success({
                        message: 'Applicant removed',
                    })
                })
                .catch((e) => {
                    notification.error({
                        message: e.message || 'Error while deleting applicant'
                    })
                })
                .finally(() => {
                    setIsSaving(false);
                })
        }
        setIsHomebuyerChild(false);
    }

    const getDictionaries = () => {
        Object.keys(dictionaries).forEach(key => dictionariesService.getDictionary(key).then((data) => setDictionaries((prev: any) => ({ ...prev, [key]: data }))));
    };

    const cancelEmail = (id: number) => {
        service.cancelEmail(id).then(() => {
            notification.success({ message: 'Email canceled.' });
            getData();
        }).catch(() => {
            notification.error({ message: 'Error while canceling email.' });
        })
    }

    const getEmailTimelineItem = (item: emailItem) => {
        switch (item.emailStatus) {
            case 'scheduled':
                return (
                    <Timeline.Item color="gray" dot={<ClockCircleOutlined className="timeline-icon" />}>
                        {moment(item.emailSendAt).format(FRONT_DATE_TIME_FORMAT)}, {emailsTypes[item.emailType]} email scheduled&nbsp;
                        <Popconfirm title="Are you sure to cancel this email?" okText="Yes" cancelText="No" onConfirm={() => cancelEmail(item.id)}>
                            <Tag className="cursor-pointer" color="red">Cancel</Tag>
                        </Popconfirm>
                    </Timeline.Item>
                )
            case 'canceled':
                return (
                    <Timeline.Item color="red" dot={<CloseCircleOutlined className="timeline-icon" />}>
                        {moment(item.emailSendAt).format(FRONT_DATE_TIME_FORMAT)}, {emailsTypes[item.emailType]} email canceled
                    </Timeline.Item>
                );
            case 'sent':
                return (
                    <Timeline.Item color="green" dot={<CheckCircleOutlined className="timeline-icon" />}>
                        {moment(item.emailSendAt || item.createdDate).format(FRONT_DATE_TIME_FORMAT)}, {emailsTypes[item.emailType]} email sent
                    </Timeline.Item>
                );
            case 'rejected':
                return (
                    <Timeline.Item color="red" dot={<ExclamationCircleOutlined className="timeline-icon" />}>
                        {moment(item.emailSendAt).format(FRONT_DATE_TIME_FORMAT)}, {emailsTypes[item.emailType]} email was rejected by mandrill
                    </Timeline.Item>
                );
        }
    }

    const onGetPDF = async () => {
        setIsGeneratingPdf(true);
        form.validateFields().then(values =>
            saveItem(values).then(() =>
                service.generatePDF(applicationId).then((data) => {
                    setIsGeneratingPdf(false);
                    notification.success({ message: 'Pdf generated.' });
                    const blob = new Blob([data]);
                    const link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = 'application.pdf';
                    link.click();
                })
            ).catch(() => {
                setIsGeneratingPdf(false);
                notification.error({ message: 'Error while generating pdf.' });
            })
        ).catch(() => {
            setIsGeneratingPdf(false);
            notification.warning({ message: 'Fill all fields correctly.' });
        });
    }

    const updateItem = (newItem: any) => {
        const formItem = form.getFieldsValue();
        setItem({
            ...item,
            ...formItem,
            eva: {
                ...item.eva,
                ...formItem.eva,
            },
            finances: {
                ...item.finances,
                ...formItem.finances,
            },
            mortgage: {
                ...item.mortgage,
                ...formItem.mortgage,
            },
            property: {
                ...item.property,
                ...formItem.property,
            },
            rates: {
                ...item.rates,
                ...formItem.rates
            },
            otherInformation: {
                ...item.otherInformation,
                ...formItem.otherInformation,
            },
            homebuyer: {
                ...item.homebuyer,
                ...formItem.homebuyer,
                childrens: newItem.homebuyer?.childrens?.map((child: any, index: number) => {
                    const valueChild = formItem.homebuyer?.childrens && formItem.homebuyer.childrens[index];
                    return {
                        ...child,
                        ...valueChild,
                        birthdate: valueChild?.birthdate ? moment(valueChild.birthdate) : null,
                        address: { ...child.address, ...valueChild?.address },
                        employerAddress: { ...child.employerAddress, ...valueChild?.employerAddress },
                        documents: child.documents || [],
                    }
                }) || [],
                documents: newItem.homebuyer?.documents,
            }
        })
    }

    const onInviteConsumer = async () => {
        if (applicationId) {
            setIsSendingInvite(true);
            consumerRegistrationService.inviteConsumer(applicationId)
                .then(_res => {
                    notification.success({
                        message: 'Invitation has been sent!',
                    });
                })
                .catch((e) => {
                    notification.error({
                        message: 'Error while inviting user',
                        description: e.message
                    });
                })
                .finally(() => {
                    setIsSendingInvite(false);
                })
        }
    }

    const onRemindConsumer = async () => {
      if (applicationId) {
          setIsSendingInvite(true);
          consumerRegistrationService.remindConsumer(applicationId)
              .then(_res => {
                  notification.success({
                      message: 'Reminder has been sent!',
                  });
              })
              .catch((e) => {
                  notification.error({
                      message: 'Error while reminding user',
                      description: e.message
                  });
              })
              .finally(() => {
                  setIsSendingInvite(false);
              })
      }
    }

    const onFinish = (values: any) => {
        setIsSaving(true);
        saveItem(values).then((data) => {
            if (!applicationId) {
                props.history.push(`/application/${data.id}`);
            } else {
                getData();
            }
            notification.success({ message: 'Application updated' });
            setIsSaving(false);
        }).catch(() => {
            notification.error({ message: 'Error while updating application.' });
            setIsSaving(false);
        });
    }

    const saveItem = (values: any) => {
        return service.saveItem(objectToValidFormat({
            ...(item?.id ? { id: item.id } : {}),
            ...values,
            property: { ...item.property, ...values?.property, address: { ...item?.property?.address, ...values?.property?.address } },
            homebuyer: {
                ...item.homebuyer,
                ...values.homebuyer,
                birthdate: values?.homebuyer?.birthdate ? moment(values?.homebuyer?.birthdate).toISOString() : null,
                address: { ...item?.homebuyer?.address, ...values?.homebuyer?.address },
                employerAddress: { ...item?.homebuyer?.employerAddress, ...values?.homebuyer?.employerAddress },
                childrens: item.homebuyer?.childrens ? item.homebuyer?.childrens.map((child: any, index: number) => {
                    const valueChild = values?.homebuyer?.childrens[index];
                    return {
                        ...child,
                        ...valueChild,
                        birthdate: valueChild?.birthdate ? moment(valueChild.birthdate).toISOString() : null,
                        address: { ...child.address, ...valueChild?.address },
                        employerAddress: { ...child.employerAddress, ...valueChild?.employerAddress },
                    }
                }) : (values.homebuyer?.childrens ? values.homebuyer?.childrens.map((child: any, index: number) => {
                    return {
                        ...child,
                        birthdate: child?.birthdate ? moment(child.birthdate).toISOString() : null,
                    }
                }) : []),
            },
            finances: { ...item.finances, ...values.finances },
            eva: { ...item.eva, ...values.eva },
            mortgage: { ...item.mortgage, ...values.mortgage },
            rates: { ...item.rates, ...values.rates },
            otherInformation: { ...item.otherInformation, ...values.otherInformation }
        }), applicationId)
    };

    const [currentDocument, setCurrentDocument] = useState<ApplicationHomebuyerDocumentTypes[]>([]);
    const [addDocumentFor, setAddDocumentFor] = useState("BOTH");
    const multipleHomebuyersExist = !!(item?.homebuyer?.childrens && item.homebuyer.childrens[0]);

    const addHomebuyerDocument = () => {
        const homebuyers = addDocumentFor === "BOTH" ? [item?.homebuyer.id, item?.homebuyer?.childrens[0]?.id].filter(x => !!x) : [addDocumentFor]
        const types = currentDocument || [];
        service.createHomebuyerDocuments(homebuyers, types)
            .then(() => {
                return service.getItem(item.id)
            })
            .then((res) => {
                updateItem(res);
                notification.success({
                    message: 'Document upload created',
                })
            })
            .catch((e) => {
                notification.error({
                    message: e.message || 'Error while creating upload'
                })
            })
            .finally(() => {
                setCurrentDocument([]);
                setIsSaving(false);
            })
    }

    const handleFileDownload = (file: ApplicationHomebuyerDocumentFile) => () => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        service.getDocumentFile(file.doName)
            .then(response => {
                let objectUrl = window.URL.createObjectURL(response);

                anchor.href = objectUrl;
                anchor.download = file.name;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                document.body.removeChild(anchor);
            })
    }

    const handleDocumentApprove = (documentId: ApplicationHomebuyerDocument['id']) => () => {
        setIsSaving(true);
        service.validateDocument(documentId, true)
            .then(() => {
                return service.getItem(item.id)
            })
            .then((res) => {
                updateItem(res);
                notification.success({
                    message: 'Document approved',
                })
            })
            .catch((e) => {
                notification.error({
                    message: e.message || 'Error while approving document'
                })
            })
            .finally(() => {
                setIsSaving(false);
            })
    }

    const handleDocumentReject = (documentId: ApplicationHomebuyerDocument['id']) => () => {
        setIsSaving(true);
        service.validateDocument(documentId, false)
            .then(() => {
                return service.getItem(item.id)
            })
            .then((res) => {
                updateItem(res);
                notification.success({
                    message: 'Document rejected',
                })
            })
            .catch((e) => {
                notification.error({
                    message: e.message || 'Error while rejecting document'
                })
            })
            .finally(() => {
                setIsSaving(false);
            })
    }

    const removeHomebuyerDocument = (id: any) => () => {
            setIsSaving(true);
            service.deleteHomebuyerDocument(id)
                .then(() => {
                    return service.getItem(item.id)
                })
                .then((res) => {
                    updateItem(res);
                    notification.success({
                        message: 'Document removed',
                    })
                })
                .catch((e) => {
                    notification.error({
                        message: e.message || 'Error while removing document'
                    })
                })
                .finally(() => {
                    setIsSaving(false);
                })
    }

    return (
        <div>
            <AddressModal
                item={item}
                path={addressModalData}
                handleCancel={() => setAddressModalData(null)}
                handleOk={(item) => {
                    setItem(item);
                    setAddressModalData(null);
                }} />
            <Form
                form={form}
                layout="vertical"
                initialValues={item}
                name="nest-messages"
                validateMessages={validateMessages}
                onFinishFailed={() => notification.warning({ message: 'Fill all fields correctly.' })}
                onValuesChange={(field, newItem) => {
                    if (field?.otherInformation?.applyingWithOtherPerson !== undefined) {
                        if (field?.otherInformation?.applyingWithOtherPerson) {
                            confirm({
                                title: `Do you want to add another applicant?`,
                                icon: <ExclamationCircleOutlined />,
                                onOk() {
                                    addChildHomebuyer();
                                },
                                onCancel() {
                                    form.setFieldsValue({
                                        otherInformation: {
                                            applyingWithOtherPerson: item?.otherInformation.applyingWithOtherPerson,
                                        }
                                    })
                                },
                            })
                        } else {
                            confirm({
                                title: `Do you want remove applicant?`,
                                icon: <ExclamationCircleOutlined />,
                                onOk() {
                                    removeChildHomebuyer();
                                },
                                onCancel() {
                                    form.setFieldsValue({
                                        otherInformation: {
                                            applyingWithOtherPerson: item?.otherInformation.applyingWithOtherPerson,
                                        }
                                    })
                                },
                            })
                        }
                        return;
                    }

                    if (field?.homebuyer?.notes) return;

                    const isEmailInitiating = field.status?.code && emailInitiatingStatuses.includes(field.status.code);
                    if (field?.status?.code) {
                        const text = [isOngoingDrip && 'cancel ongoing drip', isEmailInitiating && 'initiate email sending'].filter(x => x).join(' and ');
                        if (text) {
                            confirm({
                                title: `Do you want to change the status?`,
                                icon: <ExclamationCircleOutlined />,
                                content: `Changing status will ${text}`,
                                onCancel() {
                                    form.setFieldsValue({
                                        status: {
                                            code: item?.status.code,
                                        }
                                    })
                                },
                            });
                        }
                        return;
                    }

                    const calculatedFields = calculateFields({
                        ...newItem,
                        eva: { ...(newItem?.eva || {}) },
                        rates: { ...(newItem?.rates || {}) },
                        mortgage: { ...(newItem?.mortgage || {}) },
                        property: { ...(newItem?.property || {}) },
                        finances: { ...(newItem?.finances || {}) }
                    });
                    form.setFieldsValue({
                        ...newItem,
                        ...calculatedFields.application,
                        finances: { ...(newItem?.finances || {}), ...calculatedFields.finances },
                        eva: { ...(newItem?.eva || {}), ...calculatedFields.eva },
                        mortgage: { ...(newItem?.mortgage || {}), ...calculatedFields.mortgage },
                        rates: { ...(newItem.rates || {}) }
                    });
                    updateStatisticColors();
                }}
                onFinish={onFinish}
            >
                <HeaderTable title={`${applicationId ? 'Edit' : 'Create'} application`} breadcrumb={{
                    '/applications': 'Applications',
                    '/application': `${applicationId ? 'Edit' : 'Create'} application`
                }}>
                    <div>
                        <Button className="primary-button" onClick={item?.homebuyer?.isRegistered ? onRemindConsumer : onInviteConsumer} loading={isSendingInvite} style={{ marginRight: '24px' }}>
                            {item?.homebuyer?.isRegistered ? 'Remind consumer' : 'Invite consumer'}
                        </Button>
                        <Button className="primary-button" onClick={onGetPDF} loading={isGeneratingPdf} style={{ marginRight: '24px' }} disabled={!applicationId}>
                            Export PDF
                        </Button>
                        <Button className="secondary-button" onClick={getData} style={{ marginRight: '24px' }}>
                            Reset
                        </Button>
                        <Button name="submitButton" loading={isSaving} type="primary" htmlType="submit">
                            Save
                        </Button>
                    </div>
                </HeaderTable>
                <Row gutter={30}>
                    <Col span={5}>
                        <div className="application-right-card">
                            <Card title={
                                <Row>
                                    <Col span={4}>{item?.id ? `№${item.id}` : ''}</Col>
                                    <Col span={20} className='text-right application-generated-input-label'>
                                        {!!item?.createdDate && moment(item.createdDate).format(BACKEND_DATE_TIME_FORMAT)}
                                    </Col>
                                </Row>
                            } type="inner" loading={!item && !!applicationId} className='application-main-card'>
                                <Card.Grid style={{ width: '100%' }} hoverable={false}>
                                    <Row >
                                        <Col span={24}>
                                            <Form.Item shouldUpdate style={{ marginBottom: '0px' }}>
                                                <Statistic value={[form.getFieldValue(["homebuyer", "firstName"]), form.getFieldValue(["homebuyer", "lastName"])].filter(Boolean).join(' ') || 'N/A'} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["type"]}>
                                                <FileDoneOutlined style={{ color: '#ebba7b', paddingRight: '6px' }} />{item?.type?.description || 'N/A'}
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Status" name={["status", "code"]}>
                                                <Select>
                                                    {dictionaries.applicationHomebuyerStatus.map((status: any) => (
                                                        <Option
                                                            key={status.code}
                                                            value={status.code}
                                                        >
                                                            <Tag
                                                                style={{
                                                                    backgroundColor: StatusColors[status.code as keyof typeof StatusColors]?.color || '#fff',
                                                                    color: StatusColors[status.code as keyof typeof StatusColors]?.textColor || '#000',
                                                                }}
                                                            >
                                                                {status.description}{emailInitiatingStatuses.includes(status.code) && '\t\u2709'}
                                                            </Tag>
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item label="Category" name={["category", "code"]}>
                                                <Select>
                                                    {dictionaries.applicationCategories.sort((a: any, b: any) => a.code - b.code).map((category: any) => (
                                                        <Option
                                                            key={category.code}
                                                            value={category.code}
                                                        >
                                                            {category.code === '0' ? category.description : category.code + '. ' + category.description}
                                                        </Option>
                                                    ))}
                                                </Select>
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["property", "price"]}>
                                                <Statistic groupSeparator={'\''} prefix="CHF" title="Property price" />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={["eva", "totalWithFee"]} style={{ marginBottom: 0 }}>
                                                <Statistic groupSeparator={'\''} prefix="CHF" title="Monthly payment" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card.Grid>
                                <Card.Grid style={{ width: '100%' }} hoverable={false}>
                                    <Row >
                                        <Col span={24}>
                                            <Form.Item name={"effortRate"}>
                                                <Statistic suffix="%" title="Effort rate"
                                                    valueStyle={{ color: statisticColors.effortRate }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={"propertyToRevenueRatio"}>
                                                <Statistic title="Property to revenue ratio"
                                                    valueStyle={{ color: statisticColors.propertyToRevenueRatio }} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={24}>
                                            <Form.Item name={"depositRate"} style={{ marginBottom: 0 }}>
                                                <Statistic suffix="%" title="Deposit rate"
                                                    valueStyle={{ color: statisticColors.depositRate }} />
                                            </Form.Item>
                                        </Col>

                                    </Row>
                                </Card.Grid>
                            </Card>
                        </div>
                    </Col>
                    <Col span={19}>
                        <div className="application-tab-container">
                            <Tabs defaultActiveKey="prospect" type="card">
                                <TabPane tab="Prospect" key="prospect" forceRender>
                                    <Card bordered={false} loading={!item && !!applicationId}>
                                        <Row gutter={30}>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Salutation" name={["homebuyer", "salutation", "code"]}>
                                                        <Select>
                                                            {dictionaries.applicationHomebuyerSalutations.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="First name" name={["homebuyer", "firstName"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Last name" name={["homebuyer", "lastName"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Birth date" name={["homebuyer", "birthdate"]}>
                                                        <DatePicker style={{ width: '100%' }} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item name={["homebuyer", "email"]} label="Email" rules={[{ type: 'email' }]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Phone" name={["homebuyer", "phone"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Living address" name={["homebuyer", "address", "addressFull"]}>
                                                        <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "address"])} />}
                                                            {...(item?.homebuyer?.address?.lat && item?.homebuyer?.address?.lng ? {
                                                                addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                    item?.homebuyer?.address?.lat,
                                                                    item?.homebuyer?.address?.lng
                                                                )} />
                                                            } : {})} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Nationality / Permit" name={["homebuyer", "nationality"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Civil status" name={["homebuyer", "civilStatus", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationCivilStatus.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Matrimonial regime" name={["homebuyer", "matrimonialPropertyRegime", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationHomebuyerMatrimonialPropertyRegimes.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Employment status" name={["homebuyer", "employmentStatus", "code"]}>
                                                        <Select>
                                                            {dictionaries.applicationHomebuyerEmploymentStatuses.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Occupation" name={["homebuyer", "occupation"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Employer" name={["homebuyer", "employer"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Employer address" name={["homebuyer", "employerAddress", "addressFull"]}>
                                                        <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "employerAddress"])} />}
                                                            {...(item?.homebuyer?.employerAddress?.lat && item?.homebuyer?.employerAddress?.lng ? {
                                                                addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                    item?.homebuyer?.employerAddress?.lat,
                                                                    item?.homebuyer?.employerAddress?.lng
                                                                )} />
                                                            } : {})} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <div className="application-col">
                                                    <Form.Item label="Notes" name={["homebuyer", "notes"]}>
                                                        <Input.TextArea />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item label="Applying with other person" name={["otherInformation", "applyingWithOtherPerson"]} valuePropName="checked">
                                                    <Checkbox />
                                                </Form.Item>
                                                {isHomebuyerChild &&
                                                    <Divider className='common-divider'/>
                                                }
                                            </Col>
                                        </Row>
                                    </Card>
                                    {isHomebuyerChild &&
                                        <Card bordered={false}>
                                            <Row gutter={30}>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label="Salutation" name={["homebuyer", "childrens", 0, "salutation", "code"]}>
                                                            <Select>
                                                                {dictionaries.applicationHomebuyerSalutations.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label="First name" name={["homebuyer", "childrens", 0, "firstName"]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Last name" name={["homebuyer", "childrens", 0, "lastName"]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Birth date" name={["homebuyer", "childrens", 0, "birthdate"]}>
                                                            <DatePicker style={{ width: '100%' }} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item name={["homebuyer", "childrens", 0, "email"]} label="Email" rules={[{ type: 'email' }]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Phone" name={["homebuyer", "childrens", 0, "phone"]}>
                                                            <Input />
                                                        </Form.Item>
                                                        {/* <Form.Item label="Living address" name={["homebuyer", "childrens", 0, "address", "addressFull"]}>
                                                            <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "childrens", "[0]", "address"])} />}
                                                                {...(item?.homebuyer?.address?.lat && item?.homebuyer?.address?.lng ? {
                                                                    addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                        item?.homebuyer?.address?.lat,
                                                                        item?.homebuyer?.address?.lng
                                                                    )} />
                                                                } : {})} />
                                                        </Form.Item> */}
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label="Nationality / Permit" name={["homebuyer", "childrens", 0, "nationality"]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Civil status" name={["homebuyer", "childrens", 0, "civilStatus", "code"]}>
                                                            <Select allowClear>
                                                                {dictionaries.applicationCivilStatus.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label="Matrimonial regime" name={["homebuyer", "childrens", 0, "matrimonialPropertyRegime", "code"]}>
                                                            <Select allowClear>
                                                                {dictionaries.applicationHomebuyerMatrimonialPropertyRegimes.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label="Employment status" name={["homebuyer", "childrens", 0, "employmentStatus", "code"]}>
                                                            <Select>
                                                                {dictionaries.applicationHomebuyerEmploymentStatuses.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label="Occupation" name={["homebuyer", "childrens", 0, "occupation"]}>
                                                            <Input />
                                                        </Form.Item>
                                                        <Form.Item label="Employer" name={["homebuyer", "childrens", 0, "employer"]}>
                                                            <Input />
                                                        </Form.Item>
                                                        {/* <Form.Item label="Employer address" name={["homebuyer", "childrens", 0, "employerAddress", "addressFull"]}>
                                                            <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "childrens", "[0]", "employerAddress"])} />}
                                                                {...(item?.homebuyer?.employerAddress?.lat && item?.homebuyer?.employerAddress?.lng ? {
                                                                    addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                        item?.homebuyer?.employerAddress?.lat,
                                                                        item?.homebuyer?.employerAddress?.lng
                                                                    )} />
                                                                } : {})} />
                                                        </Form.Item> */}
                                                    </div>
                                                </Col>
                                                <Col span={24}>
                                                    <div className="application-col">
                                                        <Form.Item label="Notes" name={["homebuyer", "childrens", 0, "notes"]}>
                                                            <Input.TextArea />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    }
                                </TabPane>
                                <TabPane tab="Property" key="property" forceRender>
                                    <Card bordered={false} loading={!item && !!applicationId}>
                                        <Row gutter={30}>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Price" name={["property", "price"]} rules={[{ required: true, message: 'Please enter property price!' }]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} {...(item?.property?.property?.id ? {
                                                            addonAfter: <EyeOutlined onClick={() => props.history.push(`/property/${item?.property?.property?.id}`)} />
                                                        } : {})} />
                                                    </Form.Item>
                                                    <Form.Item label="Type" name={["property", "type", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationPropertyType.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Residence classification" name={["property", "classification", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationPropertyClassification.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Construction type" name={["property", "constructionType", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationPropertyConstructionType.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Address" name={["property", "address", "addressFull"]}>
                                                        <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["property", "address"])} />}
                                                            {...(item?.property?.address?.lat && item?.property?.address?.lng ? {
                                                                addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                    item?.property?.address?.lat,
                                                                    item?.property?.address?.lng
                                                                )} />
                                                            } : {})} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Url" name={["property", "url"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Planned investments after" name={["property", "plannedInvestmentsAfter"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Year built" name={["property", "yearBuilt"]}>
                                                        <Input />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Surface net" name={["property", "surfaceNet"]}>
                                                        <Input className="input-align-right" suffix={<span>m<sup>2</sup></span>} />
                                                    </Form.Item>
                                                    <Form.Item className="input-align-right" label="Surface terrace" name={["property", "surfaceTerrace"]}>
                                                        <Input suffix={<span>m<sup>2</sup></span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Surface total" name={["property", "surfaceTotal"]}>
                                                        <Input className="input-align-right" suffix={<span>m<sup>2</sup></span>} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Property segment" name={["property", "propertySegment"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Bathrooms" name={["property", "bathrooms"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Bedrooms" name={["property", "bedrooms"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Number of apts in building" name={["property", "numberOfAptsInBuilding"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Condominium value proportion" name={["property", "condominiumValueProportion"]}>
                                                        <Input />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </TabPane>
                                <TabPane tab="Finances" key="finances" forceRender>
                                    <Card bordered={false} loading={!item && !!applicationId} >
                                        <Row gutter={40}>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Income</div>
                                                    <Form.Item label="Annual household income" name={["finances", "annualIncome"]}
                                                        rules={[
                                                            { validator: (rule: any, value: any, callback: any) => !value || Number(value) === 0 ? callback('Annual household income can\'t be 0') : callback() }]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Income type" name={["finances", "incomeType", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationFinancesIncomeType.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Bonus" name={["finances", "bonus"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Other regular income" name={["finances", "otherRegularIncome"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Avaliable</div>
                                                    <Form.Item label="Available cash" name={["finances", "availableCash"]} rules={[{ required: true, message: 'Please enter available cash!' }]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Second pillar" name={["finances", "secondPillar"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Third pillar" name={["finances", "thirdPillar"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label={<b className="application-generated-input-label">Total funds available</b>} name={["finances", "totalFundsAvailable"]}>
                                                        <Input prefix={<span>CHF</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Wealth</div>
                                                    <Form.Item label="Life insurance" name={["finances", "lifeInsurance"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Worth of owned properties" name={["finances", "worthOfOwnedProperties"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Monthly savings" name={["finances", "monthlySavings"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Expences</div>
                                                    <Form.Item label="Loan leasing expenses" name={["finances", "loanLeasingExpenses"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Child spousal support payments" name={["finances", "childSpousalSupportPayments"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Other regular expenses" name={["finances", "otherRegularExpenses"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Current rent" name={["finances", "currentRent"]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label={<b className="application-generated-input-label">Total monthly expenses</b>} name={["finances", "totalMonthlyExpenses"]}>
                                                        <Input prefix={<span>CHF</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </TabPane>
                                <TabPane tab="Finance request" key="financeRequest" forceRender>
                                    <Card bordered={false} loading={!item && !!applicationId} style={{ height: '538px', overflowY: 'scroll' }}>
                                        <Row gutter={30}>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Deposit</div>
                                                    <Form.Item label="Available cash" name={["mortgage", "availableCash"]}
                                                        rules={[{ validator: (rule: any, value: any, callback: any) => Number(value) > Number(form.getFieldValue(["finances", "availableCash"])) ? callback('Value must be less then finances available cash') : callback() }]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Second pillar" name={["mortgage", "secondPillar"]}
                                                        rules={[{ validator: (rule: any, value: any, callback: any) => Number(value) > Number(form.getFieldValue(["finances", "secondPillar"])) ? callback('Value must be less then finances 2nd pillar') : callback() }]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Third pillar" name={["mortgage", "thirdPillar"]}
                                                        rules={[{ validator: (rule: any, value: any, callback: any) => Number(value) > Number(form.getFieldValue(["finances", "thirdPillar"])) ? callback('Value must be less then finances 3nd pillar') : callback() }]}>
                                                        <Input className="input-align-right" prefix={<span>CHF</span>} />
                                                    </Form.Item>
                                                    <Form.Item label={<b className="application-generated-input-label">Total deposit</b>} name={["mortgage", "totalFundsAvailable"]}>
                                                        <Input prefix={<span>CHF</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Needed</div>
                                                    <Form.Item label="Deposit needed (Bank)" name={["eva", "depositNeededMortgage"]}>
                                                        <Input readOnly bordered={false} prefix={<span>CHF</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label="Deposit needed (EVA)" name={["eva", "depositNeededEva"]}>
                                                        <Input readOnly bordered={false} prefix={<span>CHF</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label="Investor loan needed" name={["mortgage", "investorLoanNeeded"]}>
                                                        <Input readOnly bordered={false} prefix={<span>CHF</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item hidden label="Investor loan acquisition fees" name={["mortgage", "investorLoanAcquisitionFees"]}>
                                                        <Input readOnly bordered={false} prefix={<span>CHF</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label={<b>Bank mortgage</b>} name={["mortgage", "mortgageRequested"]}>
                                                        <Input prefix={<span>CHF</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={12}>
                                                <Row gutter={30}>
                                                    <Col span={12}>
                                                        <div className="application-col">
                                                            <div className="application-label-margin application-generated-input-label"><br /></div>
                                                            <Form.Item label="Acquisition fee covered by" name={["mortgage", "acquisitionFeeCoveredBy", "code"]}>
                                                                <Select allowClear>
                                                                    {dictionaries.applicationMortgageAcquisitionFeeCoverers.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                                </Select>
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                    <Col span={12}>
                                                        <div className="application-col">
                                                            <div className="application-label-margin application-generated-input-label"><br /></div>
                                                            <Form.Item label="Acquisition fee" name={["mortgage", "acquisitionFee"]}>
                                                                <Input readOnly bordered={false} prefix={<span>CHF</span>} className="input-align-right application-generated-input" />
                                                            </Form.Item>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col>
                                                <b style={{ fontSize: 16 }}>Month payments</b>
                                            </Col>
                                        </Row>
                                        <Row gutter={30}>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Rates</div>
                                                    <Form.Item label="Mortgage amortization" name={["rates", "mortgageAmortizationYears"]}>
                                                        <Input className="input-align-right" suffix={<span>years</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="EVA contract duration" name={["rates", "evaContractDurationYears"]}>
                                                        <Input className="input-align-right" suffix={<span>years</span>} />
                                                    </Form.Item>
                                                    <Form.Item label="Investor roi" name={["rates", "investorRoi"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label="EVA fee rate" name={["rates", "evaFeeRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label="Acquisition fee" name={["rates", "acquisitionFee"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Calculations</div>
                                                    <Form.Item label="Investor amortization" name={["mortgage", "monthlyInvestorAmortization"]}>
                                                        <Input readOnly bordered={false} prefix={<span>CHF</span>} suffix={<span>/ month</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label="Investor interest" name={["mortgage", "monthlyInvestorInterest"]}>
                                                        <Input readOnly bordered={false} prefix={<span>CHF</span>} suffix={<span>/ month</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label={<b>Investor payment</b>} name={["mortgage", "investorLoanAmortization"]}>
                                                        <Input readOnly bordered={false} prefix={<span>CHF</span>} suffix={<span>/ month</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label"><br /></div>
                                                    <Form.Item label="Mortgage amortization" name={["eva", "monthlyMortgageAmortization"]}>
                                                        <Input prefix={<span>CHF</span>} suffix={<span>/ month</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label="Mortgage interest" name={["eva", "monthlyMortgageInterest"]}>
                                                        <Input prefix={<span>CHF</span>} suffix={<span>/ month</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label={<b>Bank payment</b>} name={["eva", "bankPayment"]}>
                                                        <Input prefix={<span>CHF</span>} suffix={<span>/ month</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label={<b className="application-generated-input-label">Total (without fees)</b>} name={["eva", "monthlyTotal"]}>
                                                        <Input prefix={<span>CHF</span>} suffix={<span>/ month</span>} readOnly bordered={false} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label"><br /></div>
                                                    <Form.Item label="EVA fee" name={["eva", "monthlyEvaFee"]}>
                                                        <Input readOnly prefix={<span>CHF</span>} bordered={false} suffix={<span>/ month</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label="Other fees" name={["eva", "otherFees"]}>
                                                        <Input prefix={<span>CHF</span>} suffix={<span>/ month</span>} className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label={<b>Total fees</b>} name={["eva", "totalFees"]}>
                                                        <Input readOnly prefix={<span>CHF</span>} bordered={false} suffix={<span>/ month</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    <Form.Item label={<b className="application-generated-input-label">Total (with fee)</b>} name={["eva", "totalWithFee"]}>
                                                        <Input readOnly prefix={<span>CHF</span>} bordered={false} suffix={<span>/ month</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    {/*
                                                    <Form.Item label="Residual value" name={["eva", "residualValue"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label={<b className="application-generated-input-label">Total (adjusted)</b>} name={["eva", "monthlyTotalAdjust"]}>
                                                        <Input readOnly prefix={<span>CHF</span>} bordered={false} suffix={<span>/ month</span>} className="input-align-right application-generated-input" />
                                                    </Form.Item>
                                                    */}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </TabPane>
                                <TabPane tab="Rates" key="rates" forceRender>
                                    <Card bordered={false} loading={!item && !!applicationId}>
                                        <Row gutter={30} >
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Mortgage interest rate" name={["rates", "mortgageInterestRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label="Maintenance fee rate" name={["rates", "maintenanceFeeRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Min deposit rate" name={["rates", "minDepositRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label="Deposit rate" name={["rates", "depositRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Theoretical mortgage interest rate" name={["rates", "theoreticalMortgageInterestRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label="Theoretical maintenance fee rate" name={["rates", "theoreticalMaintenanceFeeRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label="Theoretical eva fee rate" name={["rates", "theoreticalEvaFeeRate"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                    <Form.Item label="Theoretical investor roi" name={["rates", "theoreticalInvestorRoi"]}>
                                                        <Input className="input-align-right" />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </TabPane>
                                <TabPane tab="Other" key="other" forceRender>
                                    <Card bordered={false} loading={!item && !!applicationId}>
                                        <Row gutter={40} >
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="How heard about eva" name={["otherInformation", "howHeardAboutEva", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationOtherInformationSource.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                    <Form.Item label="Planning to move in" name={["otherInformation", "planningToMoveIn", "code"]}>
                                                        <Select allowClear>
                                                            {dictionaries.applicationOtherInformationMoveIn.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Working with agent" name={["otherInformation", "workingWithAgent"]} valuePropName="checked">
                                                        <Checkbox />
                                                    </Form.Item>
                                                    <Form.Item label="Agent name" name={["otherInformation", "agentName"]}>
                                                        <Input />
                                                    </Form.Item>
                                                    <Form.Item label="Language" name={"language"}>
                                                        <Select allowClear onChange={(value) => value !== item.language && item?.emailsHistory && item.emailsHistory.map((item: any) => item.emailStatus).includes('scheduled') ? notification.warning({ message: 'Warning', description: 'Newsletter language will change only for unscheduled emails.' }) : null}>
                                                            {dictionaries.localeLanguages.map((status: any) => <Option key={status.code} value={status.code} >{status.description}</Option>)}
                                                        </Select>
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                            <Col span={6}>
                                                <div className="application-col">
                                                    <Form.Item label="Is newsletter subscriber" name={["homebuyer", "isNewsletterSubscriber"]} valuePropName="checked">
                                                        <Checkbox disabled={true} />
                                                    </Form.Item>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </TabPane>
                                <TabPane tab="Events" key="events" forceRender>
                                    <Card bordered={false} loading={!item && !!applicationId}>
                                        <Row gutter={40} >
                                            <Col span={14}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Follow-up emails schedule</div>
                                                    <Timeline>
                                                        {item?.emailsHistory?.map((item: any) => item.emailType !== emailsTypes.notification ? getEmailTimelineItem(item) : null)}
                                                    </Timeline>
                                                </div>
                                            </Col>
                                            <Col span={10}>
                                                <div className="application-col">
                                                    <div className="application-label-margin application-generated-input-label">Application status history</div>
                                                    <Timeline>
                                                        {item?.statusesHistory?.map((item: any) => <Timeline.Item>{moment(item.createdDate).format(FRONT_DATE_TIME_FORMAT)}, {item.status.description}</Timeline.Item>)}
                                                    </Timeline>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </TabPane>
                                <TabPane tab="Marketing" key="marketing">
                                    <Card bordered={false} loading={!item && !!applicationId} style={{ height: '538px', overflowY: 'auto' }}>
                                        <Row gutter={40} >
                                            <Col span={24}>
                                                <div className="application-col">
                                                    <Descriptions
                                                        className="application-marketing"
                                                        bordered
                                                        column={2}
                                                    >
                                                        <Descriptions.Item label="Initial referrer">{item?.utm?.initialReferrer}</Descriptions.Item>
                                                        <Descriptions.Item label="Referrer">{item?.utm?.referrer}</Descriptions.Item>
                                                        <Descriptions.Item label="Initial referring domain">{item?.utm?.initialReferringDomain}</Descriptions.Item>
                                                        <Descriptions.Item label="Referring domain">{item?.utm?.referringDomain}</Descriptions.Item>
                                                        <Descriptions.Item label="Initial UTM source">{item?.utm?.initialUtmSource}</Descriptions.Item>
                                                        <Descriptions.Item label="UTM source">{item?.utm?.utmSource}</Descriptions.Item>
                                                        <Descriptions.Item label="Initial UTM medium">{item?.utm?.initialUtmMedium}</Descriptions.Item>
                                                        <Descriptions.Item label="UTM medium">{item?.utm?.utmMedium}</Descriptions.Item>
                                                        <Descriptions.Item label="Initial UTM campaign">{item?.utm?.initialUtmCampaign}</Descriptions.Item>
                                                        <Descriptions.Item label="UTM campaign">{item?.utm?.utmCampaign}</Descriptions.Item>
                                                        <Descriptions.Item label="Initial UTM term">{item?.utm?.initialUtmTerm}</Descriptions.Item>
                                                        <Descriptions.Item label="UTM term">{item?.utm?.utmTerm}</Descriptions.Item>
                                                        <Descriptions.Item label="Initial UTM content">{item?.utm?.initialUtmContent}</Descriptions.Item>
                                                        <Descriptions.Item label="UTM content">{item?.utm?.utmContent}</Descriptions.Item>
                                                    </Descriptions>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                </TabPane>
                                <TabPane tab="Documents" key="documents">
                                    <Spin spinning={isSaving}>
                                        <Card
                                            bordered={false}
                                            loading={!item && !!applicationId}
                                        // style={{ height: '538px', overflowY: 'auto' }}
                                        >
                                            <Row gutter={40} >
                                                <Col span={24}>
                                                    <div className="application-col">
                                                        <Col span={24} style={{ padding: 0, marginBottom: '1em' }} >
                                                            {'Add new document:'}
                                                            <Select
                                                                mode='multiple'
                                                                style={{ minWidth: '200px', maxWidth: '600px', marginRight: '16px', marginLeft: '16px' }}
                                                                value={currentDocument}
                                                                onChange={value => setCurrentDocument(value as ApplicationHomebuyerDocumentTypes[])}
                                                                placeholder="Select document type"
                                                            >
                                                                {dictionaries.applicationHomebuyerDocumentsTypes.map((item: DictionaryModel<ApplicationHomebuyerDocumentTypes>) => {
                                                                    return <Select.Option value={item.code}>{item.description}</Select.Option>
                                                                })}
                                                            </Select>
                                                            {multipleHomebuyersExist ? 'For:' : ''}
                                                            {multipleHomebuyersExist &&
                                                                <Select style={{ minWidth: '200px', marginRight: '16px', marginLeft: '16px' }} placeholder="Document for ..." value={addDocumentFor} onChange={(value) => setAddDocumentFor(value)}>
                                                                    <Select.Option value={item.homebuyer.id}>{item.homebuyer.firstName} {item.homebuyer.lastName}</Select.Option>
                                                                    <Select.Option value={item.homebuyer.childrens[0].id}>{item.homebuyer.childrens[0].firstName} {item.homebuyer.childrens[0].lastName}</Select.Option>
                                                                    <Select.Option value={"BOTH"}>Both</Select.Option>
                                                                </Select>
                                                            }
                                                            <Button type="primary" disabled={!currentDocument} onClick={addHomebuyerDocument}>Add</Button>
                                                        </Col>
                                                        {!multipleHomebuyersExist && (item?.homebuyer?.documents as ApplicationHomebuyerDocument[])?.sort((a, b) => a.type.ord - b.type.ord).map((document) => {
                                                            return (
                                                                <Row className="application-document-files">
                                                                    <Row className="application-document-title">
                                                                        <Col span={24} className="application-document-title-name">
                                                                            <CloseOutlined
                                                                                style={{ padding: '10px', fontSize: '12px', color: '#AA4A44' }}
                                                                                onClick={removeHomebuyerDocument(document.id)}
                                                                            />
                                                                            {document.type.description}
                                                                        </Col>
                                                                        {!!document.files?.length &&
                                                                            <Col span={24} className="application-document-title-buttons">
                                                                                {document.isValidated === null &&
                                                                                    <>
                                                                                        <Button
                                                                                            type="primary"
                                                                                            size="large"
                                                                                            className="reject"
                                                                                            icon={<CustomCloseIcon />}
                                                                                            onClick={handleDocumentReject(document.id)}
                                                                                        />
                                                                                        <Button
                                                                                            type="primary"
                                                                                            size="large"
                                                                                            className="approve"
                                                                                            icon={<CustomCheckIcon />}
                                                                                            onClick={handleDocumentApprove(document.id)}
                                                                                        />
                                                                                    </>
                                                                                }
                                                                                {document.isValidated === false &&
                                                                                    <CloseOutlined style={{ color: "#EF6961", fontSize: '20px' }} />
                                                                                }
                                                                                {document.isValidated === true &&
                                                                                    <CheckOutlined style={{ color: "#63A995", fontSize: '20px' }} />
                                                                                }
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                    {document.files?.length ?
                                                                        <Col span={24}>
                                                                            {document.files.map(file => {
                                                                                return (
                                                                                    <Row className="application-document-file">
                                                                                        <Col className="application-document-file-title">
                                                                                            <FileDoneOutlined />
                                                                                            <span className="name" onClick={handleFileDownload(file)}>{file.name}</span>
                                                                                        </Col>
                                                                                    </Row>
                                                                                );
                                                                            })}
                                                                        </Col>
                                                                        :
                                                                        <Col span={24} className="application-document-file">
                                                                            <Col className="application-document-file-title empty">
                                                                                Waiting for upload by client
                                                                            </Col>
                                                                        </Col>
                                                                    }
                                                                </Row>
                                                            )
                                                        })}
                                                        {multipleHomebuyersExist &&
                                                            <Row gutter={8}>
                                                                <Col xs={24} md={24} lg={12}>
                                                                    <Card
                                                                        bordered={false}
                                                                        title={getHomebuyerFullname(item.homebuyer)}
                                                                    >
                                                                        {(item.homebuyer.documents as ApplicationHomebuyerDocument[])?.sort((a, b) => a.type.ord - b.type.ord).map((document, index) => {
                                                                            return (
                                                                                <Row className="application-document-files">
                                                                                    {index !== 0 &&
                                                                                        <Divider className='application-document-divider' />
                                                                                    }
                                                                                    <Row className="application-document-title">
                                                                                        <Col span={24} className="application-document-title-name">
                                                                                            <CloseOutlined
                                                                                                style={{ padding: '10px', fontSize: '12px', color: '#AA4A44' }}
                                                                                                onClick={removeHomebuyerDocument(document.id)}
                                                                                            />
                                                                                            {document.type.description}
                                                                                        </Col>
                                                                                        {(document.isValidated !== null || !!document.files?.length) &&
                                                                                            <Col span={24} className="application-document-title-buttons">
                                                                                                {document.isValidated === null &&
                                                                                                    <>
                                                                                                        <Button
                                                                                                            type="primary"
                                                                                                            size="large"
                                                                                                            className="reject"
                                                                                                            icon={<CustomCloseIcon />}
                                                                                                            onClick={handleDocumentReject(document.id)}
                                                                                                        />
                                                                                                        <Button
                                                                                                            type="primary"
                                                                                                            size="large"
                                                                                                            className="approve"
                                                                                                            icon={<CustomCheckIcon />}
                                                                                                            onClick={handleDocumentApprove(document.id)}
                                                                                                        />
                                                                                                    </>
                                                                                                }
                                                                                                {document.isValidated === false &&
                                                                                                    <Tooltip {...tooltipProps} title={'Rejected'}>
                                                                                                        <CloseOutlined style={{ color: "#EF6961", fontSize: '20px' }} />
                                                                                                    </Tooltip>
                                                                                                }
                                                                                                {document.isValidated === true &&
                                                                                                    <Tooltip {...tooltipProps} title={'Approved'}>
                                                                                                        <CheckOutlined style={{ color: "#63A995", fontSize: '20px' }} />
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            </Col>
                                                                                        }
                                                                                    </Row>
                                                                                    {document.files?.length ?
                                                                                        <Col span={24}>
                                                                                            {document.files.map(file => {
                                                                                                return (
                                                                                                    <Row className="application-document-file">
                                                                                                        <Col className="application-document-file-title">
                                                                                                            <FileDoneOutlined />
                                                                                                            <span className="name" onClick={handleFileDownload(file)}>{file.name}</span>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                );
                                                                                            })}
                                                                                        </Col>
                                                                                        :
                                                                                        <Col span={24} className="application-document-file">
                                                                                            <Col className="application-document-file-title empty">
                                                                                                Waiting for upload by client
                                                                                            </Col>
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            )
                                                                        })}
                                                                    </Card>
                                                                </Col>
                                                                <Col xs={24} md={24} lg={12}>
                                                                    <Card
                                                                        bordered={false}
                                                                        title={getHomebuyerFullname(item.homebuyer.childrens[0])}
                                                                    >
                                                                        {(item.homebuyer.childrens[0].documents as ApplicationHomebuyerDocument[])?.sort((a, b) => a.type.ord - b.type.ord).map((document, index) => {
                                                                            return (
                                                                                <Row className="application-document-files">
                                                                                    {index !== 0 &&
                                                                                        <Divider className='application-document-divider' />
                                                                                    }
                                                                                    <Row className="application-document-title">
                                                                                        <Col span={24} className="application-document-title-name">
                                                                                            <CloseOutlined
                                                                                                style={{ padding: '10px', fontSize: '12px', color: '#AA4A44' }}
                                                                                                onClick={removeHomebuyerDocument(document.id)}
                                                                                            />
                                                                                            {document.type.description}
                                                                                        </Col>
                                                                                        {(document.isValidated !== null || !!document.files?.length) &&
                                                                                            <Col span={24} className="application-document-title-buttons">
                                                                                                {document.isValidated === null &&
                                                                                                    <>
                                                                                                        <Button
                                                                                                            type="primary"
                                                                                                            size="large"
                                                                                                            className="reject"
                                                                                                            icon={<CustomCloseIcon />}
                                                                                                            onClick={handleDocumentReject(document.id)}
                                                                                                        />
                                                                                                        <Button
                                                                                                            type="primary"
                                                                                                            size="large"
                                                                                                            className="approve"
                                                                                                            icon={<CustomCheckIcon />}
                                                                                                            onClick={handleDocumentApprove(document.id)}
                                                                                                        />
                                                                                                    </>
                                                                                                }
                                                                                                {document.isValidated === false &&
                                                                                                    <Tooltip {...tooltipProps} title={'Rejected'}>
                                                                                                        <CloseOutlined style={{ color: "#EF6961", fontSize: '20px' }} />
                                                                                                    </Tooltip>
                                                                                                }
                                                                                                {document.isValidated === true &&
                                                                                                    <Tooltip {...tooltipProps} title={'Approved'}>
                                                                                                        <CheckOutlined style={{ color: "#63A995", fontSize: '20px' }} />
                                                                                                    </Tooltip>
                                                                                                }
                                                                                            </Col>
                                                                                        }
                                                                                    </Row>
                                                                                    {document.files?.length ?
                                                                                        <Col span={24}>
                                                                                            {document.files.map(file => {
                                                                                                return (
                                                                                                    <Row className="application-document-file">
                                                                                                        <Col className="application-document-file-title">
                                                                                                            <FileDoneOutlined />
                                                                                                            <span className="name" onClick={handleFileDownload(file)}>{file.name}</span>
                                                                                                        </Col>
                                                                                                    </Row>
                                                                                                );
                                                                                            })}
                                                                                        </Col>
                                                                                        :
                                                                                        <Col span={24} className="application-document-file">
                                                                                            <Col className="application-document-file-title empty">
                                                                                                Waiting for upload by client
                                                                                            </Col>
                                                                                        </Col>
                                                                                    }
                                                                                </Row>
                                                                            )
                                                                        })}
                                                                    </Card>
                                                                </Col>
                                                            </Row>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </Spin>
                                </TabPane>
                            </Tabs>
                        </div>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default ApplicationsEditPage;