export enum ApplicationStatuses {
  new = 'new',
  firstDrip = 'firstDrip',
  secondDrip = 'secondDrip',
  thirdDrip = 'thirdDrip',
  onHold = 'onHold',
  meeting = 'meeting',
  postMeeting = 'postMeeting',
  certificatePrep = 'certificatePrep',
  certificateIssued = 'certificateIssued',
  propertyIdentified = 'propertyIdentified',
  propertyBudgetIssue = 'propertyBudgetIssue',
  passivePropertySearch = 'passivePropertySearch',
  activePropertySearch = 'activePropertySearch',
  financingPGL = 'financingPGL',
  financingDataCollect = 'financingDataCollect',
  financingFinalOffers = 'financingFinalOffers',
  financingFirm = 'financingFirm',
  signature = 'signature',
  dormant = 'dormant',
  abandon = 'abandon',
  refusal = 'refusal',
  rejected = 'rejected',
  client = 'client',
  dripA = 'dripA',
  dripB = 'dripB',
  dripC = 'dripC',
  approved = 'approved',
  investor = 'investor',
  potentialInvestor = 'potentialInvestor',
  dripD = 'dripD',
}

export const emailInitiatingStatuses = [
  ApplicationStatuses.rejected,
  ApplicationStatuses.firstDrip,
  ApplicationStatuses.refusal,
  ApplicationStatuses.certificatePrep,
  ApplicationStatuses.dripA,
  ApplicationStatuses.dripB,
  ApplicationStatuses.dripC,
  ApplicationStatuses.dripD,
];

export const ongoingDripStatuses = [ApplicationStatuses.firstDrip, ApplicationStatuses.secondDrip];

export const StatusColors = {
  [ApplicationStatuses.new]: {
    color: '#fff',
    textColor: '#000',
  },
  [ApplicationStatuses.firstDrip]: {
    color: '#a4c2f4',
    textColor: '#000',
  },
  [ApplicationStatuses.secondDrip]: {
    color: '#a4c2f4',
    textColor: '#000',
  },
  [ApplicationStatuses.thirdDrip]: {
    color: '#a4c2f4', 
    textColor: '#000',
  },
  [ApplicationStatuses.onHold]: {
    color: '#fff',
    textColor: '#000',
  },
  [ApplicationStatuses.meeting]: {
    color: '#d0e0e3',
    textColor: '#000',
  },
  [ApplicationStatuses.postMeeting]: {
    color: '#a9d0be',
    textColor: '#000',
  },
  [ApplicationStatuses.certificatePrep]: {
    color: '#93c47d',
    textColor: '#fff',
  },
  [ApplicationStatuses.certificateIssued]: {
    color: '#6aa84f',
    textColor: '#fff',
  },
  [ApplicationStatuses.propertyIdentified]: {
    color: '#38761d',
    textColor: '#fff',
  },
  [ApplicationStatuses.propertyBudgetIssue]: {
    color: '#fff',
    textColor: '#000',
  },
  [ApplicationStatuses.passivePropertySearch]: {
    color: '#fff',
    textColor: '#000',
  },
  [ApplicationStatuses.activePropertySearch]: {
    color: '#38761d',
    textColor: '#fff',
  },
  [ApplicationStatuses.financingPGL]: {
    color: '#274e13',
    textColor: '#fff',
  },
  [ApplicationStatuses.financingDataCollect]: {
    color: '#274e13',
    textColor: '#fff',
  },
  [ApplicationStatuses.financingFinalOffers]: {
    color: '#274e13',
    textColor: '#fff',
  },
  [ApplicationStatuses.financingFirm]: {
    color: '#fff',
    textColor: '#000',
  },
  [ApplicationStatuses.signature]: {
    color: '#fff',
    textColor: '#000',
  },
  [ApplicationStatuses.dormant]: {
    color: '#f4cccc',
    textColor: '#000',
  },
  [ApplicationStatuses.abandon]: {
    color: '#d9d9d9',
    textColor: '#000',
  },
  [ApplicationStatuses.refusal]: {
    color: '#d9d9d9color: ',
    textColor: '#000',
  },
  [ApplicationStatuses.rejected]: {
    color: '#f4cccc',
    textColor: '#000',
  },
  [ApplicationStatuses.client]: {
    color: '#36b58f',
    textColor: '#fff',
  },
  [ApplicationStatuses.investor]: {
    color: '#bb00bb',
    textColor: '#fff',
  },
  [ApplicationStatuses.potentialInvestor]: {
    color: '#cc00cc',
    textColor: '#fff',
  },
}