

import React, { CSSProperties, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { MenuOutlined } from '@ant-design/icons';

interface DragableRowProps {
  index: number;
  style?: CSSProperties,
  [prop: string]: any;
}
export const dragType = 'DragableTableCell';

const DragHandler: React.VFC<DragableRowProps> = ({ index, style, ...restProps }) => {
  const [, drag] = useDrag({
    type: dragType,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <MenuOutlined
      ref={drag}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};

export default DragHandler;