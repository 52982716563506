exports = module.exports = require("../../../node_modules/css-loader/dist/runtime/api.js")(false);
// Module
exports.push([module.id, ".Dashboard_header__v1bRl {\n  align-items: baseline;\n}\n\n.Dashboard_title__3IkdI {\n  color: #63A995;\n  margin-right: 1em;\n}\n\n.Dashboard_note__1kSwO {\n  color: #999A9A;\n}\n\n.Dashboard_leadsCard__iTimk .Dashboard_name__2VV2j {\n  color: #36B58F;\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.Dashboard_leadsCard__iTimk .Dashboard_statsRow__O9R-4 {\n  margin-top: 12px;\n  margin-bottom: 24px;\n}\n\n.Dashboard_leadsCard__iTimk  .Dashboard_count__357aS {\n  font-size: 24px;\n  font-weight: 500;\n}\n\n.Dashboard_leadsCard__iTimk .Dashboard_ratio__1a2Do {\n  color: #999A9A;\n  font-size: 14px;\n}\n\n\n.Dashboard_leadsCard__iTimk .Dashboard_outerBar__3UfNF {\n  background-color: #eee;\n  height: 1em;\n}\n\n.Dashboard_outerBar__3UfNF .Dashboard_innerBar__1--DB {\n  background: linear-gradient(90deg, #63A995 0%, #4DDAB2 100%); \n  height: 100%;\n}\n\n.Dashboard_comparisonCard__33qx3 .Dashboard_name__2VV2j {\n  font-size: 18px;\n  font-weight: 500;\n}\n\n.Dashboard_comparisonCard__33qx3 .Dashboard_stats__2HF6Z {\n  margin: 12px 0;\n}\n\n.Dashboard_comparisonCard__33qx3 .Dashboard_stats__2HF6Z.Dashboard_positive__UgNtm {\n  color: #36B58F;\n}\n\n.Dashboard_comparisonCard__33qx3 .Dashboard_stats__2HF6Z.Dashboard_negative__2Dr8W {\n  color: #FA362A;\n}\n\n.Dashboard_comparisonCard__33qx3 .Dashboard_description__2mz-Y {\n  color: #999A9A;\n}\n\ntext.highcharts-credits {\n  display: none;\n}", ""]);

// Exports
exports.locals = {
	"header": "Dashboard_header__v1bRl",
	"title": "Dashboard_title__3IkdI",
	"note": "Dashboard_note__1kSwO",
	"leadsCard": "Dashboard_leadsCard__iTimk",
	"name": "Dashboard_name__2VV2j",
	"statsRow": "Dashboard_statsRow__O9R-4",
	"count": "Dashboard_count__357aS",
	"ratio": "Dashboard_ratio__1a2Do",
	"outerBar": "Dashboard_outerBar__3UfNF",
	"innerBar": "Dashboard_innerBar__1--DB",
	"comparisonCard": "Dashboard_comparisonCard__33qx3",
	"stats": "Dashboard_stats__2HF6Z",
	"positive": "Dashboard_positive__UgNtm",
	"negative": "Dashboard_negative__2Dr8W",
	"description": "Dashboard_description__2mz-Y"
};