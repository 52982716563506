import React, {useEffect, useState} from 'react';
import { Select, Badge, Popover, Row, Button, notification, Modal, Tag } from 'antd';
import {NavLink} from "react-router-dom";
import{ BACKEND_DATE_TIME_FORMAT } from './../../../constants/formats';
import moment from 'moment';
import {getDepositRateColor, getEffortRateColor, getPropertyToRevenueRatioColor} from './../ApplicationsEditPage';
import { ExclamationCircleFilled, EditOutlined } from '@ant-design/icons';
import ApplicationService from '../../../services/ApplicationService';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { ongoingDripStatuses, emailInitiatingStatuses, StatusColors } from '../../../constants/applicationStatuses';
import { ApplicationModel, ApplicationStatus } from '../../../models/ApplicationModel';
import { DictionaryModel } from '../../../models/DictionaryModel';

const numberMask = (number: Number) => number ? (number).toLocaleString('en').replaceAll(',','\'') : 'N/A';

const { Option } = Select;
const { confirm } = Modal;

type AlignType = 'left' | 'center' | 'right';
type SortType = 'ascend' | 'descend';

export const getColumns = (statuses: any[], refresh: any, deleteItems: any, categories: any[]) => [
  {
    title: 'Number',
    width: 60,
    sorter: (a: any, b: any) => Number(a.id) - Number(b.id),
    dataIndex: ['id'],
  },
  {
    title: 'Full name',
    sorter: (a: any, b: any) => [a?.homebuyer?.firstName, a?.homebuyer?.lastName].join(' ') > [b?.homebuyer?.firstName, b?.homebuyer?.lastName].join(' ') ? 1 : -1,
    render: (cell: any, row: any) => [row?.homebuyer?.firstName, row?.homebuyer?.lastName].join(' '),
  },
  {
    title: 'Email',
    width: 200,
    dataIndex: ['homebuyer', 'email'],
    sorter: (a: any, b: any) => Number(a?.homebuyer?.email) > Number(b?.homebuyer?.email) ? 1 : -1,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 170,
    sorter: (a: any, b: any) => a?.status?.businessOrder > b?.status?.businessOrder ? 1 : -1,
    render: (cell: ApplicationStatus, row: any) => {
      const [visible, setVisible] = useState(false);
      const [iconVisible, setIconVisible] = useState(false);
      const [value, setValue] = useState(cell?.code);
      const service = new ApplicationService();
      const isDrip = ongoingDripStatuses.includes(cell?.code)
      const isEmailInitiated = emailInitiatingStatuses.includes(value);

      useEffect(() => {
        setValue(cell?.code)
      }, [cell?.code]);

      const saveStatus = () => {
        service.saveItem({ id: row.id, status: { code: value }}, row.id)
          .then(() => (
            notification.success({ message: `Application №${row.id} status updated` }),
            setVisible(false),
            refresh()
          ))
      }

      const showConfirm = () => {
        setVisible(false);

        const text = [isDrip && 'cancel ongoing drip', isEmailInitiated && 'initiate email sending'].filter(x => x).join(' and ');

        confirm({
          title: `Do you want to change the status?`,
          icon: <ExclamationCircleOutlined />,
          content: `Changing status will ${text}`,
          onOk() {
            saveStatus();
          },
          onCancel() {
            setVisible(true);
          },
        });
      }

      const handleSave = () => {
        if(cell.code === value) 
          return;

        if(isEmailInitiated || isDrip) {
          showConfirm();
        } else {
          saveStatus();
        }
      }
      
      return(
        <Popover
          key={row.id}
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
          title={<span><ExclamationCircleFilled style={{color: '#faad14'}}/> Change application status</span>}
          content={
            <>
              <Row>
                <Select key={`select${row.id}`} style={{width: '100%'}} defaultValue={value} onChange={setValue}>
                  {statuses.map((status: ApplicationStatus) => (
                    <Option
                      key={status.code} 
                      value={status.code}
                    >
                      <Tag 
                        style={{ 
                          backgroundColor: StatusColors[status.code as keyof typeof StatusColors]?.color || '#fff',
                          color: StatusColors[status.code as keyof typeof StatusColors]?.textColor || '#000',
                        }}
                      >
                        {status.description}{emailInitiatingStatuses.includes(status.code) && '\t\u2709'}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Row>
              <Row justify='end' style={{marginTop: '12px'}}>
                <Button size='small' onClick={() => setVisible(false)}>Cancel</Button>
                <Button 
                  onClick={handleSave} 
                  style={{marginLeft: '12px'}}
                  type="primary"
                  size='small'
                >Save</Button>
              </Row>
            </>
          }>
          <span onMouseOver={() => setIconVisible(true)} onMouseLeave={() => setIconVisible(false)} style={{cursor: 'pointer'}}>
            {!!cell 
              ? <Tag 
                  className='status-tag'
                  style={{ 
                    backgroundColor: StatusColors[cell.code as keyof typeof StatusColors]?.color || '#fff',
                    color: StatusColors[cell.code as keyof typeof StatusColors]?.textColor || '#000',
                  }}
                >
                  {cell.description}{emailInitiatingStatuses.includes(cell.code) && '\t\u2709'}
                </Tag>
              : 'N/A'
            }
          </span> <EditOutlined hidden={!iconVisible && !visible} style={{color: '#ebae60'}}/>
        </Popover>
      );
    }
  },
  {
    title: 'Category',
    dataIndex: 'category',
    width: 125,
    sorter: (a: any, b: any) => Number(a?.category?.code || categories.length) > Number(b?.category?.code || categories.length) ? 1 : -1,
    render: (cell: DictionaryModel, row: any) => {
      const [visible, setVisible] = useState(false);
      const [iconVisible, setIconVisible] = useState(false);
      const [value, setValue] = useState(cell?.code);
      const service = new ApplicationService();

      useEffect(() => {
        setValue(cell?.code)
      }, [cell?.code]);

      const saveCategory = () => {
        service.saveItem({ id: row.id, category: { code: value }}, row.id)
          .then(() => (
            notification.success({ message: `Application №${row.id} category updated` }),
            setVisible(false),
            refresh()
          ))
      }

      const handleSave = () => {
        if(cell?.code === value) 
          return;

          saveCategory();
      }
      
      return(
        <Popover
          key={row.id}
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
          title={<span><ExclamationCircleFilled style={{color: '#faad14'}}/> Change application category</span>}
          content={
            <>
              <Row>
                <Select key={`select${row.id}`} style={{width: '100%'}} defaultValue={value} onChange={setValue}>
                  {categories.sort((a, b) => a.code - b.code).map((category: DictionaryModel) => (
                    <Option
                      key={category.code} 
                      value={category.code}
                    >
                        {category.code === '0' ? category.description : category.code + '. ' + category.description}
                    </Option>
                  ))}
                </Select>
              </Row>
              <Row justify='end' style={{marginTop: '12px'}}>
                <Button size='small' onClick={() => setVisible(false)}>Cancel</Button>
                <Button 
                  onClick={handleSave} 
                  style={{marginLeft: '12px'}}
                  type="primary"
                  size='small'
                >Save</Button>
              </Row>
            </>
          }>
          <span onMouseOver={() => setIconVisible(true)} onMouseLeave={() => setIconVisible(false)} style={{cursor: 'pointer'}}>
            {!!cell 
              ? (cell.code === '1' ? <Badge status={'success'} text={cell.code} /> 
              : (cell.code === '0' ? <Badge status={'error'} text={cell.description} /> : <Badge status={'warning'} text={cell.code} />))
              : <Badge status={'error'} text={'N/A'} />
            }
          </span> <EditOutlined hidden={!iconVisible && !visible} style={{color: '#ebae60'}}/>
        </Popover>
      );
    }
  },
  {
    width: 125,
    sorter: (a: any, b: any) => a?.type?.code > b?.type?.code ? 1 : -1,
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
    render: (cell: any) => !!cell ? <Badge status={'success'} text={cell.description} /> : <Badge status={'error'} text={'N/A'} />
  },
  {
    title: 'Property price',
    align: "right" as AlignType,
    sorter: (a: any, b: any) => Number(a.property.price) - Number(b.property.price),
    dataIndex: ['property', 'price'],
    render: (cell: any) => numberMask(cell)
  },
  {
    title: 'Monthly payment',
    align: "right" as AlignType,
    sorter: (a: ApplicationModel, b: ApplicationModel) => Number(a.eva.monthlyTotalAdjust) - Number(b.eva.monthlyTotalAdjust),
    dataIndex: ['eva', 'totalWithFee'],
    render: (cell: number) => numberMask(cell)
  },
  {
    title: 'ER',
    align: "right" as AlignType,
    sorter: (a: any, b: any) => Number(a.effortRate) - Number(b.effortRate),
    dataIndex: ['effortRate'],
    render: (cell: any) => !!cell && <span style={{color: getEffortRateColor(cell)}}>{cell}%</span>
  },
  {
    title: 'PRR',
    align: "right" as AlignType,
    sorter: (a: any, b: any) => Number(a.propertyToRevenueRatio) - Number(b.propertyToRevenueRatio),
    dataIndex: ['propertyToRevenueRatio'],
    render: (cell: any) => !!cell && <span style={{color: getPropertyToRevenueRatioColor(cell)}}>{cell}</span>
  },
  {
    title: 'DR',
    align: "right" as AlignType,
    sorter: (a: any, b: any) => Number(a.depositRate) - Number(b.depositRate),
    dataIndex: ['depositRate'],
    render: (cell: any) => !!cell && <span style={{color: getDepositRateColor(cell)}}>{cell}%</span>
  },
  {
    title: 'Submit date',
    dataIndex: 'createdDate',
    defaultSortOrder: 'descend' as SortType,
    sorter: (a: any, b: any) => new Date(a.createdDate).getTime() - new Date(b.createdDate).getTime(),
    render: (cell: any) => moment(cell).format(BACKEND_DATE_TIME_FORMAT)
  },
  {
    title: 'Action',
    key: 'action',
    width: 105,
    fixed: 'right' as boolean | "right" | "left" | undefined,
    render: (cell: any, row: any) => {
      return (
        <span>  
          <NavLink to={`/application/${row.id}`}>Edit</NavLink>
        </span>
      )
    },
  },
];
