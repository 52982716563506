import React, {createContext, useReducer} from 'react';
import {initialState, reducer} from '../reducers/listReducer';

export const ListContext = createContext({ list: initialState, listDispatch: () => null } as { list: typeof initialState, listDispatch: React.Dispatch<any>});

const ListContextProvider: React.FC = (props: any) => {
    const [list, listDispatch] = useReducer(reducer, initialState);

    return (
        <ListContext.Provider value={{ list, listDispatch }}>
            {props.children}
        </ListContext.Provider>
    );
};

export default ListContextProvider;