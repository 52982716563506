import React, { useRef, useState, useEffect } from 'react';

interface IModalEditAddressAutocompleteProps {
  onChange: (data: any) => void,
  defaultValue: string
};

const googleNamesToLocalFormat  = (label: any) => {
    switch(label) {
        case 'postal_code':  
          return 'zip';
        case 'administrative_area_level_1':  
            return 'region';
        case 'locality':  
            return 'city';
        case 'route':  
            return 'street';
        case 'street_number':  
            return 'buildingNumber';
        default:
            return label;
    }
}

const AddressAutocomplete: React.FC<IModalEditAddressAutocompleteProps> = ({
    onChange, defaultValue
}) => {
    let autocomplete = null as any;
    
    const autoCompleteRef = useRef() as any;
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
        setValue(defaultValue)
      }, [defaultValue]);

    useEffect(() => {
        onChange({addressFull: value})
      }, [value]);

    const onSelect = (address: any) => {
        const localAddress =  {
            ...address.address_components.reduce((prev: any, curr: any) => ({
                ...prev,
                [googleNamesToLocalFormat(curr.types[0])]: curr.long_name
            }), {}),
            lat: address?.geometry?.location?.lat(),
            lng: address?.geometry?.location?.lng()
        }
        localAddress.addressFull = [localAddress.country, localAddress.region, localAddress.city, localAddress.street, localAddress.buildingNumber, localAddress.zip].filter(Boolean).join(', ');
        setValue(localAddress.addressFull);
        return localAddress;
    }

    return (
        <input value={value} className={'ant-input'} onChange={e => setValue(e.target.value)} ref={ el => { 
            autoCompleteRef.current = el; 
            if(!autocomplete) {
                autocomplete = new window.google.maps.places.Autocomplete(
                    autoCompleteRef.current,
                    { componentRestrictions: { country: "ch" } }
                );
                autocomplete.setFields(["address_components", "formatted_address", "geometry"]);
                autocomplete.addListener("place_changed", () => onChange(onSelect(autocomplete.getPlace())));
            }
        }} placeholder={'Address full'}/>
    );
};

export default AddressAutocomplete;
