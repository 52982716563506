import React from 'react';
import { Row, Col, Button, Select, Input, Form, DatePicker, Tag } from 'antd';
const { RangePicker } = DatePicker;
const { Option } = Select;
import { emailInitiatingStatuses, StatusColors } from '../../../constants/applicationStatuses';
import { getRangePickerDefault } from '../../../helpers/getRangePickerDefault';

interface IApplicationSearchProps {
    filters: any,
    setFilters: any,
    statuses: any,
}

export const ApplicationSearch = ({ filters, setFilters, statuses }: IApplicationSearchProps) => {


    const [form] = Form.useForm();

    const ResetFilters = () => {
        setFilters({});
        form.resetFields();
    }

    const SetFilters = () => {
        setFilters({ ...filters, ...(form.getFieldsValue()) });
    }

    return (
        <>
            <Form form={form}>
                <Row>
                    <Col span={5} style={{ paddingRight: '30px' }}>
                        <Form.Item name="fullName" label="Full name" >
                            <Input allowClear onPressEnter={SetFilters} />
                        </Form.Item>
                    </Col>
                    <Col xs={5} sm={5} md={5} lg={5} xl={5} style={{ paddingRight: '30px' }}>
                        <Form.Item name="email" label="Email" >
                            <Input allowClear onPressEnter={SetFilters} />
                        </Form.Item>
                    </Col>
                    <Col span={4} style={{ paddingRight: '30px' }}>
                        <Form.Item name="status" label="Status">
                            <Select mode="multiple" allowClear>
                                {statuses.map((status: any) => (
                                    <Option
                                        key={status.code}
                                        value={status.code}
                                    >
                                        <Tag
                                            style={{
                                                backgroundColor: StatusColors[status.code as keyof typeof StatusColors]?.color || '#fff',
                                                color: StatusColors[status.code as keyof typeof StatusColors]?.textColor || '#000',
                                            }}
                                        >
                                            {status.description}{emailInitiatingStatuses.includes(status.code) && '\t\u2709'}
                                        </Tag>
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={7} sm={7} md={7} lg={7} xl={6} style={{ paddingRight: '30px' }}>
                        <Form.Item name="createdDateRange" label="Submit date">
                            <RangePicker defaultValue={getRangePickerDefault()} style={{ width: '100%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={3} sm={3} md={3} lg={3} xl={4}>
                        <span style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button className="secondary-button" onClick={ResetFilters}>
                                Reset
                            </Button>
                            <Button className="primary-button" onClick={SetFilters} style={{ marginLeft: '10px' }}>
                                Search
                            </Button>
                        </span>
                    </Col>
                </Row>
            </Form>
        </>
    );
};