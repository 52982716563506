export const OpenUrlInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

export const redirectToGoogleMaps = (lat: any, lng: any) => {
    OpenUrlInNewTab("https://maps.google.com?q="+lat+","+lng );
}

export const redirectBlogPreview = (id: number, slug: string, language: string) => {
    OpenUrlInNewTab(`${process.env.REACT_APP_WEBSITE_URL}${language}/preview/${id}-${slug}`);
}