import HttpService from './common/httpService';
const BASE_AUTH_URL = process.env.REACT_APP_BASE_REQUEST;

class DictionariesServices {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getDictionary<T = any>(name: string): Promise<T> {
    return this.httpService.get(`${BASE_AUTH_URL}dictionary/${name}`);
  }

  public getTechTypes() {
    return this.httpService.get(`${BASE_AUTH_URL}properties/tech_types`);
  }
  public getOrientationTypes() {
    return this.httpService.get(`${BASE_AUTH_URL}properties/orientation_types`);
  }
}

export default DictionariesServices;
