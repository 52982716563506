import { Col, Row, Form, DatePicker, Button, Card, notification, Spin, Radio, Select } from 'antd';
import moment, { unitOfTime } from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { Redirect } from 'react-router';
import { ApplicationStatuses } from '../../constants/applicationStatuses';
import { ApplicationModel } from '../../models/ApplicationModel';
import ApplicationService, { ApplicationTypes } from '../../services/ApplicationService';
import { useHasCurrentRule } from '../../services/hooks';
import style from './Dashboard.module.css';
import Icon, { CloseCircleFilled } from '@ant-design/icons';
import { ReactComponent as ScalesSVG } from '../../assets/images/scales.svg';
import GoogleAnalyticsService from '../../services/GoogleAnalyticsSerivce';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { ApplicationsReportDataModel, SessionsReportDataModel, SubscribersReportDataModel, UsersReportDataModel } from '../../models/ReportDataModel';
import SubscribersService from '../../services/SubscribersService';
import { SubscriberModel } from '../../models/SubscriberModel';
import { SubscriberTypes } from '../../constants/SubscriberTypes';

const { RangePicker } = DatePicker;
const { Option } = Select;

const applicationService = new ApplicationService();
const googleAnalyticsService = new GoogleAnalyticsService();
const subscribersService = new SubscribersService();

interface DateRange {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

const synchronizeDateRanges = (rangeA: DateRange, rangeB: DateRange) => {
  rangeA = { startDate: moment(rangeA.startDate), endDate: moment(rangeA.endDate) };
  rangeB = { startDate: moment(rangeB.startDate), endDate: moment(rangeB.endDate) };
  const rangeALength = rangeA.endDate.diff(rangeA.startDate, 'days');
  const rangeBLength = rangeB.endDate.diff(rangeB.startDate, 'days');
  
  if(rangeALength === rangeBLength)
    return [rangeA, rangeB];
  
  const diff = rangeALength - rangeBLength;
  diff > 0 ? rangeB.endDate.add(diff, 'days') : rangeA.endDate.add(-diff, 'days');
  return [rangeA, rangeB];
}

const LeadsCard: React.FC<{ name: string, total: number, count: number }> = ({ name, total, count }) => {
  const ratio = total === 0 ? 0 : ((count * 100) / total).toFixed(0);
  return (
    <Card className={style.leadsCard}>
      <div className={style.name}>{name}</div>
      <Row className={style.statsRow} justify="space-between" align="bottom">
        <span className={style.count}>{count}</span>
        <span className={style.ratio}>{`${ratio}%`}</span>
      </Row>
      <div className={style.outerBar}>
        <div className={style.innerBar} style={{ width: `${ratio}%` }}></div>
      </div>
    </Card>
  )
}

const ComparisonCard: React.FC<{ name: string, amount: number, amountToCompare: number }> = ({ name, amount, amountToCompare }) => {
  const ratio = amountToCompare === 0 ? (amount * 100) + 100 : ((amount * 100) / amountToCompare);
  const growth = isNaN(ratio) ? 0 : ratio - 100;
  return (
    <Card className={style.comparisonCard}>
      <div className={style.name}>{name}</div>
      <Row className={`${style.stats} ${growth > 0 ? style.positive : style.negative}`}>
        {growth > 0 ? `+${growth.toFixed(2)}%` : `${growth.toFixed(2)}%`}
      </Row>
      <div className={style.description}>
        <span>{`${amount} `}</span>{' compared to '}<span>{` ${amountToCompare}`}</span>
      </div>
    </Card>
  )
}

const CompareButton: React.FC<{ isComparison: boolean, onClick: React.MouseEventHandler<HTMLElement> }> = ({ isComparison, onClick }) => {
  const [isHover, setIsHover] = useState(false);
  const onMouseOver = () => setIsHover(true);
  const onMouseLeave = () => setIsHover(false);
  return (
    <Button
      onClick={onClick}
      type={isComparison ? 'link' : 'default'}
      icon={isComparison && isHover ? <CloseCircleFilled /> : <Icon component={ScalesSVG} />}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      {!isComparison && 'Compare with'}
    </Button>
  );
}

const dateFormat = 'DD-MM-YYYY';
const googleDateFormat = 'YYYY-MM-DD';
const googleResponseDateFormat = 'YYYYMMDD';

const initialStatusHistoryDate = '09-04-2021';

const PieChartColors: { [key: string]: string } = {
  referral: '#51BE91',
  organic: '#F45C76',
  banner: '#8403fc',
  email: '#fcf403',
  '(none)': '#EBAE60',
  '(not set)': '#94CDEC',
};

interface DashboardFilters {
  createdDateRange?: Array<moment.Moment>;
  dateRangeToCompare?: Array<moment.Moment>
}

// set dates range to last year(365 days) by default
const initialFilters: DashboardFilters = {
  createdDateRange: [moment().subtract(30, 'days').startOf('day'), moment().subtract(1, 'days').endOf('day')]
}

enum AdditionalChartLines {
  leads = 'leads',
  applications = 'applications',
  contacts = 'contacts',
  subscribers = 'subscribers'
}

function sumArrays(...arrays: number[][]) {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) => arrays.map(xs => xs[i] || 0).reduce((sum, x) => sum + x, 0));
}

const DashboardPage: React.VFC = props => {
  const role = useHasCurrentRule('admin');

  const [form] = Form.useForm();

  const resetFilters = () => {
    setFilters(initialFilters);
    setIsComparisonDataFetched(false);
    setIsComparisonMode(false);
    setComparisonMode('');
    setApplicationsToCompare([]);
    setDatesReportToCompare([]);
    form.resetFields();
  }

  const onSearch = () => {
    setFilters({ ...filters, ...(form.getFieldsValue()) });
  }

  const [datesReport, setDatesReport] = useState<UsersReportDataModel[]>([]);
  const [applicationsReport, setApplicationsReport] = useState<ApplicationsReportDataModel[]>([]);
  const [subscribersReport, setSubscribersReport] = useState<SubscribersReportDataModel[]>([]);
  const chartFormatDatesReport = useMemo(() => {
    const applications = applicationsReport.map(x => x.applications);
    const subscribers = subscribersReport.map(x => x.subscribers);
    const contacts = subscribersReport.map(x => x.contacts);
    return {
      dates: datesReport.map(x => x.date),
      sessions: datesReport.map(x => x.sessions),
      users: datesReport.map(x => x.activeUsers),
      applications,
      subscribers,
      contacts,
      leads: sumArrays(applications, subscribers, contacts),
    }
  }, [datesReport, applicationsReport]);
  const [sessionsReport, setSessionsReport] = useState<SessionsReportDataModel[]>([]);
  const chartFormatSessionsReport = useMemo(() => sessionsReport.map(item => ({ name: item.sessionMedium, y: item.sessions, color: PieChartColors[item.sessionMedium] })), [sessionsReport]);
  const [applications, setApplications] = useState([] as any[]);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState(initialFilters);
  const [isComparisonMode, setIsComparisonMode] = useState(false);
  const [comparisonMode, setComparisonMode] = useState('' as string);
  const [isComparisonDataFetched, setIsComparisonDataFetched] = useState(false);
  const [applicationsToCompare, setApplicationsToCompare] = useState([] as any[]);
  const [datesReportToCompare, setDatesReportToCompare] = useState<UsersReportDataModel[]>([]);
  const chartFormatDatesReportToCompare = useMemo(() => ({
    dates: datesReportToCompare.map(item => item.date),
    sessions: datesReportToCompare.map(item => item.sessions),
    users: datesReportToCompare.map(item => item.activeUsers),
  }), [datesReportToCompare]);

  const getDatesReport = (dateRange: Partial<DateRange>) => {
    const startDate = dateRange.startDate && moment(dateRange.startDate);
    const endDate = dateRange.endDate && moment(dateRange.endDate);

    if (!(startDate && endDate))
      return;

    setIsLoading(true);
    googleAnalyticsService.getUsersReport(startDate.format(googleDateFormat), endDate.format(googleDateFormat)).then(report => {
      const dates = new Map<string, UsersReportDataModel>();
      while (startDate.isBefore(endDate)) {
        const start = startDate.format(googleResponseDateFormat);
        dates.set(start, { date: start, activeUsers: 0, sessions: 0 })
        startDate.date(startDate.date() + 1);
      }
      const finalDate = endDate.format(googleResponseDateFormat);
      dates.set(finalDate, { date: finalDate, activeUsers: 0, sessions: 0 })


      for (const item of report) {
        dates.set(item.date, item);
      }
      const datesArray = Array.from(dates.values());

      const data = datesArray.map(x => ({ ...x, date: moment(x.date).format(dateFormat) }))

      setDatesReport(data);
      setIsLoading(false);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading report.'
      });
      setIsLoading(false);
    });
  };

  const getSessionsReport = () => {
    const startDate = filters.createdDateRange && filters.createdDateRange[0];
    const endDate = filters.createdDateRange && filters.createdDateRange[1];

    if (!(startDate && endDate))
      return;

    setIsLoading(true);
    googleAnalyticsService.getSessionsReport(startDate.format(googleDateFormat), endDate.format(googleDateFormat)).then(report => {
      setSessionsReport(report);
      setIsLoading(false);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading sessions data.'
      });
      setIsLoading(false);
    });
  };

  const getApplications = () => {
    setIsLoading(true);
    applicationService.getItems({ ...filters, type: [ApplicationTypes.full, ApplicationTypes.simplified, ApplicationTypes.preCalendly, null] }, null).then(applications => {
      setApplications(applications.items);

      const startDate = filters.createdDateRange && moment(filters.createdDateRange[0]);
      const endDate = filters.createdDateRange && moment(filters.createdDateRange[1]);
      const dates = new Map<string, ApplicationsReportDataModel>();
      while (startDate && endDate && startDate.isBefore(endDate)) {
        const start = startDate.format(dateFormat);
        dates.set(start, { applications: 0, date: start })
        startDate.date(startDate.date() + 1);
      }

      for (const item of applications.items as ApplicationModel[]) {
        const key = moment(item.createdDate).format(dateFormat);
        dates.set(key, { applications: (dates.get(key)?.applications|| 0) + 1, date: key});
      }

      const datesArray = Array.from(dates.values());

      const data = datesArray.map(x => ({ ...x, date: moment(x.date).format(dateFormat) }))
      setApplicationsReport(data);
      setIsLoading(false);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading applications.'
      });
      setIsLoading(false);
    });
  };

  const getApplicationsToCompare = () => {
    setIsLoading(true);
    applicationService.getItems({ ...filters, createdDateRange: filters.dateRangeToCompare }, null).then(applications => {
      setApplicationsToCompare(applications.items);
      setIsComparisonDataFetched(true);
      setIsLoading(false);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading applications.'
      });
      setIsLoading(false);
    });
  };

  const getDatesReportToCompare = (dateRange: Partial<DateRange>) => {
    const startDate = dateRange.startDate && moment(dateRange.startDate);
    const endDate = dateRange.endDate && moment(dateRange.endDate);

    if (!(startDate && endDate))
      return;

    setIsLoading(true);
    googleAnalyticsService.getUsersReport(startDate.format(googleDateFormat), endDate.format(googleDateFormat)).then(report => {
      const dates = new Map<string, UsersReportDataModel>();
      while (startDate.isBefore(endDate)) {
        const start = startDate.format(googleResponseDateFormat);
        dates.set(start, { date: start, activeUsers: 0, sessions: 0 })
        startDate.date(startDate.date() + 1);
      }
      const finalDate = endDate.format(googleResponseDateFormat);
      dates.set(finalDate, { date: finalDate, activeUsers: 0, sessions: 0 })


      for (const item of report) {
        dates.set(item.date, item);
      }

      const datesArray = Array.from(dates.values());

      const data = datesArray.map(x => ({ ...x, date: moment(x.date).format(dateFormat) }))

      setDatesReportToCompare(data);
      setIsLoading(false);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading report.'
      });
      setIsLoading(false);
    });
  };

  const getSubscribers = () => {
    setIsLoading(true);
    subscribersService.getItems({ submitDateRange: filters.createdDateRange }, null).then(subscribers => {

      const startDate = filters.createdDateRange && moment(filters.createdDateRange[0]);
      const endDate = filters.createdDateRange && moment(filters.createdDateRange[1]);
      const dates = new Map<string, SubscribersReportDataModel>();
      while (startDate && endDate && startDate.isBefore(endDate)) {
        const start = startDate.format(dateFormat);
        dates.set(start, { subscribers: 0, contacts: 0, date: start })
        startDate.date(startDate.date() + 1);
      }

      for (const item of subscribers.items as SubscriberModel[]) {
        const key = moment(item.submitDate).format(dateFormat);
        if(item.type.code === SubscriberTypes.newsletter){
          dates.set(key, { subscribers: (dates.get(key)?.subscribers || 0) + 1, contacts: (dates.get(key)?.contacts || 0), date: key});
        } else {
          dates.set(key, { subscribers: (dates.get(key)?.subscribers || 0), contacts: (dates.get(key)?.contacts || 0) + 1, date: key});
        }        
      }

      const datesArray = Array.from(dates.values());

      const data = datesArray.map(x => ({ ...x, date: moment(x.date).format(dateFormat) }))
      setSubscribersReport(data);
      setIsLoading(false);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading applications.'
      });
      setIsLoading(false);
    });
  };

  const [currentAdditionalChartLine, setCurrentAdditionalChartLine] = useState<AdditionalChartLines>(AdditionalChartLines.applications);
  const handleAdditionalChartLineSelect = (value: AdditionalChartLines) => {
    setCurrentAdditionalChartLine(value);
  }

  useEffect(() => {
    let syncronizedRanges: DateRange[] = [];
    if(filters.createdDateRange && filters.dateRangeToCompare) {
      syncronizedRanges = synchronizeDateRanges(
        {
          startDate: filters.createdDateRange[0],
          endDate: filters.createdDateRange[1],
        },
        {
          startDate: filters.dateRangeToCompare[0],
          endDate: filters.dateRangeToCompare[1],
        }
      )
    }

    getApplications();
    getSubscribers();
    getDatesReport(syncronizedRanges[0] ? syncronizedRanges[0] : {
      startDate: filters?.createdDateRange && filters.createdDateRange[0],
      endDate: filters?.createdDateRange && filters.createdDateRange[1],
    });
    getSessionsReport();
    if (isComparisonMode) {
      getApplicationsToCompare();
      getDatesReportToCompare(syncronizedRanges[1] ? syncronizedRanges[1] : {
        startDate: filters?.dateRangeToCompare && filters.dateRangeToCompare[0],
        endDate: filters?.dateRangeToCompare && filters.dateRangeToCompare[1],
      });
    }
  }, [filters, isComparisonMode]);

  const filterApplicationByHistoryStatuses = (...args: ApplicationStatuses[]) => (application: ApplicationModel) => application.statusesHistory.findIndex(item => { return args.includes(item.status.code) }) > -1;

  const setCurrentDayFilter = () => {
    const startDate = moment().startOf('day');
    const endDate = moment().endOf('day');
    form.setFieldsValue({
      createdDateRange: [startDate, endDate]
    });
  };

  const setLastNDaysFilter = (n: number) => {
    const startDate = moment().subtract(n, 'days').startOf('day');
    const endDate = moment().subtract(1, 'days').endOf('day');
    form.setFieldsValue({
      createdDateRange: [startDate, endDate]
    });
  }

  const setLastNMonthsFilter = (n: number) => {
    const startDate = moment().subtract(n, 'months').startOf('day');
    const endDate = moment().subtract(1, 'days').endOf('day');
    form.setFieldsValue({
      createdDateRange: [startDate, endDate]
    });
  }

  const setLastNTime = (value: string) => {
      const n = Number(value.split(' ')[0]);
      const type = value.split(' ')[1];

      switch (type) {
        case 'day':
          setCurrentDayFilter();
          break;
        case 'days':
          setLastNDaysFilter(n);
          break;
        case 'months':
          setLastNMonthsFilter(n);
          break;
      }

      if (isComparisonMode) {
        setComparePeriod(comparisonMode);
      }
  }

  const setComparePeriod = (value: string) => {
    setComparisonMode(value);
    const [startDate, endDate] = form.getFieldValue(['createdDateRange']);

    if (value === 'previosPeriod') {
      const period = endDate?.diff(startDate, 'days');
      const newStartDate = moment(startDate)?.add(-period, 'days');
      form.setFieldsValue({
        dateRangeToCompare: [newStartDate, moment(startDate)]
      });
    }
    if (value === 'sameLastYear') {
      form.setFieldsValue({
        dateRangeToCompare: [moment(startDate).add(-1, 'years'), moment(endDate).add(-1, 'years')]
      }); 
    }
  }

  const handleCompareButton = () => {
    if (isComparisonMode) {
      resetFilters();
      return;
    }

    setIsComparisonMode(true);
  }

  if (!role) return <Redirect to="/" />;
  const today = moment().endOf('day');
  const disabledDate = (date: moment.Moment) => {
    // Can not select days after today
    return date && date > today;
  }


  return (
    <Spin spinning={isLoading}>
      <Form
        form={form}
        initialValues={initialFilters}
        onValuesChange={(values) => {
          if ((values?.createdDateRange || values?.periodType) && isComparisonMode) {
            setComparePeriod(comparisonMode);
          }

          if (values?.dateRangeToCompare) {
            setComparisonMode('');
            form.resetFields(['compareMode'])
          }
        }}
      >
        <Row>
          <Col span={24}>
            <Row className={style.header}>
              <h2 className={style.title}>Dashboard</h2>
              <p className={style.note}>Displayed correctly from {initialStatusHistoryDate}</p>
            </Row>
          </Col>
          <Col style={{ paddingRight: '30px', marginBottom: '24px' }}>
            <Row gutter={15} align="middle">
              <Form.Item label='Period For:' name="periodType">
                <Select defaultValue='30 days' style={{ minWidth: '140px' }} onChange={(value: string) => setLastNTime(value)}>
                  <Option value={'1 day'}>Day</Option>
                  <Option value={'7 days'}>Last 7 days</Option>
                  <Option value={'30 days'}>Last 30 days</Option>
                  <Option value={'3 months'}>Last 3 months</Option>
                  <Option value={'6 months'}>Last 6 months</Option>
                  <Option value={'12 months'}>Last 12 months</Option>
                </Select>
              </Form.Item>
            </Row>
          </Col>
          <Col
            style={{ paddingRight: '15px' }}
          >
            <Row>
              <Form.Item name="createdDateRange" label="Submit date" style={{ marginRight: '14px' }}>
                <RangePicker
                  style={{ width: '100%' }}
                  format={dateFormat}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <CompareButton isComparison={isComparisonMode} onClick={handleCompareButton} />
              {isComparisonMode &&
                <>
                <Form.Item name="compareMode">
                  <Select  style={{ minWidth: '200px' }} onChange={(value: string) => setComparePeriod(value)}>
                    <Option value={'previosPeriod'}>Previous period</Option>
                    <Option value={'sameLastYear'}>Same period last year</Option>
                  </Select>
                </Form.Item>
                <Form.Item name="dateRangeToCompare" style={{ marginLeft: '14px' }}>
                  <RangePicker
                    style={{ width: '100%' }}
                    format={dateFormat}
                    disabledDate={disabledDate}
                  />
                </Form.Item>
                </>
              }
            </Row>

          </Col>
          <Col xs={24} sm={24} md={24} lg={5} xl={3} style={{ marginLeft: 'auto' }}>
            <span style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '24px' }}>
              <Button className="secondary-button" onClick={resetFilters}>
                Reset
              </Button>
              <Button className="primary-button" htmlType="submit"
                onClick={onSearch}
                style={{ marginLeft: '10px' }}>
                Search
              </Button>
            </span>
          </Col>
        </Row>
      </Form>
      <Row>
        <Col md={24} lg={13}>
          {chartFormatDatesReportToCompare.dates.length ?
            <HighchartsReact
              highcharts={Highcharts}
              options={{
                width: 600,
                xAxis: [
                  {
                    categories: chartFormatDatesReport.dates,
                  },
                  {
                    categories: chartFormatDatesReportToCompare.dates,
                    opposite: true,
                    labels: {
                      enabled: true,
                    }
                  },
                ],
                credits: {
                  enabled: false,
                },
                title: null,
                tooltip: {
                  crosshairs: true,
                  shared: true,
                  formatter: function (): any {
                    return (this as any).points.reduce(function (s: any, point: any) {
                      return s +
                        '<br/><b style="color:' + point.color + '">' + point.x + '</b>: ' +
                        point.y;
                    }, '<b>Users</b><br/>');
                  },
                },
                series: [
                  {
                    xAxis: 1,
                    name: `${chartFormatDatesReportToCompare.dates[0]} - ${chartFormatDatesReportToCompare.dates[chartFormatDatesReportToCompare.dates.length - 1]}`,
                    data: chartFormatDatesReportToCompare.users,
                    color: '#94CDEC',
                  },
                  {
                    xAxis: 0,
                    name: `${chartFormatDatesReport.dates[0]} - ${chartFormatDatesReport.dates[chartFormatDatesReport.dates.length - 1]}`,
                    data: chartFormatDatesReport.users,
                    color: '#36B58F',
                  },
                ]
              }}
            />
            :
            <div style={{maxWidth:'900px'}}>
              <Select style={{ width: '200px', marginBottom: '16px' }} value={currentAdditionalChartLine} onChange={handleAdditionalChartLineSelect}>
                <Select.Option value={AdditionalChartLines.leads}>All leads</Select.Option>
                <Select.Option value={AdditionalChartLines.applications}>Applications</Select.Option>
                <Select.Option value={AdditionalChartLines.contacts}>Contacts</Select.Option>
                <Select.Option value={AdditionalChartLines.subscribers}>Subscribers</Select.Option>
              </Select>
              <HighchartsReact
                highcharts={Highcharts}
                options={{
                  xAxis: {
                    categories: chartFormatDatesReport.dates,
                  },
                  yAxis: [{
                    allowDecimals: false,
                    title: {
                      text: 'Values'
                    }
                  },
                  {
                    allowDecimals: false,
                    labels: {
                      style: {
                        color: '#f75e5e',
                      }
                    },
                    title: {
                      text: currentAdditionalChartLine.charAt(0).toUpperCase() + currentAdditionalChartLine.slice(1),
                      style: {
                        color: '#f75e5e'
                      }
                    },
                    opposite: true,
                  }],
                  title: null,
                  tooltip: {
                    crosshairs: true,
                    shared: true,
                  },
                  plotOptions: {
                    series: {
                      allowPointSelect: true
                    }
                  },
                  series: [
                    {
                      name: 'Sessions',
                      data: chartFormatDatesReport.sessions,
                      color: '#94CDEC',
                    },
                    {
                      name: 'Users',
                      data: chartFormatDatesReport.users,
                      color: '#36B58F',
                    },
                    {
                      name: currentAdditionalChartLine.charAt(0).toUpperCase() + currentAdditionalChartLine.slice(1),
                      data: chartFormatDatesReport[currentAdditionalChartLine],
                      color: '#f75e5e',
                      yAxis: 1,
                    },
                  ],
                  credits: {
                    enabled: false,
                  },
                }}
              />
            </div>
          }
        </Col>
        <Col md={24} lg={11}>
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                type: 'pie',
              },
              title: {
                text: 'Traffic sources',
              },
              tooltip: {
                pointFormat: 'Users: <b>{point.y}</b>'
              },
              plotOptions: {
                pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                  }
                }
              },
              series: [{
                colorByPoint: true,
                data: chartFormatSessionsReport,
              }]
            }}
          />
        </Col>
      </Row>
      {
        isComparisonDataFetched ?
          <Row gutter={42}>
            <Col span={6}>
              <ComparisonCard name="Leads" amount={applications.length} amountToCompare={applicationsToCompare.length} />
            </Col>
            <Col span={6}>
              <ComparisonCard
                name="Active leads"
                amount={applications.filter(filterApplicationByHistoryStatuses(
                  ApplicationStatuses.meeting,
                  ApplicationStatuses.postMeeting,
                  ApplicationStatuses.firstDrip,
                  ApplicationStatuses.secondDrip,
                  ApplicationStatuses.thirdDrip,
                  ApplicationStatuses.dripA,
                  ApplicationStatuses.dripB,
                  ApplicationStatuses.dripC,
                  ApplicationStatuses.certificateIssued,
                  ApplicationStatuses.certificatePrep,
                  ApplicationStatuses.propertyBudgetIssue,
                  ApplicationStatuses.propertyIdentified,
                  ApplicationStatuses.activePropertySearch,
                  ApplicationStatuses.passivePropertySearch,
                  ApplicationStatuses.signature,
                  ApplicationStatuses.client,
                  ApplicationStatuses.approved,
                  ApplicationStatuses.financingDataCollect,
                  ApplicationStatuses.financingFinalOffers,
                  ApplicationStatuses.financingFirm,
                  ApplicationStatuses.financingPGL,
                )).length}
                amountToCompare={applicationsToCompare.filter(filterApplicationByHistoryStatuses(
                  ApplicationStatuses.meeting,
                  ApplicationStatuses.postMeeting,
                  ApplicationStatuses.firstDrip,
                  ApplicationStatuses.secondDrip,
                  ApplicationStatuses.thirdDrip,
                  ApplicationStatuses.dripA,
                  ApplicationStatuses.dripB,
                  ApplicationStatuses.dripC,
                  ApplicationStatuses.certificateIssued,
                  ApplicationStatuses.certificatePrep,
                  ApplicationStatuses.propertyBudgetIssue,
                  ApplicationStatuses.propertyIdentified,
                  ApplicationStatuses.activePropertySearch,
                  ApplicationStatuses.passivePropertySearch,
                  ApplicationStatuses.signature,
                  ApplicationStatuses.client,
                  ApplicationStatuses.approved,
                  ApplicationStatuses.financingDataCollect,
                  ApplicationStatuses.financingFinalOffers,
                  ApplicationStatuses.financingFirm,
                  ApplicationStatuses.financingPGL,
                )).length}
              />
            </Col>
            <Col span={6}>
              <ComparisonCard
                name="Onboarding"
                amount={
                  applications.filter(
                    filterApplicationByHistoryStatuses(
                      ApplicationStatuses.certificateIssued,
                      ApplicationStatuses.certificatePrep,
                      ApplicationStatuses.propertyBudgetIssue,
                      ApplicationStatuses.propertyIdentified,
                      ApplicationStatuses.activePropertySearch,
                      ApplicationStatuses.passivePropertySearch,
                      ApplicationStatuses.approved,
                      ApplicationStatuses.financingDataCollect,
                      ApplicationStatuses.financingFinalOffers,
                      ApplicationStatuses.financingFirm,
                      ApplicationStatuses.financingPGL,
                      ApplicationStatuses.client,
                      ApplicationStatuses.approved,
                      ApplicationStatuses.signature,
                    )
                  ).length
                }
                amountToCompare={
                  applicationsToCompare.filter(
                    filterApplicationByHistoryStatuses(
                      ApplicationStatuses.certificateIssued,
                      ApplicationStatuses.certificatePrep,
                      ApplicationStatuses.propertyBudgetIssue,
                      ApplicationStatuses.propertyIdentified,
                      ApplicationStatuses.activePropertySearch,
                      ApplicationStatuses.passivePropertySearch,
                      ApplicationStatuses.approved,
                      ApplicationStatuses.financingDataCollect,
                      ApplicationStatuses.financingFinalOffers,
                      ApplicationStatuses.financingFirm,
                      ApplicationStatuses.financingPGL,
                      ApplicationStatuses.client,
                      ApplicationStatuses.approved,
                      ApplicationStatuses.signature,
                    )
                  ).length
                }
              />
            </Col>
            <Col span={6}>
              <ComparisonCard
                name="Sales"
                amount={applications.filter(
                  filterApplicationByHistoryStatuses(
                    ApplicationStatuses.client,
                    ApplicationStatuses.approved,
                    ApplicationStatuses.signature,
                  )
                ).length}
                amountToCompare={applicationsToCompare.filter(
                  filterApplicationByHistoryStatuses(
                    ApplicationStatuses.client,
                    ApplicationStatuses.approved,
                    ApplicationStatuses.signature,
                  )
                ).length}
              />
            </Col>
          </Row>
          :
          <Row gutter={42}>
            <Col span={6}>
              <LeadsCard name="Leads" count={applications.length} total={applications.length} />
            </Col>
            <Col span={6}>
              <LeadsCard
                name="Active leads"
                count={applications.filter(filterApplicationByHistoryStatuses(
                  ApplicationStatuses.meeting,
                  ApplicationStatuses.postMeeting,
                  ApplicationStatuses.firstDrip,
                  ApplicationStatuses.secondDrip,
                  ApplicationStatuses.thirdDrip,
                  ApplicationStatuses.dripA,
                  ApplicationStatuses.dripB,
                  ApplicationStatuses.dripC,
                  ApplicationStatuses.certificateIssued,
                  ApplicationStatuses.certificatePrep,
                  ApplicationStatuses.propertyBudgetIssue,
                  ApplicationStatuses.propertyIdentified,
                  ApplicationStatuses.activePropertySearch,
                  ApplicationStatuses.passivePropertySearch,
                  ApplicationStatuses.signature,
                  ApplicationStatuses.client,
                  ApplicationStatuses.approved,
                  ApplicationStatuses.financingDataCollect,
                  ApplicationStatuses.financingFinalOffers,
                  ApplicationStatuses.financingFirm,
                  ApplicationStatuses.financingPGL,
                )).length}
                total={applications.length}
              />
            </Col>
            <Col span={6}>
              <LeadsCard
                name="Onboarding"
                count={
                  applications.filter(
                    filterApplicationByHistoryStatuses(
                      ApplicationStatuses.certificateIssued,
                      ApplicationStatuses.certificatePrep,
                      ApplicationStatuses.propertyBudgetIssue,
                      ApplicationStatuses.propertyIdentified,
                      ApplicationStatuses.activePropertySearch,
                      ApplicationStatuses.passivePropertySearch,
                      ApplicationStatuses.financingDataCollect,
                      ApplicationStatuses.financingFinalOffers,
                      ApplicationStatuses.financingFirm,
                      ApplicationStatuses.financingPGL,
                      ApplicationStatuses.client,
                      ApplicationStatuses.approved,
                      ApplicationStatuses.signature,
                    )
                  ).length
                }
                total={applications.length}
              />
            </Col>
            <Col span={6}>
              <LeadsCard
                name="Sales"
                count={applications.filter(
                  filterApplicationByHistoryStatuses(
                    ApplicationStatuses.client,
                    ApplicationStatuses.approved,
                    ApplicationStatuses.signature,
                  )
                ).length}
                total={applications.length}
              />
            </Col>
          </Row>
      }
    </Spin>
  );
};

export default DashboardPage;