import { Col, Form, Modal, Row, Select, Tag, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import React, { useState } from 'react'
import { ApplicationStatus, IManyEditsBody } from '../../models/ApplicationModel';
import { StatusColors, emailInitiatingStatuses } from '../../constants/applicationStatuses';
import { DictionaryModel } from '../../models/DictionaryModel';
import ApplicationService from '../../services/ApplicationService';

const { Option } = Select;
const service = new ApplicationService();

interface IProps {
  isOpen: boolean;
  statuses: any[];
  categories: any[];
  applicationsId: number[];
  onClose: () => void;
  onCancel: () => void;
}

const ApplicationsManyEditModal = ({ isOpen, onCancel, onClose, statuses, categories, applicationsId }: IProps) => {
  const [form] = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values: {status: string | undefined, category: string | undefined}) => {
    setIsLoading(true);

    const body: IManyEditsBody = {
      ids: applicationsId,
      update: {}
    }

    if (values.status) {
      body.update.status = {
        code: values.status
      }
    }
    if (values.category) {
      body.update.category = {
        code: values.category
      }
    }

    service.editMany(body)
      .then(() => {
        notification.success({
          message: 'Applications updated',
        })
        onClose();
      })
      .catch((e) => {
        notification.error({
          message: e.message || 'Error while updating applications.'
        })
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  return (
    <Modal
      visible={isOpen}
      onOk={form.submit}
      onCancel={onCancel}
      okButtonProps={{loading: isLoading}}
      cancelButtonProps={{loading: isLoading}}
      title={'Edit'}>
        <Form
          form={form}
          onFinish={onSubmit}
          layout={'vertical'}>
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item label="Status" name={'status'}>
                  <Select style={{width: '100%'}} allowClear>
                    {statuses.map((status: ApplicationStatus) => (
                      <Option
                        key={status.code}
                        value={status.code}
                      >
                        <Tag
                          style={{
                            backgroundColor: StatusColors[status.code as keyof typeof StatusColors]?.color || '#fff',
                            color: StatusColors[status.code as keyof typeof StatusColors]?.textColor || '#000',
                          }}
                        >
                          {status.description}{emailInitiatingStatuses.includes(status.code) && '\t\u2709'}
                        </Tag>
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Category" name={'category'}>
                  <Select style={{width: '100%'}} allowClear>
                    {categories.sort((a, b) => a.code - b.code).map((category: DictionaryModel) => (
                      <Option
                        key={category.code}
                        value={category.code}
                      >
                        {category.code === '0' ? category.description : category.code + '. ' + category.description}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
        </Form>
      </Modal>
  )
}

export default ApplicationsManyEditModal