import React, { useContext } from 'react';
import {Typography, Breadcrumb,} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import css from './../../assets/styles/header.module.css';
import { AppContext } from '../../contexts/AppContextProvider';

const {Title} = Typography;

interface IHeaderPage {
    title: string;
    breadcrumb: any;
    children?: any;
}
const BreadcrumpComponent = ({breadcrumb}: any) => {
    return (
        <div className="demo">
            <Breadcrumb>
              <Breadcrumb.Item key="home">
                <Link to="/">Home</Link>
              </Breadcrumb.Item>
              {Object.entries(breadcrumb).map(([key, value], index, arr) => ( 
                <Breadcrumb.Item key={key}>
                  { arr.length !== ++index ? <Link to={key}>{value as string}</Link> : value as string }
                </Breadcrumb.Item>
              ))}
          </Breadcrumb>
        </div>
    );
};

const HeaderTable = ({ title, breadcrumb, children: Component }: IHeaderPage) => {
  const { app } = useContext(AppContext);
  return (
    <div className={css.header}>
        <Title level={4}>{title}</Title>
        <div className={css.con}>
            {!app.roles.includes('consumer') ?
                <BreadcrumpComponent breadcrumb={breadcrumb} /> : <div></div>
            }
            {Component}
        </div>
    </div>
  );
};

export default HeaderTable;
