import React, {useEffect, useState, useContext} from 'react';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {notification, Table, Button, Popconfirm} from 'antd';
import {getColumns} from './components/SubscribersColumns';
import SubscribersService from '../../services/SubscribersService';
import DictionariesServices from '../../services/DictionariesServices';
import { SubscribersSearch } from './components/SubscribersSearch';
import { PageContext } from '../../contexts/PageContextProvider';
import { SUBSCRIBER } from '../../constants/actionTypes/pageConstants';

import {Link} from 'react-router-dom';

const breadcrumbNameMap = {
  '/Subscribers': 'Subscribers',
};

const SubscribersPage = () => {
  const service = new SubscribersService();
  const dictionariesService = new DictionariesServices();

  const [data, setData] = useState([]) as any[];
  const [types, setTypes] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]) as any[];
  const [filters, setFilters] = useState({});
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);
  const [page, pageDispatch] = useContext(PageContext);


  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    getData();
    getTypes();
    getStatuses();
  }, []);

  const refreshData = () => {
    getData();
  }

  const setPage = (page: any) => {
    pageDispatch({
      type: SUBSCRIBER,
      page
    });
  };

  const getTypes = () => {
    dictionariesService.getDictionary('subscriberTypes').then(data => {
      setTypes(data);
    });
  };

  const getStatuses = () => {
    dictionariesService.getDictionary('subscribersStatuses').then(data => {
      setStatuses(data);
    });
  };

  const deleteItems = (selectedRowKeys: number) => {
    service.deleteItem(selectedRowKeys).then(() => {
      notification.success({ message: 'Subscriber deleted' });
      getData();
    });
  }

  const getData = () => {
    setIsLoading(true);
    service.getItems(filters, null).then(data => {
      setData(data);
      setSelectedRowKeys([]);
      setIsLoading(false);
    }).catch(() => {
      notification.error({
        message: 'Error while loading subscribers.'
      });
      setIsLoading(false);
    });
  };

  const exportToCSV = async () => {
    setIsGeneratingCsv(true);

    service.getItemsCsv(filters).then((csv) => {
      const link = document.createElement("a");
      link.href = csv;
      link.download = 'subscribers.csv';
      link.click();
      setIsGeneratingCsv(false);
      notification.success({ message: 'Csv generated.' });
    }).catch(() => {
      setIsGeneratingCsv(false);
      notification.error({ message: 'Error while generating csv.' });
    });
  };

  return (
    <>
      <HeaderTable title='Subscribers list' breadcrumb={breadcrumbNameMap}>
        <span>
          <span style={{ marginRight: 8, color: "#ebae60" }}>
            {selectedRowKeys.length ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
          <Button className="primary-button" onClick={exportToCSV} loading={isGeneratingCsv} style={{ width: 100, marginLeft: '10px'}} disabled={false}>Export CSV</Button>
          <Link to="/subscriber"><Button type="primary" style={{marginLeft: '10px', width: 100}}>+ New</Button></Link>
        </span>
      </HeaderTable>
      <SubscribersSearch types={types} filters={filters} setFilters={setFilters} />
      <Table
        className="dnd-table"
        columns={getColumns(deleteItems, refreshData, statuses)}
        loading={isLoading}
        dataSource={data.items}
        showHeader={true}
        pagination={{ 
          defaultPageSize: 10, 
          showSizeChanger: true, 
          pageSizeOptions: ['10', '20', '30'],
          current: page.pageSubs, 
          onChange: (page => setPage(page))
        }}
        rowKey={record => record.id}
        // NOTE:
        // May be used later
        // rowSelection={{
        //   selectedRowKeys,
        //   onChange: setSelectedRowKeys
        // }}
        onRow={(_record, index) => ({
          index,
        } as any)}
      />
    </>
  );
};

export default SubscribersPage;
