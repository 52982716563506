import React, {useEffect, useState} from 'react';
import { Button, Tag, notification, Popover, Row, Select } from 'antd';
import {NavLink} from "react-router-dom";
import{ BACKEND_DATE_TIME_FORMAT } from './../../../constants/formats';
import moment from 'moment';
import SubscribersService from '../../../services/SubscribersService';
import { ExclamationCircleFilled, EditOutlined } from '@ant-design/icons';
import { emailInitiatingStatuses, subscriberStatusColors } from '../../../constants/SubscribersStatuses';
import { SubscriberStatus } from '../../../models/SubscriberModel';

const { Option } = Select;

type SortType = 'ascend' | 'descend';

export const getColumns = (deleteItems: any, refresh: any, statuses: any[]) => [
  {
    title: 'Number',
    sorter: (a: any, b: any) => Number(a.id) - Number(b.id),
    defaultSortOrder: 'descend' as SortType,
    dataIndex: ['id'],
    width: '60px',
    className: 'text-center',
  },
  {
    title: 'Full name',
    dataIndex: ['fullName'],
    sorter: (a: any, b: any) => Number(a.fullName) > Number(b.fullName) ? 1 : -1,
  },
  {
    title: 'Email',
    dataIndex: ['email'],
    sorter: (a: any, b: any) => Number(a?.email) > Number(b?.email) ? 1 : -1,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    width: 200,
    sorter: (a: any, b: any) => a?.status?.listOrder > b?.status?.listOrder ? 1 : -1,
    render: (cell: SubscriberStatus, row: any) => {
      const [visible, setVisible] = useState(false);
      const [iconVisible, setIconVisible] = useState(false);
      const [value, setValue] = useState(cell?.code);
      const service = new SubscribersService();

      useEffect(() => {
        setValue(cell?.code)
      }, [cell?.code]);

      const saveStatus = () => {
        service.saveItem({ id: row.id, status: { code: value }})
          .then(() => (
            notification.success({ message: `Subscriber №${row.id} status updated` }),
            setVisible(false),
            refresh()
          ))
      }

      const handleSave = () => {
        if(cell?.code === value) 
          return;

          saveStatus();
      }
      
      return(
        <Popover
          key={row.id}
          trigger="click"
          visible={visible}
          onVisibleChange={setVisible}
          title={<span><ExclamationCircleFilled style={{color: '#faad14'}}/> Change subscriber status</span>}
          content={
            <>
              <Row>
                <Select key={`select${row.id}`} style={{width: '100%'}} defaultValue={value} onChange={setValue}>
                  {statuses.sort((a, b) => a.code - b.code).map((status: SubscriberStatus) => (
                    <Option
                      key={status.code} 
                      value={status.code}
                    >
                      <Tag 
                        style={{ 
                          backgroundColor: subscriberStatusColors[status.code as keyof typeof subscriberStatusColors]?.color || '#fff',
                          color: subscriberStatusColors[status.code as keyof typeof subscriberStatusColors]?.textColor || '#000',
                        }}
                      >
                        {status.description}{emailInitiatingStatuses.includes(status.code) && '\t\u2709'}
                      </Tag>
                    </Option>
                  ))}
                </Select>
              </Row>
              <Row justify='end' style={{marginTop: '12px'}}>
                <Button size='small' onClick={() => setVisible(false)}>Cancel</Button>
                <Button 
                  onClick={handleSave} 
                  style={{marginLeft: '12px'}}
                  type="primary"
                  size='small'
                >Save</Button>
              </Row>
            </>
          }>
          <span onMouseOver={() => setIconVisible(true)} onMouseLeave={() => setIconVisible(false)} style={{ cursor: 'pointer' }}>
          {!!cell 
              ? <Tag 
                  style={{ 
                    backgroundColor: subscriberStatusColors[cell.code as keyof typeof subscriberStatusColors]?.color || '#fff',
                    color: subscriberStatusColors[cell.code as keyof typeof subscriberStatusColors]?.textColor || '#000',
                  }}
                >
                  {cell.description}{emailInitiatingStatuses.includes(cell.code) && '\t\u2709'}
                </Tag>
              : 'N/A'
            }
          </span> <EditOutlined hidden={!iconVisible && !visible} style={{ color: '#ebae60' }} />
        </Popover>
      );
    }
  },
  {
    title: 'Type',
    dataIndex: ['type'],
    key: 'type',
    render: (cell: any) => cell.description,
    sorter: (a: any, b: any) => Number(a?.type) > Number(b?.type) ? 1 : -1,
  },
  {
    title: 'Submit date',
    dataIndex: ['submitDate'],
    sorter: (a: any, b: any) => new Date(a.submitDate).getTime() - new Date(b.submitDate).getTime(),
    render: (cell: any) => moment(cell).format(BACKEND_DATE_TIME_FORMAT)
  },
  {
    title: 'Property',
    dataIndex: ['property', 'code'],
    key: 'property',
    sorter: (a: any, b: any) => Number(a?.property) > Number(b?.property) ? 1 : -1,
  },
  {
    title: 'Phone',
    dataIndex: ['phone'],
    key: 'phone',
    sorter: (a: any, b: any) => Number(a?.phone) > Number(b?.phone) ? 1 : -1,
  },
  {
    title: 'Action',
    key: 'action',
    render: (cell: any, row: any) => {
      return (
        <span>
          <>
            <NavLink to={`/subscriber/${cell.id}`}>Edit</NavLink>
            {/* NOTE: May be used later
             Delete option
            <Divider type="vertical" />
            <Popconfirm placement="left" title="Are you sure you want to delete this item?" okText="Yes" cancelText="No" onConfirm={() => deleteItems(row.id)}>
              <Button type="link" style={{color: '#f65a74'}}>Delete</Button>
            </Popconfirm>
            */}
          </>
        </span>
      )
    },
  },
];
