import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Card, Upload, Col, DatePicker, Form, Input, notification, Row, Select, Tabs} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FaqService from '../../services/FaqService';
import DictionariesServices from '../../services/DictionariesServices';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {InputDecimal, InputInteger} from '../../components/common/antdFormatter';
import { imageURLToAntdModel } from '../../helpers/imageToAntdModel';
import moment from 'moment';

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const languages = ['fr', 'en'];

export const getStatusColor = (isPublished: Boolean) => {
  return isPublished ? '#63a995' : '#ebd27c'
}

const BlogEditPage = (props: any) => {
  const [form] = Form.useForm();

  const service = new FaqService();

  const [item, setItem] = useState(null as any);
  const [isSaving, setIsSaving] = useState(false);

  const [pictures, setPictures] = useState({} as any);

  useEffect(() => {
    props?.match?.params?.id ? getData() : setItem({});
  }, []);


  useEffect(() => {
    form.resetFields();
  }, [item]);

  const getData = () => {
    service.getFaqItemById(props.match.params.id).then(data => {
        setItem({
          ...data,
          ...data.translation.reduce((prev: any, curr: any) => {
            return {
              ...prev,
              [curr.language.code]: {
                ...curr,
                publishedDate: curr?.publishedDate ? moment(curr?.publishedDate) : null,
              }
            }
          }, {})
        });
    });
  };

  const togglePublish = (locale: string) => {
    const oldValues = form.getFieldsValue();
    form.setFieldsValue({
      ...oldValues,
      [locale]: {
        ...oldValues[locale],
        isPublished: !oldValues[locale]?.isPublished
      }
    });
    notification.warning({message: `Don't forget to save your changes!`});
  }

  const onFinish = async (values: any) => {
    setIsSaving(true);
    const toUpdate = {
      ...(props?.match?.params?.id ? { id: props.match.params.id } : { createdDate: new Date() }),
      translation: languages.map(language => ({
        ...(item && item[language]?.id ? { id: item[language].id } : {}),
        language: { code: language },
        question: values[language]?.question || null,
        answer: values[language]?.answer || null,
        isPublished: values[language]?.isPublished || false,
      })), 
    }

    service.saveFaqItem(toUpdate).then(async (res: any) => {
        notification.success({message: `FAQ item ${props?.match?.params?.id ? 'updated' : 'created'}`});
        !props?.match?.params?.id ? props.history.push(`/faqItem/${res.id}`) : getData();
        setIsSaving(false);
    }).catch(() => {
      notification.error({ message: 'Error while updating FAQ item.' });
      setIsSaving(false);
    });
  }

  return (
      <Form 
        layout="vertical" 
        onFinish={onFinish} 
        form={form}
        name="nest-messages"
        initialValues={item}>
        <HeaderTable 
          title={`${props?.match?.params?.id ? 'Edit' : 'Create'} ${item?.techType || ''}`}
          breadcrumb={{
            '/faq': 'FAQ list',
            '/faqItem': `${props?.match?.params?.id ? 'Edit' : 'Create'} ${item?.techType || ''}`
          }}>
          <div>
            <Button name="submitButton" type="primary" htmlType="submit" loading={isSaving}>
              Save changes
            </Button> 
          </div>
        </HeaderTable>
        <div className="application-tab-container">
          <Tabs defaultActiveKey="fr" type="card">  
          {
              languages.map(language => (
                <TabPane tab={<Form.Item shouldUpdate style={{marginBottom: 0}}>{() => {
                  const status = !!form.getFieldValue([language, "isPublished"]);
                  return <span>{language.toUpperCase()} {<b style={{color: getStatusColor(status)}}>({status ? "Published" : "Draft"})</b>}</span>
                }}</Form.Item>} key={language} forceRender>
                  <Card bordered={false} loading={!item && !!props?.match?.params?.id}>
                    <Row gutter={60}>
                      <Col span={18}>
                        <Form.Item label="Question" name={[language, 'question']}>
                          <TextArea rows={2}/>
                        </Form.Item>
                        <Form.Item label="Answer" name={[language, 'answer']}>
                          <TextArea rows={4}/>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <div className="blog-col">
                          <Form.Item name={[language, "isPublished"]} shouldUpdate hidden/>
                          <Form.Item shouldUpdate style={{marginBottom: '12px'}}>
                            {() => {
                              const status = !!form.getFieldValue([language, "isPublished"]);
                              return <span>Status: {<b style={{color: getStatusColor(status)}}>({status ? "Published" : "Draft"})</b>}</span>
                            }}
                          </Form.Item>
                          <Form.Item shouldUpdate>
                            {() => {
                              const status = !!form.getFieldValue([language, "isPublished"]);
                              return (
                                <Button size="large" type={!status ? "primary" : "default"} style={{width: '100%'}} 
                                  onClick={() => togglePublish(language)}>
                                  {!status ? 'Publish' : 'Unpublish'}
                                </Button>
                              )
                            }}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </TabPane>
              ))
            }    
          </Tabs>
        </div>
      </Form>
  );
};

export default BlogEditPage;
