import HttpService from "./common/httpService";
import { LoginModel } from "../models/LoginModel";

const BASE_AUTH_URL = process.env.REACT_APP_AUTH_REQUEST as string;

class LoginPageServices {

  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }


  public getToken(body: LoginModel) {
    return this.httpService.post(`${BASE_AUTH_URL}`, body);
  }
}

export default LoginPageServices;
