import React, { useEffect, useState, useContext } from 'react';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { notification, Table, Button, Popconfirm } from 'antd';
import { getColumns } from './components/ApplicationsColumns';
import ApplicationService from '../../services/ApplicationService';
import DictionariesServices from '../../services/DictionariesServices';
import { ApplicationSearch } from './components/ApplicationSearch';
import { Link } from 'react-router-dom';
import { ApplicationsStats } from '../../models/ApplicationStatsModel';
import { ApplicationsStatsBar } from './components/ApplicationsStats';
import { PageContext } from '../../contexts/PageContextProvider';
import { APPLICATION } from '../../constants/actionTypes/pageConstants';
import { ApplicationStatus } from '../../models/ApplicationModel';
import { DictionaryModel } from '../../models/DictionaryModel';
import { getEnabledCategories } from 'trace_events';
import ApplicationsManyEditModal from '../../components/ApplicationsManyEditModal/ApplicationsManyEditModal';


const breadcrumbNameMap: any = {
  '/applications': 'Applications',
};

const ApplicationsPage = (props: any) => {
  const service = new ApplicationService();
  const dictionariesService = new DictionariesServices();

  const [data, setData] = useState([]) as any[];
  const [statuses, setStatuses] = useState<ApplicationStatus[]>([]);
  const [categories, setCategories] = useState<DictionaryModel[]>([]);

  const [showModalEdit, setShowModalEdit] = useState(false);

  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]) as any[];

  const [filters, setFilters] = useState({});

  const [stats, setStats] = useState<ApplicationsStats | null>(null);

  const [page, pageDispatch] = useContext(PageContext);


  useEffect(() => {
    getData();
  }, [filters]);

  useEffect(() => {
    getData();
    getStatuses();
    getStats();
    getCategories();
  }, []);

  const setPage = (page: any) => {
    pageDispatch({
      type: APPLICATION,
      page
    });
  };

  const getStats = () => {
    service.getStats()
      .then(data => {
        setStats(data);
      })
  };

  const getStatuses = () => {
    dictionariesService.getDictionary<ApplicationStatus[]>('applicationHomebuyerStatus').then(data => {
      setStatuses(data);
    });
  };

  const getCategories = () => {
    dictionariesService.getDictionary<DictionaryModel[]>('applicationCategories').then(data => {
      setCategories(data);
    });
  };

  const getData = () => {
    setIsLoading(true);
    service.getItems(filters, null).then(data => {
      setData(data);
      setIsLoading(false);
      setSelectedRowKeys([]);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading applications.'
      });
      setIsLoading(false);
    });
  };

  const refreshData = () => {
    getData();
    getStats();
  }

  const deleteItems = (selectedRowKeys: number[]) => {
    service.deleteItems(selectedRowKeys).then(() => {
      notification.success({ message: 'Application items deleted' });
      refreshData();
    });
  }

  const exportToCSV = async () => {
    setIsGeneratingCsv(true);

    service.getItemsCSV(filters).then((csv) => {
      const link = document.createElement("a");
      link.href = csv;
      link.download = 'applications.csv';
      link.click();
      setIsGeneratingCsv(false);
      notification.success({ message: 'Csv generated.' });
    }).catch(() => {
      setIsGeneratingCsv(false);
      notification.error({ message: 'Error while generating csv.' });
    });
  };

  return (
    <>
      <HeaderTable title='Applications list' breadcrumb={breadcrumbNameMap}>
        <span>
          <span style={{ marginRight: 8, color: "#ebae60" }}>
            {selectedRowKeys.length ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
          <Popconfirm placement="bottom" disabled={!selectedRowKeys.length} title="Are you sure you want to delete selected items?" okText="Yes" cancelText="No" onConfirm={() => deleteItems(selectedRowKeys)}>
            <Button type="primary" className="danger-button" style={{ width: 100 }} disabled={!selectedRowKeys.length}>Delete</Button>
          </Popconfirm>
          <Button className="secondary-button" onClick={() => setShowModalEdit(true)} style={{width: 100, marginLeft: '10px'}} disabled={!selectedRowKeys.length}>Edit</Button>
          <Button className="primary-button" onClick={exportToCSV} loading={isGeneratingCsv} style={{ width: 100, marginLeft: '10px'}} disabled={false}>Export CSV</Button>
          <Link to="/application"><Button type="primary" style={{ width: 100, marginLeft: '10px', }}>+ New</Button></Link>
        </span>
      </HeaderTable>
      <ApplicationsStatsBar stats={stats} />
      <ApplicationSearch statuses={statuses} filters={filters} setFilters={setFilters} />
      <Table columns={getColumns(statuses, refreshData, deleteItems, categories)}
        loading={isLoading}
        dataSource={data.items}
        showHeader={true}
        scroll={{ x: 1500 }}
        pagination={{ 
          defaultPageSize: 30, 
          showSizeChanger: true, 
          pageSizeOptions: ['10', '30', '50', '100'] ,
          current: page.pageApp, 
          onChange: (page => setPage(page))
        }}
        rowKey={record => record.id}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys
        }} />
      {showModalEdit && <ApplicationsManyEditModal
        isOpen={showModalEdit}
        onCancel={() => setShowModalEdit(false)}
        onClose={() => {
          setShowModalEdit(false);
          getData();
        }}
        statuses={statuses}
        categories={categories}
        applicationsId={selectedRowKeys} />
      }
    </>
  );
};

export default ApplicationsPage;
