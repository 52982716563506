import React, { useContext } from 'react';
import { Layout, Menu } from 'antd';
import {NavContext} from '../../contexts/NavContextProvider';
import { LineChartOutlined , HomeOutlined, FormOutlined, SolutionOutlined, ProfileOutlined, CopyOutlined, StarOutlined } from '@ant-design/icons';

import logo from '../../assets/images/logo_w.svg';
import logo_collapsed from '../../assets/images/logo_w_collapsed.svg';

import { NavLink } from 'react-router-dom';
import { useHasCurrentRule } from '../../services/hooks';
import { useTranslation } from 'react-i18next';

const { Sider } = Layout;
const { SubMenu } = Menu;

const Logo = ({ isCollapsed }: { isCollapsed: boolean }) => {
  return (
    <div className={isCollapsed ? `logo-collapsed` : `logo`}>
      <img src={!isCollapsed ? logo : logo_collapsed} alt="Logo" style={!isCollapsed ? {width: '180px'} : {}}/>
    </div>
  );
};

const NavComponent: React.FC = (props: any) => {
  const isAdmin = useHasCurrentRule('admin');
  const { t } = useTranslation();

  const {
    nav: { isMenuCollapsed },
  } = useContext(NavContext);
  return (
    <Sider
      theme="light"
      trigger={null}
      collapsible
      collapsed={isMenuCollapsed}
      width={250}
      className="slider">
      <Logo isCollapsed={isMenuCollapsed}/>
      <Menu mode="inline" theme="light" defaultSelectedKeys={['1']}>
        {isAdmin && (
          <Menu.Item key="dashboard">
            <LineChartOutlined />
            <span><b>Dashboard</b></span>
            <NavLink to="/dashboard"/>
          </Menu.Item>
        )}
        {isAdmin && (
        <Menu.Item key="property">
            <HomeOutlined/>
            <span><b>Properties</b></span>
            <NavLink to="/properties"/>
        </Menu.Item>
        )}
        <Menu.Item key="applications">
            <SolutionOutlined />
            <span><b>{isAdmin ? 'Applications' : t('Applications')}</b></span>
            <NavLink to={`${isAdmin ? '/applications' : '/application'}`}/>
        </Menu.Item>
        {isAdmin && (<Menu.Item key="subscribers">
            <StarOutlined />
            <span><b>Subscribers</b></span>
            <NavLink to="/subscribers"/>
        </Menu.Item>
        )}
        {isAdmin && (
        <SubMenu key="sub2" icon={<CopyOutlined/>} title={<b>Content</b>}>
          <Menu.Item key="blog">
              <FormOutlined/>
              <span><b>Blog</b></span>
              <NavLink to="/blog"/>
          </Menu.Item>
          <Menu.Item key="faq">
              <ProfileOutlined />
              <span><b>Faq</b></span>
              <NavLink to="/faq"/>
          </Menu.Item>
        </SubMenu>
        )}
      </Menu>
    </Sider>
  );
};

export default NavComponent;
