import React, { useContext, useEffect, useState } from 'react';
import { Form, Button, Input, Card, Row, Col, notification, Spin } from 'antd';
import logo from '../../assets/images/logo.svg';
import loginPic from '../../assets/images/blog-big.svg';
import useQuery from '../../hooks/useQuery';
import ConsumerRegistrationService from '../../services/RegistrationService';
import LoginPageServices from '../../services/LoginPageServices';
import { SET_AUTHENTICATED } from '../../constants/actionTypes/appConstants';
import { AppContext } from '../../contexts/AppContextProvider';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const Logo = () => (
    <img src={logo} alt="Logo" className="logo-login" />
);

const consumerRegistrationService = new ConsumerRegistrationService();
const loginPageServices = new LoginPageServices();

interface RegistrationFields {
    login: string;
    firstName: string;
    lastName: string;
    password: string;
    passwordDuplicate: string;
}

const RegistrationPage: React.FC = (props: any) => {
    localStorage.clear();
    const [form] = Form.useForm<RegistrationFields>();
    const { app, appDispatch } = useContext(AppContext);

    const query = useQuery();
    const token = query.get('token');
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (!token) {
            notification.error({
                message: 'Error',
                description: 'Registration page is not accessible!'
            });
            props.history.push('/login');
            return;
        }

        consumerRegistrationService.confirmToken(token)
            .then(res => {
                setIsLoading(false);
                form.setFieldsValue({ login: res.email });
            })
            .catch(e => {
                notification.error({
                    message: 'Error',
                    description: e.message,
                });
                props.history.push('/login');
            });
    }, [token]);
  
    const setGlobalCredentials = (user: any, data: any) => {
      appDispatch({ type: SET_AUTHENTICATED, user: user });
      localStorage.setItem('roles', JSON.stringify(user.roles));
      localStorage.setItem('user', JSON.stringify(user));
      delete data.user;
      localStorage.setItem('id_token', JSON.stringify(data));
      props.history.push('/applications');
    }

    const handleSubmit = (values: RegistrationFields) => {
        if(token) {
            setIsLoading(true);
            consumerRegistrationService.register({
                login: values.login,
                firstName: values.firstName,
                lastName: values.lastName,
                password: values.password,
                token,
            })
            .then(()=> {
                loginPageServices.getToken({ username: values.login, password: values.password,
                }).then(
                  (res: any) => {
                    const user = res.user;
                    const data = res;
                    setGlobalCredentials(user, data);
                  },
                  err => {
                    // TODO: create global cheking
                    notification.error({
                      message: 'Error',
                      description: err.message,
                    });
                  },
                );
            })
            .catch(e => {
                notification.error({
                    message: 'Error',
                    description: e.message,
                })
                setIsLoading(false);
            })
        }
    };

    return (
        <Row
            justify="center"
            align="middle"
            className="login-page"
        >
            <div className="login-page_in">
                <h1>Welcome to the Eva</h1>
                <Spin spinning={isLoading}>
                    <div className="section-login_row">
                        <figure className="section-login_pic"><img src={loginPic} alt="" /></figure>
                        <Col className="section-login_form">
                            <Card className="section-login_card" title={<Logo />}>
                                <Form onFinish={handleSubmit} className="login-form" form={form}>
                                    <Form.Item name="login" rules={[{ required: true, message: 'Please input your email!' }]}>
                                        <Input
                                            prefix={
                                                <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                            }
                                            disabled
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item name="firstName" rules={[{ required: true, message: 'Please input your name!' }]}>
                                        <Input
                                            placeholder="First name"
                                        />
                                    </Form.Item>
                                    <Form.Item name="lastName" rules={[{ required: true, message: 'Please input your last name!' }]}>
                                        <Input
                                            placeholder="Last name"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            { required: true, message: 'Please input your password!' },
                                            { type: 'string', min: 6, message: 'Password too short! Must be at least 6 characters.' }
                                        ]}
                                    >
                                        <Input
                                            prefix={
                                                <LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                                            }
                                            type="password"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="passwordDuplicate"
                                        dependencies={['password']}
                                        rules={[
                                            { required: true, message: 'Please confirm your password!' },
                                            (form) => {
                                                return {
                                                    validator: (_rule, value) => {
                                                        if (value === form.getFieldValue('password')) {
                                                            return Promise.resolve();
                                                        }
                                                        return Promise.reject(new Error('Passwords do not match!'));
                                                    }
                                                }
                                            }
                                        ]}>
                                        <Input
                                            prefix={
                                                <LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                                            }
                                            type="password"
                                            placeholder="Confirm password"
                                        />
                                    </Form.Item>
                                    <div style={{ height: '100%', float: 'right' }}>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            className="login-form-button"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                    </div>
                </Spin>
            </div>

        </Row>
    );
};

export default RegistrationPage;
