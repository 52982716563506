import React, {ReactElement} from 'react';
import {Input} from 'antd';

interface Props {
    prefix?: ReactElement;
    suffix?: ReactElement;
    length?: number;
    precision?: number;
    alwaysShowO?: boolean;
}

export const InputDecimal: React.FC<Props> = (props: any) => {

    const onChange = (e: any) => {
        const { value } = e.target;
        const length = `{0,${(props.length || 10) - 1}}`;
        const precision = `{1,${(props.precision || 2)}}`;
        const regWithoutO = `^(0|[1-9][0-9]${length})(\\.|\\.[0-9]${precision})?$`;
        const reg = new RegExp(regWithoutO);
        if ((!isNaN(value) && reg.test(value)) || value === '') {
            props.onChange(value);
        }
    };

    const onBlur = () => {
        const { value, onBlur, onChange } = props;
        if (value.toString().charAt(value.length - 1) === '.' && !props.alwaysShowO) {
            onChange(value.toString().slice(0, -1));
        } else if (value.toString().indexOf('.') >= 0 && props.alwaysShowO) {
            const splitValue = value.toString().split('.');
            splitValue[1] = splitValue[1].padEnd(props.precision, '0');
            onChange(splitValue.join('.'));
        } else if (value.toString().indexOf('.') < 0 && props.alwaysShowO) {
            const dec = '.' + '0'.padEnd(props.precision, '0');
            onChange(value + dec);
        }
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <Input
            {...props}
            onChange={onChange}
            onBlur={onBlur}
            prefix={props.prefix || null}
            suffix={props.suffix || null}/>
    );
};