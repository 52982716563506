import React, {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Button, Card, Upload, Col, DatePicker, Form, Input, notification, Row, Select, Tabs} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import BlogService from '../../services/BlogService';
import DictionariesServices from '../../services/DictionariesServices';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {InputDecimal, InputInteger} from '../../components/common/antdFormatter';
import { imageURLToAntdModel } from '../../helpers/imageToAntdModel';
import moment from 'moment';
import slugify from 'slugify';
import {redirectBlogPreview} from '../../helpers/OpenUrlInNewTab';
import TextEditor from "../../components/TextEditor";

const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;

const defaultStatus = { code: 'draft', description: 'Draft' };

export const getStatusColor = (code: string) => {
  switch(code){
    case 'published':
      return '#63a995';
    case 'draft':
      return '#ebd27c';
  }
}

const BlogEditPage = (props: any) => {
  const [form] = Form.useForm();

  const service = new BlogService();
  const dictionariesService = new DictionariesServices();

  const [item, setItem] = useState(null as any);
  const [isSaving, setIsSaving] = useState(false);

  const [dictionaries, setDictionaries] = useState({
    blogStatuses: []
  } as any);

  const [pictures, setPictures] = useState({} as any);

  useEffect(() => {
    getDictionaries();
    props?.match?.params?.id ? getData() : setItem({
      fr: { status: defaultStatus },
      en: { status: defaultStatus }
    });
  }, []);

  const getDictionaries = () => {
    Object.keys(dictionaries).forEach(key => dictionariesService.getDictionary(key).then((data) => setDictionaries((prev: any) => ({ ...prev, [key]: data }))));
  };

  useEffect(() => {
    form.resetFields();
    (item?.translation || []).forEach((item: any) => setPictures((prev: any) => ({...prev, [item.language.code]: item?.image ? [imageURLToAntdModel({name: item.image})] : []})));
  }, [item]);

  const getData = () => {
    service.getBlogItemById(props.match.params.id).then(data => {
        setItem({
          ...data,
          ...data.translation.reduce((prev: any, curr: any) => {
            return {
              ...prev,
              [curr.language.code]: {
                ...curr,
                publishedDate: curr?.publishedDate ? moment(curr?.publishedDate) : null,
              }
            }
          }, {})
        });
    });
  };

  const handleImage = async (pictures: any[], locale: string) => {
    if((pictures?.length ? pictures[0].name : null) !== item[locale]?.image){
      if(item.image){
        await service.deleteImage([item.image]);
      }
      return !!pictures?.length ? (await service.saveImage(pictures))[0] : Promise.resolve(null);
    }
  }

  const togglePublish = (locale: string) => {
    const oldValues = form.getFieldsValue();
    form.setFieldsValue({
      ...oldValues,
      [locale]: {
        ...oldValues[locale],
        status: dictionaries.blogStatuses.find((status: any) => status.code !== oldValues[locale]?.status?.code)
      }
    });
    notification.warning({message: `Don't forget to save your changes!`});
  }

  const onFinish = async (values: any) => {
    setIsSaving(true);
    const toUpdate = {
      ...(props?.match?.params?.id ? { id: props.match.params.id } : { createdDate: new Date() }),
      translation: [
        {
          ...(item?.en?.id ? { id: item?.en?.id } : {}),
          language: { code: 'en' },
          title: values?.en?.title || null,
          slug: values?.en?.slug || null,
          content: values?.en?.content || null,
          excerpt: values?.en?.excerpt || null,
          publishedDate: values?.en?.publishedDate || null, 
          image: await handleImage(pictures.en, 'en'),
          status: values?.en?.status?.code ? values?.en?.status : null,
        },
        {
          ...(item?.fr?.id ? { id: item?.fr?.id } : {}),
          language: { code: 'fr' },
          title: values?.fr?.title || null,
          slug: values?.fr?.slug || null,
          content: values?.fr?.content || null,
          excerpt: values?.fr?.excerpt || null,
          publishedDate: values?.fr?.publishedDate || null,
          image: await handleImage(pictures.fr, 'fr'),
          status: values?.fr?.status?.code ? values?.fr?.status : null,
        }
      ] 
    }

    service.saveBlogItem(toUpdate, props?.match?.params?.id).then(async (res: any) => {
        notification.success({message: `Blog ${props?.match?.params?.id ? 'updated' : 'created'}`});
        !props?.match?.params?.id ? props.history.push(`/blogItem/${res.id}`) : getData();
        setIsSaving(false);
    }).catch(() => {
      notification.error({ message: 'Error while updating blog item.' });
      setIsSaving(false);
    });
  }

  return (
      <Form 
        layout="vertical" 
        onFinish={onFinish} 
        form={form}
        name="nest-messages"
        onValuesChange={(field, newItem) => {
          form.setFieldsValue({
              ...newItem,
              en: {
                ...newItem.en,
                slug: slugify(newItem.en.title || '', {lower: true})
              },
              fr: {
                ...newItem.fr,
                slug: slugify(newItem.fr.title || '', {lower: true})
              }
          }); 
      }}
        initialValues={item}>
        <HeaderTable 
          title={`${props?.match?.params?.id ? 'Edit' : 'Create'} ${item?.techType || ''}`}
          breadcrumb={{
            '/blog': 'Blog',
            '/blogItem': `${props?.match?.params?.id ? 'Edit' : 'Create'} ${item?.techType || ''}`
          }}>
          <div>
            <Button name="submitButton" type="primary" htmlType="submit" loading={isSaving}>
              Save changes
            </Button> 
          </div>
        </HeaderTable>
        <div className="application-tab-container">
          <Tabs defaultActiveKey="fr" type="card">  
                <TabPane tab={<Form.Item shouldUpdate style={{marginBottom: 0}}>{() => {
                  const status = form.getFieldValue(['fr', "status"]);
                  return <span>FR {!!status && <b style={{color: getStatusColor(status?.code)}}>({status?.description})</b>}</span>
                }}</Form.Item>} key="fr" forceRender>
                  <Card bordered={false} loading={!item && !!props?.match?.params?.id}>
                    <Row gutter={60}>
                      <Col span={18}>
                        <Form.Item label="Title" name={['fr', 'title']}>
                          <Input/>
                        </Form.Item>
                        <Form.Item label="Slug" name={['fr', 'slug']}>
                          <Input readOnly bordered={false} className="application-generated-input"/>
                        </Form.Item>
                        <Form.Item label="Picture" name="pictures">
                          <div className="dropbox">
                              <Upload
                                name="filesPicture"
                                listType="picture"
                                accept=".png, .jpg, .jpeg"
                                beforeUpload={() => false}
                                defaultFileList={pictures?.fr || []}
                                onChange={(e) => setPictures({...pictures, fr: e.fileList})}>
                                  {!pictures?.fr?.length && <Button>Upload</Button>} 
                              </Upload>
                          </div>
                        </Form.Item>
                        <Form.Item label="Content" name={['fr', 'content']}>
                          <TextArea rows={9}/>
                        </Form.Item>
                        <Form.Item label="Excerpt" name={['fr', 'excerpt']}>
                          <TextArea rows={3}/>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <div className="blog-col">
                          <Form.Item name={['fr', "status"]} shouldUpdate hidden/>
                          <Form.Item shouldUpdate style={{marginBottom: '12px'}}>
                            {() => {
                              const status = form.getFieldValue(['fr', "status"]);
                              return <span>Status: {!!status && <b style={{color: getStatusColor(status?.code)}}>({status?.description})</b>}</span>
                            }}
                          </Form.Item>
                          <Form.Item label="Publish date" name={['fr', "publishedDate"]}>
                            <DatePicker style={{width: '100%'}}/>
                          </Form.Item>
                          <Form.Item>
                            <Button className={'secondary-button'} size="large" style={{width: '100%'}}
                              disabled={!props?.match?.params?.id}
                              onClick={() => redirectBlogPreview(props?.match?.params?.id, form.getFieldValue(['fr', 'slug']), 'fr')}>Preview</Button>
                          </Form.Item>
                          <Form.Item shouldUpdate>
                            {() => {
                              const statusCode = form.getFieldValue(['fr', "status", "code"]);
                              return (
                                <Button size="large" type={statusCode === 'draft' ? "primary" : "default"} style={{width: '100%'}} 
                                  onClick={() => togglePublish('fr')}>
                                  {statusCode === 'draft' ? 'Publish' : 'Unpublish'}
                                </Button>
                              )
                            }}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </TabPane>
                <TabPane tab={<Form.Item shouldUpdate style={{marginBottom: 0}}>{() => {
                  const status = form.getFieldValue(['en', "status"]);
                  return <span>EN {!!status && <b style={{color: getStatusColor(status?.code)}}>({status?.description})</b>}</span>
                }}</Form.Item>} key="en" forceRender>
                <Card bordered={false} loading={!item && !!props?.match?.params?.id}>
                    <Row gutter={60}>
                      <Col span={18}>
                        <Form.Item label="Title" name={['en', 'title']}>
                          <Input/>
                        </Form.Item>
                        <Form.Item label="Slug" name={['en', 'slug']}>
                          <Input readOnly bordered={false} className="application-generated-input"/>
                        </Form.Item>
                        <Form.Item label="Picture" name="pictures">
                          <div className="dropbox">
                              <Upload
                                name="filesPicture"
                                listType="picture"
                                accept=".png, .jpg, .jpeg"
                                beforeUpload={() => false}
                                defaultFileList={pictures?.en || []}
                                onChange={(e) => setPictures({...pictures, en: e.fileList})}>
                                  {!pictures?.en?.length && <Button>Upload</Button>} 
                              </Upload>
                          </div>
                        </Form.Item>
                        <Form.Item label="Content" name={['en', 'content']}>
                          <TextArea rows={9}/>
                        </Form.Item>
                        <Form.Item label="Excerpt" name={['en', 'excerpt']}>
                          <TextArea rows={3}/>
                        </Form.Item>
                      </Col>
                      <Col span={6}>
                        <div className="blog-col">
                          <Form.Item name={['en', "status"]} shouldUpdate hidden/>
                          <Form.Item shouldUpdate style={{marginBottom: '12px'}}>
                            {() => {
                              const status = form.getFieldValue(['en', "status"]);
                              return <span>Status: {!!status && <b style={{color: getStatusColor(status?.code)}}>({status?.description})</b>}</span>
                            }}
                          </Form.Item>
                          <Form.Item label="Publish date" name={['en', "publishedDate"]}>
                            <DatePicker style={{width: '100%'}}/>
                          </Form.Item>
                          <Form.Item>
                            <Button className={'secondary-button'} size="large" style={{width: '100%'}}
                              disabled={!props?.match?.params?.id}
                              onClick={() => redirectBlogPreview(props?.match?.params?.id, form.getFieldValue(['en', 'slug']), 'en')}>Preview</Button>
                          </Form.Item>
                          <Form.Item shouldUpdate>
                            {() => {
                              const statusCode = form.getFieldValue(['en', "status", "code"]);
                              return (
                                <Button size="large" type={statusCode === 'draft' ? "primary" : "default"} style={{width: '100%'}} 
                                  onClick={() => togglePublish('en')}>
                                  {statusCode === 'draft' ? 'Publish' : 'Unpublish'}
                                </Button>
                              )
                            }}
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Card>
                </TabPane>
            </Tabs>
            </div>
      </Form>
  );
};

export default BlogEditPage;
