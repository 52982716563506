import React, {useContext, useEffect, useState} from 'react';
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  notification,
  Row,
  Upload,
  Tabs,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ListPageServices from '../../services/PropertiesService';
import DictionariesServices from '../../services/DictionariesServices';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import { imageURLToAntdModel } from '../../helpers/imageToAntdModel';
import moment from 'moment';
import {AppContext} from '../../contexts/AppContextProvider';
import TextEditor from "../../components/TextEditor";
import { TechTypes } from '../../reducers/appReducer';
import { GroupModel } from '../../models/GroupModel';
import PropertyCard from './components/PropertyCard';
import { PropertyModel } from '../../models/PropertyModel';
import AddressModal from './../../components/AddressModal/AddressModal';
import { AppartmentModel } from '../../models/AppartmentModel';
import useQuery from '../../hooks/useQuery';

const { TextArea } = Input;
const { TabPane } = Tabs;

const languages = ['fr', 'en'];

const EditPropertyPage = (props: any) => {
  const {app} = useContext(AppContext);
  const [form] = Form.useForm();

  const service = new ListPageServices();
  const dictionariesService = new DictionariesServices();

  const [item, setItem] = useState(null as any);
  const [propertyAddress, setPropertyAddress] = useState<AppartmentModel['address'] | null>(null);

  const [statuses, setStatuses] = useState([]);
  const [features, setFeatures] = useState([]);
  const [types, setTypes] = useState([]);
  const [brokers, setBrokers] = useState([]);
  const [groups, setGroups] = useState<GroupModel[]>([]);
  const [orientations, setOrientations] = useState([]);
  const [wobblers, setWobblers] = useState([]);
  const [propertyFields, setFields] = useState([]);
  const [isShowAddSelect, setIsShowAddSelect] = useState(false);


  const [thumbnails, setThumbnail] = useState([] as any[]);
  const [pictures, setPictures] = useState([] as any[]);
  const [threeDPlan, setThreeDPlan] = useState([] as any[]);
  const [twoDPlan, setTwoDPlan] = useState([] as any[]);
  const [certificate, setCertificate] = useState([] as any[]);

  const [isSaving, setIsSaving] = useState(false);
  const [isTogglingPublish, setiIsTogglingPublish] = useState(false);

  const query = useQuery();
  const duplicate = query.get('duplicate');
  const techType = query.get('type') as TechTypes;

  const isLoading = !item && (!!props?.match?.params?.id || !!duplicate);
  const [isGroupsTreeLoading, setGroupsTreeLoading] = useState(false);
  const [addressModalData, setAddressModalData] = useState(null as any);


  useEffect(() => {
    form.setFieldsValue({ 
      address: { 
      ...propertyAddress 
    }});
  }, [propertyAddress]);

  useEffect(() => {
    if(item) {
      form.setFieldsValue({
        ...item,
        availabilityDate: item.availabilityDate ? moment(item.availabilityDate) : null,
        features : {code: item.features?.map((item: any) => item.code) || []},
      });
      setThumbnail(item?.thumbnails ? item.thumbnails.map((image: any) => imageURLToAntdModel(image)) : []);
      setPictures(item?.pictures ? item.pictures.map((image: any) => imageURLToAntdModel(image)) : []);
      setThreeDPlan(item?.threeDPlan ? item.threeDPlan.map((image: any) => imageURLToAntdModel(image)) : []);
      setTwoDPlan(item?.twoDPlan ? item.twoDPlan.map((image: any) => imageURLToAntdModel(image)) : [] as any[]);
      setCertificate(item?.certificate ? item.certificate.map((image: any) => imageURLToAntdModel(image)) : []);
    }
  }, [item]);

  useEffect(() => {
    getDictionaries();
    if(props?.match?.params?.id) {
      getData(props.match.params.id);
    }
    if(duplicate) {
      getDuplicateData(duplicate);
    }
  }, [props?.match?.params?.id, duplicate]);

  const handleShowSelect = (isShow?: boolean) => {
    setIsShowAddSelect(isShow === undefined ? !isShowAddSelect : isShow);
  };

  // NOTE:
  // DO NOT DELETE, MIGHT BE USED IN THE FUTURE
  //
  // const formPropertiesTree = async (data: any[]): Promise<any> => {
  //   return Promise.all(
  //     data.map(async (element: any) => {
  //       const properties = await service.getNested(element.id);
  //       return {
  //         ...element,
  //         children: properties.length ? await formPropertiesTree(properties) : null,
  //       };
  //     }),
  //   );
  // };

  const formParentSequence = async (item: any): Promise<any[]> => {
    if (item?.parent?.id) {
      const property = await service.getPropertyById(item.parent.id);
      const form = await formParentSequence(property);
      return [...form, item.parent.id];
    }
    return [];
  };

  const getData = (id: number | string) => {
    service.getPropertyById(id).then(async data => {
      setItem({
        ...data,
        parent: await formParentSequence(data),
        ...data.translation.reduce((prev: any, curr: any) => {
          return {
            ...prev,
            [curr.language.code]: {
              ...curr
            }
          }
        }, {}),
        fields: data?.fields?.reduce((result: any, item: any) => {
          return {
            ...result,
            [item.field.code]: {
              value: item.value ? (isNaN(item.value) ? item.value : +item.value) : null,
              type: item.field.type,
              title: item.field.title,
              code: item.field.code,
              id: item.id,
              dictionaryName: item.field.dictionaryName,
            },
          };
        }, {})
      });
    });
  };

  const getDuplicateData = (id: number | string) => {
    service.getPropertyById(id).then(async data => {
      setItem({
        ...data,
        parent: await formParentSequence(data),
        code: null,
        thumbnails: [],
        pictures: [],
        threeDPlan: [],
        twoDPlan: [],
        certificate: [],
        published: false,
        address: { ...data.address, id: null },
        ...data.translation.reduce((prev: any, curr: any) => {
          return {
            ...prev,
            [curr.language.code]: {
              ...curr,
              id: null
            }
          }
        }, {}),
        fields: data?.fields?.reduce((result: any, item: any) => {
          return {
            ...result,
            [item.field.code]: {
              value: item.value ? (isNaN(item.value) ? item.value : +item.value) : null,
              type: item.field.type,
              title: item.field.title,
              code: item.field.code,
              id: item.id,
              dictionaryName: item.field.dictionaryName,
            },
          };
        }, {})
      });
    });
  };

  const onFinish = (values: any) => {
    setIsSaving(true);
    const toUpdate = {
      code: values.code || null,
      price: values.price || null,
      aptNo: values.aptNo || null,
      floor: values.floor || null,
      rooms: values.rooms || null,
      totalSurface: values.totalSurface || null,
      orientationTypes: values.orientationTypes || null,
      availabilityDate: values.availabilityDate || null,
      url: values.url || null,
      features: values.features || null,
      showBrokerInfo: values?.showBrokerInfo,
      broker: values?.broker?.id ? values?.broker : null,
      wobbler: values?.wobbler?.code ? values?.wobbler : null,
      type: values?.type?.code ? values?.type : null,
      status: values?.status?.code ? values?.status : null,
      parent: values?.parent ? values.parent[values.parent.length - 1] : null,
      fields: Object.entries(values?.fields || []).map(([code, element]: [any, any]) => ({
        id: item?.fields[code]?.id,
        value: element.value || null,
        field: propertyFields.find((item: any) => item.code === code),
      })),
      translation: languages.map(language => ({
        ...(item && item[language]?.id ? { id: item[language].id } : {}),
        language: { code: language },
        shortDescription: values[language]?.shortDescription || null,
        description: values[language]?.description || null,
        headline: values[language]?.headline || null,
      })),
      ...(props?.match?.params?.id ? {} : { published: false }),
      techType: item?.techType || techType,
      ...(values?.address?.addressFull || item?.address?.id ? {
        address: {
          ...propertyAddress,
          ...(!!item?.address?.id ? { id: item.address.id } : {}),
          addressFull: values?.address?.addressFull || null,
        }
      } : {}),
    }
  
    service.saveProperty(toUpdate, props?.match?.params?.id)
      .then((res: any) => {
        return Promise.all([
          service.saveImages(res.id, thumbnails, 'thumbnails'),
          service.saveImages(res.id, pictures, 'pictures'),
          service.saveImages(res.id, twoDPlan, 'twoDPlan'),
          service.saveImages(res.id, threeDPlan, 'threeDPlan'),
          service.saveImages(res.id, certificate, 'certificate')
        ] as Promise<any>[]).then(() => res);
      })
        .then((res) => {
          notification.success({
            message: `Property ${props?.match?.params?.id ? 'updated' : 'created'}`
          });
          setIsSaving(false);
          if (!props?.match?.params?.id) {
            props.history.push(`/property/${res.id}?type=${res.techType}`);
          } else {
            getData(res.id);
          }
        })
      .catch((e) => {
        setIsSaving(false)
        notification.error(e.message ? e : {
          message: `Error while property ${props?.match?.params?.id ? 'update' : 'create'}`
        });
      });
  }

  const togglePublish = () => {
    setiIsTogglingPublish(true);
    service.saveProperty({published: !item.published}, props?.match?.params?.id).then(() => {
      notification.success({
        message: `Property ${item?.published ? 'un': ''}published`
      });
      getData(props?.match?.params?.id);
      setiIsTogglingPublish(false);
    }).catch(() => (notification.error({
        message: `Error while ${item?.published ? 'un': ''}publishing`
    }), setiIsTogglingPublish(false)));
  }

  const getGroupsTreeData = () => {
    setGroupsTreeLoading(true);
    service.getPropertyWithFilter(null, {or: {techType: TechTypes.GROUP}}, null, null)
      .then(data => {
        setGroups(techType === TechTypes.APARTMENT ? data : data.map((item: PropertyModel) => ({ ...item, childrens: null })));
    })
    .finally(() => setGroupsTreeLoading(false));
  }

  const getDictionaries = () => {
    dictionariesService.getDictionary('propertyStatuses').then(data => {
      setStatuses(data);
    });
    dictionariesService.getDictionary('propertyFeatures').then(data => {
      setFeatures(data);
    });
    dictionariesService.getDictionary('propertyTypes').then(data => {
      setTypes(data);
    });
    dictionariesService.getDictionary('propertyFields').then(data => {
      setFields(data);
    });
    dictionariesService.getDictionary('broker').then(data => {
      setBrokers(data);
    });
    dictionariesService.getOrientationTypes().then(data => {
      setOrientations(data);
    });
    dictionariesService.getDictionary('propertyWobblers').then(data => {
      setWobblers(data);
    });

    if(techType !== TechTypes.GROUP)
      getGroupsTreeData();
  };
  
  return (
    <div>
      <AddressModal
      item={item}
      path={addressModalData}
      handleCancel={() => setAddressModalData(null)}
      handleOk={(item) => {
          setPropertyAddress(item.address);
          setAddressModalData(null);
      }} />
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={techType == 'group' ? {
          en: {
            description: "slide1_title: <br/>slide1_description: <br/>slide2_title: <br/>slide2_description: <br/>slide3_title: <br/>slide3_description: ",
            shortDescription: "head_title: \nhead_desc: "
          },
          fr: {
            description: "slide1_title: <br/>slide1_description: <br/>slide2_title: <br/>slide2_description: <br/>slide3_title: <br/>slide3_description: ",
            shortDescription: "head_title: \nhead_desc: "
          }
        } : {}
        }
      >
        <HeaderTable 
          title={`${props?.match?.params?.id ? 'Edit' : 'Create'} ${item?.techType || ''}`}
          breadcrumb={{
            '/properties': 'Properties',
            '/property': `${props?.match?.params?.id ? 'Edit' : 'Create'} ${item?.techType || ''}`
          }}>
          <div>
            <Button loading={isTogglingPublish} className={!item?.published ? 'primary-button' : 'secondary-button'} style={{marginRight: '24px'}}
                disabled={!props?.match?.params?.id} onClick={togglePublish}>
                {!item?.published ? 'Publish' : 'Unpublish'}
            </Button> 
            <Button loading={isSaving} name="submitButton" type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </HeaderTable>
        <Row gutter={24}>
          <Col span={12}>
            <PropertyCard
              isGroupsTreeLoading={isGroupsTreeLoading}
              isLoading={isLoading}
              statuses={statuses}
              types={types}
              brokers={brokers}
              groups={groups}
              orientations={orientations}
              features={features}
              wobblers={wobblers}
              isShowAddSelect={isShowAddSelect}
              handleShowSelect={handleShowSelect}
              propertyFields={propertyFields}
              item={item}
              techType={techType}
              setAddressModalData={setAddressModalData}
            />
          </Col>
          <Col span={12}>
            <div className="application-tab-container">
            <Tabs defaultActiveKey="fr" type="card">  
            {
              languages.map(language => (
                <TabPane tab={language.toUpperCase()} key={language} forceRender>
                  <Card bordered={false} loading={isLoading}>
                    <Form.Item label="Headline" name={[language, 'headline']}>
                      <Input/>
                    </Form.Item>
                    <Form.Item label="Summary (short description)" name={[language, 'shortDescription']}>
                      <TextArea 
                      rows={3} />
                    </Form.Item>
                    <Form.Item label="Description" name={[language, 'description']}>
                      <TextEditor/>
                    </Form.Item>
                  </Card>
                </TabPane>
              ))
            }
            </Tabs>
            </div>
          </Col>
          <Col span={24}>
            <Card loading={isLoading}>
            <Row gutter={24}>
                <Col span={5}>
                  <Form.Item label="Thumbnail" name="thumbnails">
                    <div className="dropbox">
                        <Upload.Dragger
                          listType="picture"
                          accept=".png, .jpg, .jpeg"
                          beforeUpload={() => false}
                          fileList={thumbnails}              
                          onChange={(e) => {setThumbnail(e.fileList)}}
                          multiple>
                          <p
                            className="ant-upload-drag-icon"
                            style={{marginTop: 0, marginBottom: 0}}>
                            <PlusOutlined/>
                          </p>
                        </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label="Picture" name="pictures">
                    <div className="dropbox">
                        <Upload.Dragger
                          name="filesPicture"
                          listType="picture"
                          accept=".png, .jpg, .jpeg"
                          beforeUpload={() => false}
                          fileList={pictures}
                          onChange={(e) => setPictures(e.fileList)}
                          multiple>
                          <p
                            className="ant-upload-drag-icon"
                            style={{marginTop: 0, marginBottom: 0}}>
                            <PlusOutlined/>
                          </p>
                        </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label={ techType == 'group' ? "Logo" : "3D Plan"} name="threeDPlan">
                    <div className="dropbox">
                        <Upload.Dragger
                          name="filesThreeDPlan"
                          listType="picture"
                          accept=".png, .jpg, .jpeg"
                          beforeUpload={() => false}
                          fileList={threeDPlan}
                          onChange={(e) => setThreeDPlan(e.fileList)}
                          multiple>
                          <p
                            className="ant-upload-drag-icon"
                            style={{marginTop: 0, marginBottom: 0}}>
                            <PlusOutlined/>
                          </p>
                        </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={5}>
                  <Form.Item label={ techType == 'group' ? "Slides" : "2D Plan"} name="twoDPlan">
                    <div className="dropbox">
                        <Upload.Dragger
                          name="filesTwoDPlan"
                          listType="picture"
                          accept=".png, .jpg, .jpeg"
                          beforeUpload={() => false}
                          fileList={twoDPlan}
                          onChange={(e) => setTwoDPlan(e.fileList)}
                          multiple>
                          <p
                            className="ant-upload-drag-icon"
                            style={{marginTop: 0, marginBottom: 0}}>
                            <PlusOutlined/>
                          </p>
                        </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Col>
                <Col span={4}>
                  <Form.Item label={ techType == 'group' ? "Icons" : "Certificate"} name="certificate">
                    <div className="dropbox">
                        <Upload.Dragger
                          name="filesCertificate"
                          listType="picture"
                          accept=".png, .jpg, .jpeg"
                          beforeUpload={() => false}
                          fileList={certificate}
                          onChange={(e) => setCertificate(e.fileList)}
                          multiple>
                          <p
                            className="ant-upload-drag-icon"
                            style={{marginTop: 0, marginBottom: 0}}>
                            <PlusOutlined/>
                          </p>
                        </Upload.Dragger>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditPropertyPage;
