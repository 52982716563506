const processResponse = (response: any, responseProcessor: any) => {
  if (response) {
    responseProcessor = responseProcessor || 'json';
    if (response.status === 204) {
      return null;
    } else if (response.status === 413) {
      throw response;
    } else {
      // NOTE: this code may be deleted if no bugs found
      // this code prevents getting error message for 404 responses
      // if (response.status === 404) {
      //   throw response;
      // }
      return response[responseProcessor]().then((data: any) => {
        if (!response.ok) {
          if (data.message) {
            response.message = data.message;
          }
          if (data.data) {
            response.data = data.data;
          } 
          if (data.error) {
            response.error = data.error;
          }
          throw response;
        } else {
          return data;
        }
      });
    }
  } else {
    return null;
  }
};

const executeFetch = (url: string, options: any, responseProcessor?: any) => {
  return fetch(url, options).then((response) => processResponse(response, responseProcessor));
};

const processOptions = (options: any, method: string, body: any) => {
  options = options || {};
  options.method = method || 'GET';
  if (body && options.method !== 'GET') {
    options.body = body;
  }
  if (!options.headers || !options.headers['Content-Type']) {
    options.headers = {
      'Content-Type': 'application/json;charset=utf-8',
      'Accept-Language': 'ua'
    };
  }

  return options;
};

class HttpService {
  public post(url: string, body: any, options?: any) {
    options = processOptions(options, 'POST', body ? JSON.stringify(body) : null);
    return executeFetch(url, options);
  }

  public postFile(url: string, body: any, options?: any) {
    options = options || {};
    options.method = 'POST';
    options.body = body;
    return executeFetch(url, options);
  }

  public postResFile(url: string, body: any, options?: any) {
    options = processOptions(options, 'POST', body ? JSON.stringify(body) : null);
    return executeFetch(url, options, 'blob');
  }

  public get(url: string, options: any = null) {
    options = processOptions(options, 'GET', null);
    return executeFetch(url, options);
  }

  public getFile(url: string, options?: any) {
    options = options || {};
    options.method = options.method || 'GET';
    return executeFetch(url, options, 'blob');
  }

  public put(url: string, body: any, options?: any) {
    options = processOptions(options, 'PUT', body ? JSON.stringify(body) : null);
    return executeFetch(url, options);
  }

  public delete(url: string, body: any, options?: any) {
    options = processOptions(options, 'DELETE', body ? JSON.stringify(body) : null);
    return executeFetch(url, options);
  }
}

export default HttpService;
