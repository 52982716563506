const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

export function imageURLToAntdModel(image: any) {
    return {
        thumbUrl: `${BASE_URL}image/binary/${image.name}`,
        name: image?.name,
        uid: image?.id ? String(image?.id) : image?.name,
        type: `image/${image.name.substr(image.name.indexOf('.') + 1)}`,
        size: 0
    };
}
