import HttpService from './common/httpService';
import buildQuery from 'odata-query';
import { ApplicationHomebuyerDocumentTypes } from '../models/enums/ApplicationHomeBuyerDocumentTypes';
import { ApplicationHomebuyerDocument, ApplicationHomebuyerDocumentFile, ApplicationModel, IManyEditsBody } from '../models/ApplicationModel';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

export enum ApplicationTypes {
  full = 'full',
  simplified = 'simpified',
  preCalendly = 'preCalendly',
  backOffice = 'backOffice'
}

class ApplicationService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getItems(searchObjects: any, pagingObject: any) {
    let orderBy: any = null;
    let filter: any = {and: []};
    let top: any = null;
    let skip: any = null;
    let count: boolean = true;

    filter.and.push(
      'deletionDate eq null'
    );

    //Search part
    if (searchObjects) {
      if(searchObjects.fullName){
        const value = encodeURI(searchObjects.fullName.replace(/[/\\!@#$%^&*)(+=.,_-]/g, '').replace(/`/g, `'`).toLowerCase());
        filter.and.push({or: [
          {[`tolower(homebuyer/firstName)`]: {contains: value}},
          {[`tolower(homebuyer/lastName)`]: {contains: value}}
        ]});
      }
      if(searchObjects.status){
        filter.and.push({
          or: searchObjects.status.map((status: any) => (
            {[`status/code`]: {contains: status}}
          ))
        });
      }
      if(searchObjects.email){
        const value = encodeURI(searchObjects.email);
        filter.and.push(
          {[`tolower(homebuyer/email)`]: {contains: value}}
        );
      }
      if(searchObjects.type){
        filter.and.push({
          or: searchObjects.type.map((type: ApplicationTypes) => (
            {[`type/code`]:  type }
          ))
        });
      }
      if(searchObjects?.createdDateRange){
        filter.and.push({and: [
          {[`createdDate`]: {ge: searchObjects.createdDateRange[0].toISOString()}},
          {[`createdDate`]: {le: searchObjects.createdDateRange[1].toISOString()}}
        ]});
      }
    }

    // Paging part
    if (pagingObject) {
      top = pagingObject.sizePerPage;
      skip = (pagingObject.page - 1) * pagingObject.sizePerPage;
    }

    const query = buildQuery({filter, orderBy, top, skip, count});
    return this.httpService.get(`${BASE_URL}homebuyer/application${query}`);
  }

  public getItemsCSV(searchObjects: any) {
    let orderBy: any = null;
    let filter: any = {and: []};
    let top: any = null;
    let skip: any = null;
    let count: boolean = true;

    filter.and.push(
      'deletionDate eq null'
    );

    //Search part
    if (searchObjects) {
      if(searchObjects.fullName){
        const value = encodeURI(searchObjects.fullName.replace(/[/\\!@#$%^&*)(+=.,_-]/g, '').replace(/`/g, `'`).toLowerCase());
        filter.and.push({or: [
          {[`tolower(homebuyer/firstName)`]: {contains: value}},
          {[`tolower(homebuyer/lastName)`]: {contains: value}}
        ]});
      }
      if(searchObjects.status){
        filter.and.push({
          or: searchObjects.status.map((status: any) => (
            {[`status/code`]: {contains: status}}
          ))
        });
      }
      if(searchObjects.email){
        const value = encodeURI(searchObjects.email);
        filter.and.push(
          {[`tolower(homebuyer/email)`]: {contains: value}}
        );
      }
      if(searchObjects?.createdDateRange){
        filter.and.push({and: [
          {[`createdDate`]: {ge: searchObjects.createdDateRange[0].toISOString()}},
          {[`createdDate`]: {le: searchObjects.createdDateRange[1].toISOString()}}
        ]});
      }
    }

    const query = buildQuery({filter, orderBy, top, skip, count});
    return this.httpService.get(`${BASE_URL}homebuyer/application/csv${query}`);
  }

  public deleteItems(id: number[]) {
    return this.httpService.delete(`${BASE_URL}homebuyer/application`, {id});
  }

  public getItem(id: string){
    return this.httpService.get(`${BASE_URL}homebuyer/application/${id}`);
  }

  public saveItem(body: any, id?: number) {
    return this.httpService[id ? 'put' : 'post'](`${BASE_URL}homebuyer/application${id ? `/${id}`:``}`, body);
  }

  public generatePDF(applicationID: string){
    return this.httpService.postResFile(`${BASE_URL}report/generate/application-pdf`, { applicationID });
  }

  public cancelEmail(id: number){
    return this.httpService.post(`${BASE_URL}homebuyer/application/email/cancel`, { id });
  }

  public getStats() {
    return this.httpService.get(`${BASE_URL}homebuyerApplicationStats`);
  }

  public createChildHomebuyer(applicationId: number): Promise<void> {
    return this.httpService.put(`${BASE_URL}homebuyer/child/${applicationId}`, null)
  }

  public deleteChildHomebuyer(childId: number): Promise<void> {
    return this.httpService.delete(`${BASE_URL}homebuyer/child/${childId}`, null)
  }

  public createHomebuyerDocuments(homebuyers: string[], types: ApplicationHomebuyerDocumentTypes[]): Promise<void> {
    return this.httpService.post(`${BASE_URL}homebuyer/documents`, {
      homebuyers,
      types,
    })
  }

  public uploadDocumentFile(applicationId: ApplicationModel['id'], documentId: ApplicationHomebuyerDocument['id'], file: FormData): Promise<void> {
    return this.httpService.postFile(`${BASE_URL}homebuyer/${applicationId}/documents/${documentId}/file`, file)
  }

  public deleteHomebuyerDocument(documentId: ApplicationHomebuyerDocument['id']): Promise<void> {
    return this.httpService.delete(`${BASE_URL}homebuyer/documents/${documentId}`, null)
  }

  public deleteDocumentFile(fileId: ApplicationHomebuyerDocumentFile['id']): Promise<void> {
    return this.httpService.delete(`${BASE_URL}homebuyer/documents/file/${fileId}`, null)
  }

  public getDocumentFile(fileName: string): Promise<Blob> {
    return this.httpService.getFile(`${BASE_URL}file/binary/${fileName}`);
  }

  public validateDocument(id: ApplicationHomebuyerDocument['id'], validationPassed: boolean) {
    return this.httpService.put(`${BASE_URL}homebuyer/documents/validate`, {
      id,
      validate: validationPassed,
    });
  }

  public notifyBroker(id: ApplicationModel['id']) {
    return this.httpService.get(`${BASE_URL}homebuyer/application/${id}/notifyBroker`);
  }

  public editMany(body: IManyEditsBody) {
    return this.httpService.put(`${BASE_URL}homebuyer/application`, body);
  }
}

export default ApplicationService;
