import React, {useCallback, useEffect, useState} from 'react';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {notification, Table, Button, Popconfirm} from 'antd';
import {columns} from './components/FaqColumns';
import FaqService from '../../services/FaqService';

import {Link} from 'react-router-dom';
import DragableTableRow from '../../components/DragableTableRow/DragableTableRow';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { FaqModel } from '../../models/FaqModel';

const breadcrumbNameMap = {
  '/blog': 'Blog',
};

const BlogPage = () => {
  const service = new FaqService();

  const [data, setData] = useState<FaqModel[]>([]);
  
  const [isLoading, setIsLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]) as any[];

  useEffect(() => {
    getData();
  }, []);

  const deleteItems = (selectedRowKeys: number[]) => {
    service.deleteFaqItems(selectedRowKeys).then(() => {
      notification.success({ message: 'FAQ items deleted' });
      getData();
    });
  }

  const getData = () => {
    setIsLoading(true);
    service.getFaqList().then(data => {
      setData(data);
      setSelectedRowKeys([]);
      setIsLoading(false);
    }).catch(() => {
      notification.error({
        message: 'Error while loading faq items.'
      });
      setIsLoading(false);
    });
  };

  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if(dragIndex !== hoverIndex) {
        const firstOrder = 1;
        const toUpdate: any[] = [];
        const newData = data.slice(0);
        const dragRow = newData.splice(dragIndex, 1)[0];
        newData.splice(hoverIndex, 0, dragRow);
        newData.forEach((item, index) => {  
          const newOrder = index + firstOrder;
          if(newOrder !== item.ord)
          toUpdate.push(({ id: item.id, ord: newOrder }));       
        });;
        setIsLoading(true);
        service.changeOrder(toUpdate)
          .then((res) => {
            getData();
          })
      }
    },
  [data]);

  const components = {
    body: {
      row: DragableTableRow,
    }
  };

  return (
    <>
      <HeaderTable title='FAQ list' breadcrumb={breadcrumbNameMap}>
        <span>
          <span style={{ marginRight: 8, color: "#ebae60" }}>
            {selectedRowKeys.length ? `Selected ${selectedRowKeys.length} items` : ''}
          </span>
          <Popconfirm placement="bottom" disabled={!selectedRowKeys.length} title="Are you sure you want to delete selected items?" okText="Yes" cancelText="No" onConfirm={() => deleteItems(selectedRowKeys)}>
            <Button type="primary" className="danger-button" style={{width: 100}} disabled={!selectedRowKeys.length}>Delete</Button>
          </Popconfirm>
          <Link to="/faqItem"><Button type="primary" style={{marginLeft: '10px', width: 100}}>+ New</Button></Link>
        </span>
      </HeaderTable>
      <DndProvider backend={HTML5Backend}>
      <Table
        className="dnd-table"
        columns={columns(deleteItems)}
        loading={isLoading}
        dataSource={data}
        showHeader={true}
        pagination={{ 
          defaultPageSize: 10, 
          showSizeChanger: true, 
          pageSizeOptions: ['10', '20', '30'],
        }}
        rowKey={record => record.id}
        rowSelection={{
          selectedRowKeys,
          onChange: setSelectedRowKeys
        }}
        onRow={(_record, index) => ({
          index,
          moveRow,
        } as any)}
        components={components}
      />
      </DndProvider>
    </>
  );
};

export default BlogPage;
