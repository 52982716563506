import React, { useState } from 'react';
import { Button, Select, Col, Form, Checkbox, Input, InputNumber, DatePicker } from 'antd';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { SelectValue } from 'antd/lib/select';
import { checkDigit } from '../../../helpers/checkDigit';
import DictionaryFieldSelect from '../components/DictionaryFieldSelect';

interface DynamicFieldsModel {
  name: string;
  isSelect: boolean;
  handleSelect: (isShow?: any) => void;
  fields: any[];
  values: any;
}

const DynamicFields: React.FC<DynamicFieldsModel> = ({
  name,
  isSelect,
  handleSelect,
  fields,
  values,
}: DynamicFieldsModel) => {
  const [params, setParams] = useState(values);

  const getFieldName = (item: any) => `${item.paramsCode || item.param?.code || item.code}`;

  const getLabel = (code: string, label: string) => {
    return (
      <>
        <span>{label}</span>
        <CloseOutlined style={{ cursor: 'pointer' }} onClick={() => deleteParam(code)} />
      </>
    );
  };

  const selectFilterOption = (inputValue: any, option: any) => {
    return option.props.children.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;
  };

  const onChange = (params: any) => {
    setParams(params);
  };

  const handleParamSelect = (param: SelectValue) => {
    const item = fields.find((item: any) => item.code === param);
    if (item) {
      onChange({
        ...params,
        [item.code]: {
          value: null,
          type: item.type,
          title: item.title,
          code: item.code,
          dictionaryName: item.dictionary_name || item.dictionaryName,
        },
      });
      handleSelect(false);
    }
  };

  const deleteParam = (code: string) => {
    const toUpdate = { ...params };
    delete toUpdate[code];
    setParams(toUpdate);
  };

  const filteredParams = fields.filter((item: any) => {
    return !params ? true : !params[item.code];
  });

  const getParameterField = (item: any) => {
    const fieldName = [name, getFieldName(item), 'value'];
    switch (item.type) {
      case 'boolean':
        return (
          <Form.Item
            name={fieldName}
            label={getLabel(item.code, item.title)}
            valuePropName="checked"
            wrapperCol={{ span: 24 }}>
            <Checkbox style={{ width: '100%' }} />
          </Form.Item>
        );
      case 'number': {
        return (
          <Form.Item
            name={fieldName}
            label={getLabel(item.code, item.title)}
            rules={[{ required: true, message: 'Field is required' }]}
            wrapperCol={{ span: 24 }}>
            <InputNumber
              style={{ width: '100%' }}
              className="faq-modify-select"
              onKeyDown={(e) => {
                if (!checkDigit(e.key)) {
                  e.preventDefault();
                }
              }}
            />
          </Form.Item>
        );
      }
      case 'string':
        return (
          <Form.Item
            name={fieldName}
            label={getLabel(item.code, item.title)}
            rules={[{ required: true, message: 'Field is required' }]}
            wrapperCol={{ span: 24 }}>
            <Input style={{ width: '100%' }} className="faq-modify-select" />
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            name={fieldName}
            label={getLabel(item.code, item.title)}
            rules={[{ required: true, message: 'Field is required' }]}
            wrapperCol={{ span: 24 }}>
            <DatePicker style={{ width: '100%' }} showToday />
          </Form.Item>
        );
      case 'timestamp':
        return (
          <Form.Item
            name={fieldName}
            label={getLabel(item.code, item.title)}
            rules={[{ required: true, message: 'Field is required' }]}
            wrapperCol={{ span: 24 }}>
            <DatePicker style={{ width: '100%' }} showTime showToday />
          </Form.Item>
        );
      case 'dictionary':
        return (
          <DictionaryFieldSelect
            name={fieldName}
            label={getLabel(item.code, item.title)}
            dictionaryName={item.dictionaryName}
            keyField="id"
            showField="description"
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {!!Object.keys(params).length &&
        Object.values(params).map((item: any) => (
          <Col span={12} key={item.code}>
            {getParameterField(item)}
          </Col>
        ))}
      {isSelect ? (
        <Select
          autoFocus
          defaultOpen
          placeholder={<span style={{ color: '#778dac' }}>{'Select a field'}</span>}
          onChange={handleParamSelect}
          onBlur={handleSelect}
          showSearch
          filterOption={selectFilterOption}
          style={{ width: '100%' }}>
          {filteredParams.map((item) => (
            <Select.Option key={item.code} value={item.code}>
              {item.title}
            </Select.Option>
          ))}
        </Select>
      ) : (
        <Button
          type="dashed"
          onClick={handleSelect}
          className="add-field-btn"
          icon={<PlusOutlined style={{ color: '#778dac' }} />}>
          <span style={{ color: '#778dac', fontWeight: 600 }}>{'Add field'}</span>
        </Button>
      )}
    </>
  );
};

export default DynamicFields;
