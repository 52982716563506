import {notification} from 'antd';

const round = (number: number) => Math.round(number*100)/100;

const calcPropertyAcquisitionFee = (propertyPrice: number, ACQUISITION_FEE: number): number => 
    propertyPrice * ACQUISITION_FEE;


const CalcMonthlyInvestorPayment = (investorLoan: number, INVESTOR_ROI: number, EVA_CONTRACT_DURATION_YEARS: number): number => {
    const rate = INVESTOR_ROI / 12
    return (rate + rate / ( Math.pow( 1 + rate, EVA_CONTRACT_DURATION_YEARS * 12) - 1 )) * investorLoan;
}

const CalcMortgageAmount = (propertyPrice: number, DEPOSIT_RATE: number): number => {
    return propertyPrice * (1 - DEPOSIT_RATE);
}

export const CalcInvestorLoanNeeded = (propertyPrice: number, totalFundsAvailable: number, DEPOSIT_RATE: number, 
        acquisitionFeePaidBy?: string, ACQUISITION_FEE?: number): number => {
    let investorLoan =  DepositNeededMortgage(propertyPrice, DEPOSIT_RATE) - totalFundsAvailable;

    if ("investor" === acquisitionFeePaidBy) {
        investorLoan += calcPropertyAcquisitionFee(propertyPrice, ACQUISITION_FEE || 0)   
    }
    
    return investorLoan;
}

const CalcTotalTheoreticalAnnualCharges = (
        propertyPrice: number, mortgageAmount: number, investorLoan: number, 
        THEORETICAL_MORTGAGE_INTEREST_RATE: number, MORTGAGE_AMORTIZATION_YEARS: number, EVA_FEE_RATE: number, 
        THEORETICAL_MAINTENANCE_FEE_RATE: number, INVESTOR_ROI: number, EVA_CONTRACT_DURATION_YEARS: number
    ): number => {
    const annualMortgageInterest = mortgageAmount * THEORETICAL_MORTGAGE_INTEREST_RATE;
    const annualMortgageAmortization = propertyPrice * 0.14 /  MORTGAGE_AMORTIZATION_YEARS;
    const annualEvaServiceFee = propertyPrice * EVA_FEE_RATE;
    const annual_maintenance_fees = propertyPrice * THEORETICAL_MAINTENANCE_FEE_RATE
    const annualInvestorPayment = CalcMonthlyInvestorPayment(investorLoan, INVESTOR_ROI, EVA_CONTRACT_DURATION_YEARS) * 12;
    return  annualMortgageInterest + annualMortgageAmortization + annualEvaServiceFee + annual_maintenance_fees + annualInvestorPayment;
}

const calcTotalMonthlyCharges = (
    propertyPrice: number, DEPOSIT_RATE: number,
    MORTGAGE_INTEREST_RATE: number, MORTGAGE_AMORTIZATION_YEARS: number, monthlyInvestorPayment: number, 
    EVA_FEE_RATE: number, monthlyAdditionalChargesOnProperty: number ) => {
  const mortgageAmount = propertyPrice * (1 - DEPOSIT_RATE); 
  const annualMortgageInterest = mortgageAmount * MORTGAGE_INTEREST_RATE;
  const annualMortgageAmortization = propertyPrice * 0.14 /  MORTGAGE_AMORTIZATION_YEARS;

  const annualEvaServiceFee = propertyPrice * EVA_FEE_RATE;
 
  const monthlyMortgageInterest = annualMortgageInterest / 12;
  const monthlyMortgageAmortization = annualMortgageAmortization / 12;
  const totalMonthlyChargesWithoutFees = monthlyMortgageInterest + monthlyMortgageAmortization + monthlyInvestorPayment;

  const monthlyEvaServiceFee = annualEvaServiceFee / 12;
  return totalMonthlyChargesWithoutFees + monthlyEvaServiceFee + monthlyAdditionalChargesOnProperty;
}

const calcTotalMonthlyChargesWithResidualValue = (propertyPrice: number, DEPOSIT_RATE: number,
    MORTGAGE_INTEREST_RATE: number, MORTGAGE_AMORTIZATION_YEARS: number, monthlyInvestorPayment: number, 
    EVA_FEE_RATE: number, monthlyAdditionalChargesOnProperty: number, residualValue: number, EVA_CONTRACT_DURATION_YEARS: number) => {
  const monthlyCharges = calcTotalMonthlyCharges(propertyPrice, DEPOSIT_RATE, MORTGAGE_INTEREST_RATE, 
    MORTGAGE_AMORTIZATION_YEARS, monthlyInvestorPayment, EVA_FEE_RATE, monthlyAdditionalChargesOnProperty);
  return monthlyCharges - residualValue / EVA_CONTRACT_DURATION_YEARS / 12
}


const EffortRate = (
        propertyPrice: number, mortgageAmount: number,investorLoan: number, annualIncome: number,
        THEORETICAL_MORTGAGE_INTEREST_RATE: number, MORTGAGE_AMORTIZATION_YEARS: number, EVA_FEE_RATE: number, 
        THEORETICAL_MAINTENANCE_FEE_RATE: number, INVESTOR_ROI: number, EVA_CONTRACT_DURATION_YEARS: number
    ): number => {
    return CalcTotalTheoreticalAnnualCharges(propertyPrice, mortgageAmount, investorLoan, 
        THEORETICAL_MORTGAGE_INTEREST_RATE, MORTGAGE_AMORTIZATION_YEARS, EVA_FEE_RATE,
        THEORETICAL_MAINTENANCE_FEE_RATE, INVESTOR_ROI, EVA_CONTRACT_DURATION_YEARS
    ) / annualIncome * 100;
}

const PropertyToRevenueRatio = (propertyPrice: number, annualIncome: number): number => {
    return annualIncome ? propertyPrice / annualIncome : -1;
}

const DepositRate = (totalFundsAvailable: number, propertyPrice: number): number => {
    return propertyPrice ? totalFundsAvailable / propertyPrice * 100 : -1;
}

export const TotalFundsAvailable = (availableCash: number, secondPillar: number, thirdPillar: number): number => {
    return availableCash + secondPillar + thirdPillar;
}

const DepositNeededEva = (propertyPrice: number, MIN_DEPOSIT_RATE: number): number => {
    return propertyPrice * MIN_DEPOSIT_RATE;
}

const DepositNeededMortgage = (propertyPrice: number, DEPOSIT_RATE: number): number => {
    return propertyPrice * DEPOSIT_RATE;
}

const MonthlyMortgageInterest = (mortgageAmount: number, MORTGAGE_INTEREST_RATE: number): number => {
    const annualMortgageInterest = mortgageAmount * MORTGAGE_INTEREST_RATE;
    const monthlyMortgageInterest = annualMortgageInterest / 12;
    return monthlyMortgageInterest;

}

const MonthlyMortgageAmortization = (propertyPrice: number, MORTGAGE_AMORTIZATION_YEARS: number): number => {
    const annualMortgageAmortization = propertyPrice * 0.14 /  MORTGAGE_AMORTIZATION_YEARS;
    const monthlyMortgageAmortization = annualMortgageAmortization / 12;
    return monthlyMortgageAmortization;

}

const MonthlyEvaFee = (
        propertyPrice: number , EVA_FEE_RATE: number, EVA_CONTRACT_DURATION_YEARS: number    
    ): number => {
    const annualEvaServiceFee = propertyPrice * EVA_FEE_RATE / EVA_CONTRACT_DURATION_YEARS;

    const monthlyEvaServiceFee = annualEvaServiceFee / 12;

    return monthlyEvaServiceFee;
}

const TotalMonthlyExpenses = (currentRent: number, monthlySavings: number): number => {
    return currentRent + monthlySavings;
}

const BuildingFundsPeriod = (depositNeeded: number, totalFundsAvailable: number, monthlySavings: number): number => {
    return !!monthlySavings ? (depositNeeded - totalFundsAvailable) / monthlySavings / 12 : -1;
}

export const calculateFields = (
    item: any
) => {
    //constants
    const DEPOSIT_RATE = Number(item?.rates.depositRate);
    const MIN_DEPOSIT_RATE = Number(item?.rates.minDepositRate);
    const INVESTOR_ROI = Number(item?.rates.investorRoi);
    const MORTGAGE_INTEREST_RATE = Number(item?.rates.mortgageInterestRate);
    const MORTGAGE_AMORTIZATION_YEARS = Number(item?.rates.mortgageAmortizationYears);
    const EVA_CONTRACT_DURATION_YEARS = Number(item?.rates.evaContractDurationYears);
    const EVA_FEE_RATE = Number(item?.rates.evaFeeRate);  
    const THEORETICAL_MORTGAGE_INTEREST_RATE = Number(item?.rates.theoreticalMortgageInterestRate);
    const THEORETICAL_MAINTENANCE_FEE_RATE = Number(item?.rates.theoreticalMaintenanceFeeRate);
    const ACQUISITION_FEE = Number(item?.rates.acquisitionFee);
    
    //finances
    const propertyPrice = Number(item?.property?.price || 0);
    const annualIncome = Number(item?.finances?.annualIncome || 0);
    const availableCash = Number(item?.finances?.availableCash || 0);
    const secondPillar = Number(item?.finances?.secondPillar || 0);
    const thirdPillar = Number(item?.finances?.thirdPillar || 0);
    const residualValue = Number(item?.eva?.residualValue || 0);
    const monthlySavings = Number(item?.finances?.monthlySavings || 0);
    const currentRent = Number(item?.finances?.currentRent || 0);
    const otherFees = Number(item?.eva?.otherFees || 0);

    const mortgageAmount = CalcMortgageAmount(propertyPrice, DEPOSIT_RATE);
    const totalFundsAvailable = TotalFundsAvailable(availableCash, secondPillar, thirdPillar);
    const investorLoan = CalcInvestorLoanNeeded(propertyPrice, totalFundsAvailable, DEPOSIT_RATE);
    const monthlyMortgageInterest = MonthlyMortgageInterest(mortgageAmount, MORTGAGE_INTEREST_RATE);
    const monthlyMortgageAmortization = MonthlyMortgageAmortization(propertyPrice, MORTGAGE_AMORTIZATION_YEARS);
    const monthlyEvaFee = MonthlyEvaFee(propertyPrice, EVA_FEE_RATE, EVA_CONTRACT_DURATION_YEARS);
    const depositNeededMortgage = DepositNeededMortgage(propertyPrice, DEPOSIT_RATE);
    const monthlyInvestorPayment = round(CalcMonthlyInvestorPayment(investorLoan, INVESTOR_ROI, EVA_CONTRACT_DURATION_YEARS));

    const totalFees = otherFees + monthlyEvaFee;

    //morgage
    const availableCashMortgage = Number(item?.mortgage?.availableCash || 0);
    const secondPillarMortgage = Number(item?.mortgage?.secondPillar || 0);
    const thirdPillarMortgage = Number(item?.mortgage?.thirdPillar || 0);

    const acquisitionFeePaidBy = String(item?.mortgage?.acquisitionFeeCoveredBy?.code || null);

    const totalFundsAvailableMortgage = TotalFundsAvailable(availableCashMortgage, secondPillarMortgage, thirdPillarMortgage);

    const investorLoanMorgage = CalcInvestorLoanNeeded(propertyPrice, totalFundsAvailableMortgage, DEPOSIT_RATE, acquisitionFeePaidBy, ACQUISITION_FEE);

    const depositNeededEva = DepositNeededEva(propertyPrice, MIN_DEPOSIT_RATE);

    const investorLoanAmortization = CalcMonthlyInvestorPayment(investorLoanMorgage, INVESTOR_ROI, EVA_CONTRACT_DURATION_YEARS);
    const investorAmortization = investorLoanMorgage / EVA_CONTRACT_DURATION_YEARS / 12;

    const monthlyTotal = investorLoanAmortization + monthlyMortgageInterest + monthlyMortgageAmortization;

    if ((acquisitionFeePaidBy === 'homebuyer') && (availableCashMortgage + calcPropertyAcquisitionFee(propertyPrice, ACQUISITION_FEE) > availableCash) ) {
        notification.close('acquisitionFee');
        notification.warning({
            key: 'acquisitionFee',
            message: 'Warning',
            description: 'Cash for acquisition fee is not enough!',
        });
    }

    if(totalFundsAvailableMortgage < depositNeededEva){
        notification.close('deposit');
        notification.warning({
            key: 'deposit',
            message: 'Warning',
            description: 'Deposit is less than min EVA requirement!'
        });
    }
    
    return {
        application: {
            effortRate: round(EffortRate(propertyPrice, mortgageAmount, investorLoanMorgage, annualIncome,
                THEORETICAL_MORTGAGE_INTEREST_RATE, MORTGAGE_AMORTIZATION_YEARS, EVA_FEE_RATE, 
                THEORETICAL_MAINTENANCE_FEE_RATE, INVESTOR_ROI, EVA_CONTRACT_DURATION_YEARS
            )),
            propertyToRevenueRatio: round(PropertyToRevenueRatio(propertyPrice, annualIncome)),
            depositRate: round(DepositRate(totalFundsAvailableMortgage, propertyPrice)),
        },
        finances: {
            totalFundsAvailable: round(totalFundsAvailable),
            totalMonthlyExpenses: round(TotalMonthlyExpenses(currentRent, monthlySavings)),
        },
        eva: {
            depositNeededEva: round(depositNeededEva),
            depositNeededMortgage: round(depositNeededMortgage),
            buildingFundsPeriod: round(BuildingFundsPeriod(depositNeededMortgage, totalFundsAvailable, monthlySavings)),
            monthlyEvaFee: round(monthlyEvaFee),
            monthlyTotal: round(monthlyTotal),
            monthlyTotalAdjust: round(calcTotalMonthlyChargesWithResidualValue(
                propertyPrice, DEPOSIT_RATE, MORTGAGE_INTEREST_RATE, MORTGAGE_AMORTIZATION_YEARS, 
                investorLoanAmortization, EVA_FEE_RATE, otherFees, residualValue, EVA_CONTRACT_DURATION_YEARS
            )),
            monthlyMortgage: round(monthlyMortgageInterest + monthlyMortgageAmortization),
            investorLoan: round(investorLoan),
            monthlyMortgageInterest: round(monthlyMortgageInterest),
            monthlyMortgageAmortization: round(monthlyMortgageAmortization),
            bankPayment: round(monthlyMortgageInterest + monthlyMortgageAmortization),
            monthlyInvestorPayment: monthlyInvestorPayment,
            totalFees: round(totalFees),
            totalWithFee: round(totalFees + monthlyTotal)
        },
        mortgage: {
            acquisitionFee: round(propertyPrice*ACQUISITION_FEE),
            investorLoanAmortization: round(investorLoanAmortization),
            monthlyInvestorAmortization: round(investorAmortization),
            monthlyInvestorInterest: round(investorLoanAmortization - investorAmortization),
            totalFundsAvailable: round(totalFundsAvailableMortgage),
            investorLoanNeeded: round(investorLoanMorgage),
            investorLoanAcquisitionFees: round("investor" === acquisitionFeePaidBy ? calcPropertyAcquisitionFee(propertyPrice, ACQUISITION_FEE || 0) : 0),
            mortgageRequested: round(propertyPrice*0.8),
            evaFee: round(monthlyEvaFee)
        }
    }
}