import React, { useState, useContext } from 'react';
import { Layout, Badge, Avatar, Dropdown, Menu, Row, Col } from 'antd';
import css from './../../assets/styles/header.module.css';
import { UserOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined, ApiOutlined } from '@ant-design/icons';

import { SET_NON_AUTHENTICATED } from '../../constants/actionTypes/appConstants';
import { TOGGLE_MENU } from '../../constants/actionTypes/navConstants';
import {AppContext} from '../../contexts/AppContextProvider';
import {NavContext} from '../../contexts/NavContextProvider';
import { getUserLogin, useHasCurrentRule } from '../../services/hooks';
import { useTranslation } from 'react-i18next';
const { Header } = Layout;

const HeaderComponent: React.FC = (props: any) => {
  const { app, appDispatch } = useContext(AppContext);
  const { nav, navDispatch } = useContext(NavContext);
  const isAdmin = useHasCurrentRule('admin');
  const userName = getUserLogin();
  const { t } = useTranslation();

  const logOut = () => {
    appDispatch({ type: SET_NON_AUTHENTICATED });
    props.history.push('/login');
  };

  const menu = () => (
    <Menu>
      <Menu.Item key="profile">
        <UserOutlined style={{ fontSize: '14px' }} />
        {isAdmin ? 'Profile' : t('header.profile')}
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={logOut}>
        <LogoutOutlined style={{ fontSize: '14px' }}/>
        {isAdmin ? 'Logout' : t('header.logout')}
      </Menu.Item>
    </Menu>
  );

  const toggleMenu = () => {
    navDispatch({
      type: TOGGLE_MENU,
      isMenuCollapsed: !nav.isMenuCollapsed,
    });
  };

  return (
    <Header
      style={{ background: '#fff', padding: 0 }}
      className="header-sh-box"
    >
      <div className="container-header">
        <span
          className={css['header-icon']}
          onClick={toggleMenu}
        >
          {nav.isMenuCollapsed ? <MenuUnfoldOutlined className="trigger"/> : <MenuFoldOutlined className="trigger"/>}
        </span>
        <span className="profile">
          <span className={css['header-profile']}/>
          <Dropdown overlay={menu}>
            <span className={css['header-profile-dropdown']}>
              <Avatar icon={<UserOutlined/>}/>
              <span className={css['header-profile-username']}>{userName}</span>
            </span>
          </Dropdown>
        </span>
      </div>
    </Header>
  );
};

export default HeaderComponent;
