import React, { useState, useEffect } from 'react';
import {Row, Col, Form, Modal, Button, Input} from 'antd';
import { EyeOutlined, EditOutlined } from '@ant-design/icons';
import AddressAutocomplete from '../AddressAutocomplete/AddressAutocomplete';
import { redirectToGoogleMaps } from '../../helpers/OpenUrlInNewTab';

interface IModalEditArticleProps {
  item: any | null,
  path: any[];
  handleOk: (data: any) => void,
  handleCancel: () => void
};

const AddressModal: React.FC<IModalEditArticleProps> = ({
  item,
  path,
  handleOk,
  handleCancel
}) => {
  const [form] = Form.useForm();
  const [values, setValues] = useState({} as any);

  useEffect(() => {
    const toUpdate = path ? eval(`item?.${path.join('?.')}`) : {};
    setValues(toUpdate);
    form.setFieldsValue(toUpdate);
  }, [path]);

  const onFinish = () => {
    form.validateFields().then(data => {
      const toUpdate = {...item};
      eval(`toUpdate.${path.join('.')} = { ...toUpdate.${path.join('.')}, ...data};`);
      handleOk(toUpdate);
    });
  };

  return (
    <Modal 
      visible={!!path} 
      onOk={onFinish} 
      onCancel={handleCancel}
      title={'Edit address'}>
      <Form 
        form={form}
        layout={'vertical'} 
        onValuesChange={(field, newItem) => {
          if(newItem.country || newItem.region || newItem.city || newItem.street || newItem.buildingNumber || newItem.zip){
            setValues({...values, addressFull: [newItem.country, newItem.region, newItem.city, newItem.street, newItem.buildingNumber, newItem.zip].filter(Boolean).join(', ')});
          }
        }}
        initialValues={values}>
        <Row gutter={20}>
          <Col span={24}>
              <Form.Item name={'addressFull'}>
                <AddressAutocomplete defaultValue={values?.addressFull} onChange={(data) => {
                  form.setFieldsValue(data);
                }}/>
              </Form.Item>
          </Col>
          <Col span={12}>
              <Form.Item name={'country'}>
                <Input placeholder={'Country'}/>
              </Form.Item>
          </Col>
          <Col span={12}>
              <Form.Item name={'region'}>
                <Input placeholder={'Region'}/>
              </Form.Item>
          </Col>
          <Col span={12}>
              <Form.Item name={'zip'}>
                <Input placeholder={'Zip'}/>
              </Form.Item>
          </Col>
          <Col span={12}>
              <Form.Item name={'city'}>
                <Input placeholder={'City'}/>
              </Form.Item>
          </Col>
          <Col span={18}>
              <Form.Item name={'street'}>
                <Input placeholder={'Street'}/>
              </Form.Item>
          </Col>
          <Col span={6}>
              <Form.Item name={'buildingNumber'}>
                <Input placeholder={'Nr'}/>
              </Form.Item>
          </Col>
          <Col span={6}/>
          <Col span={6}>
              <Form.Item name={'lat'}>
                <Input placeholder={'Latitude'}/>
              </Form.Item>
          </Col>
          <Col span={6}>
              <Form.Item name={'lng'}>
                <Input placeholder={'Longitude'}/>
              </Form.Item>
          </Col>
          <Col span={6}>
              <Button onClick={() => {
                if(form.getFieldValue('lat') && form.getFieldValue('lng')){
                  redirectToGoogleMaps(form.getFieldValue('lat'), form.getFieldValue('lng'))
                }
              }}><EyeOutlined/></Button>
          </Col>
        </Row>
      </Form>  
    </Modal>
  );
};

export default AddressModal;
