import { LIST_TECH_TYPE, REFRESH_TECH_TYPE } from '../constants/actionTypes/listConstants';
import { TechTypes } from './appReducer';

export const initialState = {
  techType: null as TechTypes | null,
};

export function reducer(_state: typeof initialState, action: any) {
  switch (action.type) {
    case LIST_TECH_TYPE:
      return { techType: action.techType as TechTypes };
    case REFRESH_TECH_TYPE:
        return { techType: null };
    default:
      return initialState;
  }
}
