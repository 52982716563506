import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { Languages } from '../../constants/languages';

import translationEN from './locales/en-US/translation.json';
import translationFR from './locales/fr-FR/translation.json';

// will be used in the future
// const test = () => {
//   const arr: any[] = [];

//   Object.keys(translationFR).forEach((key) => {
//     if ((translationEN as any)[key] === undefined) {
//       arr.push(key);
//     }
//   });
// };
// test();

const CUSTOMER_LANG_KEY = 'customerLang';

// the translations
const resources = {
  [Languages.EN]: { translation: translationEN },
  [Languages.FR]: { translation: translationFR },
};

const getLanguage = (language: string) => {
    if (!!language && language === Languages.FR) {
        i18n.changeLanguage(Languages.FR);
        sessionStorage.setItem(CUSTOMER_LANG_KEY, Languages.FR);
    } else {
        i18n.changeLanguage(Languages.EN);
        sessionStorage.setItem(CUSTOMER_LANG_KEY, Languages.EN);
    }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    supportedLngs: [Languages.EN, Languages.FR],
    fallbackLng: Languages.EN,
    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export {getLanguage, CUSTOMER_LANG_KEY};
export default i18n;
