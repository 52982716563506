import React, { useState, useEffect } from 'react';
import DictionariesServices from '../../../services/DictionariesServices';
import { Select, Form, notification } from 'antd';
import { selectFilterOption } from '../../../helpers/selectFilterOption';
import { getNestedValueByPath } from '../../../helpers/getNestedValueByPath';

const { Option } = Select;

interface DictionarySelectProps {
  dictionaryName: string | null;
  keyField: string;
  showField: string | string[];
  showFieldSeparator?: string;
  onChange?: (value: any) => void;
  value?: any;
  multipleMode?: boolean;
  name: any;
  label: any;
  initialValue?: any;
}

const service = new DictionariesServices();

const DictionaryFieldSelect = ({
  dictionaryName,
  keyField,
  showField,
  showFieldSeparator,
  onChange,
  multipleMode,
  name,
  label,
  initialValue,
}: DictionarySelectProps) => {
  const [options, setOptions] = useState([] as any[]);

  useEffect(() => {
    if (dictionaryName) {
      service
        .getDictionary(dictionaryName)
        .then((options) => {
          setOptions(options);
        })
        .catch((e) => {
            notification.error(e.message);
        });
    }
  }, [dictionaryName]);

  const backupOnChange = () => null;

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={[{ required: true }]}
      wrapperCol={{ span: 24 }}>
      <Select
        mode={multipleMode ? 'multiple' : undefined}
        onChange={onChange || backupOnChange}
        className="faq-modify-select"
        filterOption={selectFilterOption}>
        {options.map((option) => {
          if (Array.isArray(showField)) {
            const label = showField
              .map((field) => getNestedValueByPath(option, field, '.'))
              .join(showFieldSeparator || ', ');
            return (
              <Option key={option[keyField]} value={option[keyField]}>
                {label}
              </Option>
            );
          }
          return (
            <Option key={option[keyField]} value={option[keyField]}>
              {option[showField]}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

export default DictionaryFieldSelect;
