import React from 'react';
import { Popconfirm, Button, Divider } from 'antd';
import {NavLink} from "react-router-dom";
import {getStatusColor} from '../FaqEditPage';
import { ColumnProps } from 'antd/lib/table';
import { FaqModel } from '../../../models/FaqModel';
import DragHandler from '../../../components/DragableTableRow/DragHandler';

const defaultLanguage = 'fr';

export const columns = (deleteItems: any): ColumnProps<FaqModel>[] => ([
  {
    title: 'Sort',
    dataIndex: 'sort',
    className: 'drag-visible',
    render: (_value, _record, index) => <DragHandler index={index}/>,
  },
  {
    title: 'Order',
    dataIndex: ['ord'],
  },
  {
    title: 'Number',
    dataIndex: ['id'],
  },
  {
    title: 'Question',
    render: (cell: any, row: any) => row.translation.find((el: any) => el.language.code == defaultLanguage).question,
  },
  {
    title: 'Answer',
    render: (cell: any, row: any) => row.translation.find((el: any) => el.language.code == defaultLanguage).answer,
  },
  {
    title: 'Statuses',
    width: 235,
    dataIndex: 'isPublished',
    render: (cell: any, row: any) => row.translation.sort((a: any, b: any) => a.id - b.id).map((item: any) => <>
      {item.language.code.toUpperCase()}: <b style={{color: getStatusColor(item?.isPublished)}}>{item?.isPublished ? "Published" : "Draft"}</b><br/>
    </>)
  },
  {
    title: 'Action',
    key: 'action',
    width: 250,
    render: (cell: any, row: any) => {
      return (
        <span>  
          <NavLink to={`/faqItem/${row.id}`} style={{marginRight: '12px'}}>Edit</NavLink>    
          <Divider type="vertical"/>       
          <Popconfirm placement="left" title="Are you sure you want to delete this item?" okText="Yes" cancelText="No" onConfirm={() => deleteItems([row.id])}>
            <Button type="link" style={{color: '#f65a74'}}>Delete</Button>
          </Popconfirm>
        </span> 
      )
    },
  }
]);
