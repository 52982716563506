const UPDATE_USER = "UPDATE_USER";
const DELETE_USER = "DELETE_USER";

const SET_AUTHENTICATED = "SET_AUTHENTICATED";
const SET_NON_AUTHENTICATED = "SET_NON_AUTHENTICATED";

const SET_TECH_TYPES = "SET_TECH_TYPES";

export {
    UPDATE_USER,
    DELETE_USER,
    SET_AUTHENTICATED,
    SET_NON_AUTHENTICATED,
    SET_TECH_TYPES
};
