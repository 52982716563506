export const applicationRates = {
    depositRate: 0.2, 
    minDepositRate: 0.1, 
    acquisitionFee: 0.046, 
    mortgageInterestRate: 0.02, 
    mortgageAmortizationYears: 15, 
    maintenanceFeeRate: 0.01, 
    evaContractDurationYears: 15, 
    evaFeeRate: 0.02, 
    investorRoi: 0.05, 
    theoreticalMortgageInterestRate: 0.05, 
    theoreticalMaintenanceFeeRate: 0.01, 
    theoreticalEvaFeeRate: 0.02, 
    theoreticalInvestorRoi: 0.05
}