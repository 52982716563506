import React, {useContext} from 'react';
import {Form, Button, Input, Card, Row, Col, notification} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/images/logo.svg';
import loginPic from '../../assets/images/blog-big.svg';
import {AppContext} from '../../contexts/AppContextProvider';
import {SET_AUTHENTICATED} from '../../constants/actionTypes/appConstants';
import {failedTofetch, tokenExpired} from '../../constants/tokenFailConstants';

import LoginPageServices from '../../services/LoginPageServices';
import { Link } from 'react-router-dom';

const checkIsFailed = (props: any) => {
  const search = props.location.search;
  const searchStr = search ? search.substr(1, search.length) : undefined;
  if (searchStr) {
    const type = searchStr.split('=')[1];
    switch(type) {
      case failedTofetch: {
        props.history.push('/login');
        notification.warning({
          message: 'Warning',
          description: 'Internal server error!'
        });
        break;
      }
      case tokenExpired: {
        props.history.push('/login');
        notification.warning({
          message: 'Warning',
          description: 'Token expired!'
        });
        break;
      }
    }
  }
}

const Logo = () => (
  <img src={logo} alt="Logo" className="logo-login" />
);

const LoginPage: React.FC = (props: any) => {
  const { app, appDispatch } = useContext(AppContext);
  const loginPageServices = new LoginPageServices();
  localStorage.clear();
  
  // check type of token errors and show notification
  checkIsFailed(props);

  const setGlobalCredentials = (user: any, data: any) => {
    appDispatch({ type: SET_AUTHENTICATED, user: user });
    localStorage.setItem('roles', JSON.stringify(user.roles));
    localStorage.setItem('user', JSON.stringify(user));
    delete data.user;
    localStorage.setItem('id_token', JSON.stringify(data));
    props.history.push('/applications');
  }

  const handleSubmit = (values: any) => {
        loginPageServices.getToken(values).then(
          (res: any) => {
            const user = res.user;
            const data = res;
            setGlobalCredentials(user, data);
          },
          err => {
            // TODO: create global cheking
            notification.error({
              message: 'Error',
              description: err.message,
            });
          },
        );
  };

  return (
    <Row
      justify="center"
      align="middle"
      className="login-page"
    >
        <div className="login-page_in">
            <h1>Welcome to the Eva</h1>

            <div className="section-login_row">
                <figure className="section-login_pic"><img src={loginPic} alt="" /></figure>
                <Col className="section-login_form">
                    <Card className="section-login_card" title={<Logo />}>
                      <Form onFinish={handleSubmit} className="login-form">
                        <Form.Item name="username" rules={[{required: true, message: 'Please input your username!'}]}>
                            <Input
                              prefix={
                                <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                              }
                              placeholder="Username"
                            />
                        </Form.Item>
                        <Form.Item name="password" rules={[{ required: true, message: 'Please input your Password!' }]}>
                            <Input
                              prefix={
                                <LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                              }
                              type="password"
                              placeholder="Password"
                            />
                        </Form.Item>
                        <div style={{ height: '100%', display: 'flex', justifyContent: 'space-between' }}>
                          <Link to="/forgot-pass">
                            <Button
                              type="link"
                              style={{ paddingLeft: 0 }}
                            >
                              Forgot password
                            </Button>
                          </Link>
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="login-form-button"
                          >
                            Log in
                          </Button>
                        </div>
                      </Form>
                    </Card>
                </Col>
            </div>
        </div>
    </Row>
  );
};

export default LoginPage;
