import React, {createContext, useReducer} from 'react';
import {initialState, reducer} from '../reducers/pageReducer';

export const PageContext = createContext(({} as any));

const PageContextProvider: React.FC = (props: any) => {
    const [page, pageDispatch] = useReducer(reducer, initialState);

    return (
        <PageContext.Provider value={[page, pageDispatch]}>
            {props.children}
        </PageContext.Provider>
    );
};

export default PageContextProvider;