import React, {ReactElement} from 'react';
import {Input} from 'antd';

interface Props {
    prefix?: ReactElement;
    suffix?: ReactElement;
    length?: number;
    startWithO?: boolean;
}

export const InputInteger: React.FC<Props> = (props: any) => {

    const onChange = (e: any) => {
        const { value } = e.target;
        const regWithoutO = `^(0|[1-9][0-9]{0,${(props.length || 10) - 1}})?$`;
        const regWithO = `^([0-9]{0,${(props.length || 10) - 1}})?$`;
        const reg = new RegExp(props.startWithO ? regWithO : regWithoutO);
        if ((!isNaN(value) && reg.test(value)) || value === '' || value === '-') {
            props.onChange(value);
        }
    };

    const onBlur = () => {
        const { value, onBlur, onChange } = props;
        if (value.toString().charAt(value.length - 1) === '.' || value === '-') {
            onChange(value.toString().slice(0, -1));
        }
        if (onBlur) {
            onBlur();
        }
    };

    return (
        <Input
            {...props}
            onChange={onChange}
            onBlur={onBlur}
            prefix={props.prefix || null}
            suffix={props.suffix || null}/>
    );
};