import HttpService from './common/httpService';
import buildQuery from 'odata-query';
import { ApplicationModel } from '../models/ApplicationModel';
const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class CustomerService {
    private httpService: HttpService;
  
    constructor() {
      this.httpService = new HttpService();
    }
  
    public getCustomerApplications(email: string): Promise<ApplicationModel[]> {
        let orderBy: any = null;
        let filter: any = {and: []};
        let top: any = null;
        let skip: any = null;
        let count: boolean = false;
    
        filter.and.push(
          'deletionDate eq null'
        );
    
        filter.and.push(
            {[`tolower(homebuyer/email)`]: {contains: encodeURI(email)}}
        );

        filter.and.push({
            'homebuyer/isRegistered': true
        })
    
        const query = buildQuery({filter, orderBy, top, skip, count});
        return this.httpService.get(`${BASE_URL}homebuyer/application${query}`);
      }
    
  }
  
  export default CustomerService;