import React from 'react';
import {Card, Cascader, Col, Form, Input, Row, Select, Spin } from 'antd';
import { GroupModel } from '../../../models/GroupModel';


export interface BuildingCardModel {
    isLoading: boolean,
    groups: GroupModel[],
    isGroupsTreeLoading: boolean,
}

const BuildingCard = ({
    isLoading,
    groups,
    isGroupsTreeLoading,
}: BuildingCardModel) => {
    return <Card style={{marginBottom: '24px'}} loading={isLoading}>
    <Row gutter={12}>
      <Col span={12}>
        <Form.Item label="Code" name="code" 
          rules={[{required: true, message: 'Please input property code'}]}>
          <Input/>
        </Form.Item>
      </Col>
        <Col span={12}>
          <Spin spinning={isGroupsTreeLoading}>
            <Form.Item label="Group / Building" name={["parent"]}>
              <Cascader
                options={groups}
                changeOnSelect
                fieldNames={{ label: 'code', value: 'id', children: 'childrens' }}
              />
            </Form.Item>
          </Spin>
        </Col>
    </Row>
  </Card>
}

export default BuildingCard;