import React, {useEffect, useState} from 'react';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {notification, Table, Button} from 'antd';
import {columns} from './components/BlogColumns';
import BlogService from '../../services/BlogService';
import DictionariesServices from '../../services/DictionariesServices';

import {Link} from 'react-router-dom';

const breadcrumbNameMap: any = {
  '/blog': 'Blog',
};

const BlogPage = (props: any) => {
  const service = new BlogService();

  const [data, setData] = useState([]) as any[];
  
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);


  const getData = () => {
    setIsLoading(true);
    service.getBlog().then(data => {
      setData(data);
      setIsLoading(false);
    }).catch((e) => {
      notification.error({
        message: 'Error while loading blog items.'
      });
      setIsLoading(false);
    });
  };


  return (
    <>
      <HeaderTable title='Blog list' breadcrumb={breadcrumbNameMap}>
        <Link to="/blogItem"><Button type="primary" style={{width: 100}}>+ New</Button></Link>
      </HeaderTable>
      <Table columns={columns}
            loading={isLoading}
            dataSource={data}
            showHeader={true}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['10', '20', '30']}}
            rowKey={record => record.key}/>
    </>
  );
};

export default BlogPage;
