import React from "react";
import { useDrop } from "react-dnd";
import { dragType } from "./DragHandler";

interface DragableRowProps {
  index: number;
  moveRow: (itemIndex: number, index: number) => void;
  className?: string;
  style?: React.CSSProperties;
  [prop: string]: any;
}

const DragableTableRow: React.FC<DragableRowProps> = ({ index, moveRow, className, style, ...restProps }) => {
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: dragType,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() as any || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: any) => {
      moveRow(item.index, index);
    },
  });

  return (
    <tr
      ref={drop}
      className={`${className}${isOver ? dropClassName : ''}`}
      {...restProps}
    />
  );
};

export default DragableTableRow;