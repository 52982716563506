import {
  UPDATE_USER,
  DELETE_USER,
  SET_AUTHENTICATED,
  SET_NON_AUTHENTICATED,
  SET_TECH_TYPES,
} from '../constants/actionTypes/appConstants';

export enum TechTypes {
  GROUP = 'group',
  BUILDING = 'building',
  APARTMENT = 'apartment'
}

export interface AppState {
  user: any;
  isMenuCollapsed: boolean;
  isAuthenticated: boolean;
  roles: any;
  techTypes?: TechTypes;
}

export const initialState: AppState = {
  user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') as string): JSON.parse || null,
  isMenuCollapsed: false,
  isAuthenticated: localStorage.getItem('id_token') ? true : false,
  roles: localStorage.getItem('roles') || null,
  techTypes: undefined,
};

export function reducer(state: AppState, action: any) {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, userName: action.userName };
    case DELETE_USER:
      return { ...state, userName: null };
    case SET_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        user: action.user,
        roles: action.user.roles,
      };
    case SET_NON_AUTHENTICATED:
      return { ...state, isAuthenticated: false, user: null };
    case SET_TECH_TYPES:
      return { ...state, techTypes: action.techTypes }
    default:
      return initialState;
  }
}
