const itemsToReplace = [''];

export const objectToValidFormat = (object: any) => {
    Object.keys(object || {}).forEach(key => {
        if(object[key] instanceof Object){
            if(!(object[key] instanceof Date)){
                object[key] = Object.keys(object[key]).length ? 
                    Object.keys(object[key]).length === 1 && !object[key][Object.keys(object[key])[0]] ? 
                        null
                    : 
                        objectToValidFormat(object[key])
                : null
            }
        } else {
            object[key] = itemsToReplace.includes(object[key]) ? null : object[key];
        }
    });
    return object;
}