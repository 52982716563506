export enum SubscribersStatuses {
    new = 'new',
    addressed = 'addressed',
    firstDrip = 'firstDrip',
    firstEvaDrip = 'firstEvaDrip',
}

export const emailInitiatingStatuses = [
    SubscribersStatuses.firstEvaDrip,
    SubscribersStatuses.firstDrip,
];

export const subscriberStatusColors = {
    [SubscribersStatuses.new]: {
        color: '#fff',
        textColor: '#000',
    },
    [SubscribersStatuses.addressed]: {
        color: '#d0e0e3',
        textColor: '#000',
    },
    [SubscribersStatuses.firstDrip]: {
        color: '#a4c2f4',
        textColor: '#000',
    },
    [SubscribersStatuses.firstEvaDrip]: {
        color: '#a4c2f4',
        textColor: '#000',
    },
}