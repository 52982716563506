import HttpService from './common/httpService';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class FaqService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getFaqList() {
    return this.httpService.get(`${BASE_URL}faq?$orderby=${encodeURIComponent('ord asc')}`);
  }

  public getFaqItemById(id: any) {
    return this.httpService.get(`${BASE_URL}faq/${id}`)
  }

  public saveFaqItem(values: any) {
    return this.httpService.post(`${BASE_URL}faq`, values);
  }

  public deleteFaqItems(id: number[]) {
    return this.httpService.delete(`${BASE_URL}faq`, {id});
  }

  public changeOrder(newOrder: Array<{ id: number, ord: number}>) {
    return this.httpService.post(`${BASE_URL}faq/order`, newOrder);
  }
}

export default FaqService;
