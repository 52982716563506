import HttpService from './common/httpService';
import buildQuery from 'odata-query';
import { PropertyShort } from '../models/PropertyModel';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

const toDataURL = (url: any) => fetch(url, {
  headers: {}
}).then(response => response.blob());

class ListPageServices {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  

  public getGroupProperties() {
    return this.httpService.get(`${BASE_URL}properties/groups`);
  }

  public getNested(id: any) {
    return this.httpService.get(`${BASE_URL}properties/children/${id}`);
  }

  public getPropertyById(id: any) {
    return this.httpService.get(`${BASE_URL}properties/${id}`)
  }

  public saveProperty(values: any, id?: number) {
    return this.httpService[id ? 'put' : 'post'](`${BASE_URL}properties${id ? `/${id}`:``}`, values);
  }

  public async saveImages(propertyId: number, fileList: any[], type: string) {
    const formData = new FormData();

    const results = await Promise.all(fileList.map((img: any) => toDataURL(img.thumbUrl)));
    
    fileList.forEach(async (data: any, index) => {
      data.originFileObj ? formData.append('file', data.originFileObj) : formData.append('file', results[index], data.name)
    });

    return this.httpService.postFile(`${BASE_URL}properties/images/${type}/${propertyId}`, formData);
  }

  public getGroups() {
    return this.httpService.get(`${BASE_URL}properties/groups`);
  }

  public getPropertyChildren(id: number) {
    return this.httpService.get(`${BASE_URL}properties/children/${id}`);
  }

  public getPropertyShort(): Promise<PropertyShort[]> {
    return this.httpService.get(`${BASE_URL}properties/short?$orderby=${encodeURIComponent('code asc')}`);
  }

  public getPropertyWithFilter(sortObject: any, searchObjects: any, pagingObject: any, expandParam: any) {
    let orderBy: any = null;
    let filter: any = {or: [], and: []};
    let top: any = null;
    let skip: any = null;
    let expand: any = expandParam;

    //Search part
    if (searchObjects) {
      const concatProperties = Object.keys(searchObjects);
      concatProperties.map(concatProperty => {
        const keys = Object.keys(searchObjects[concatProperty]);
        keys.map(key => {
          const value = searchObjects[concatProperty][key] ? encodeURI(searchObjects[concatProperty][key].replace(/[/\\!@#$%^&*)(+=.,_-]/g, '').replace(/`/g, `'`).toLowerCase()) : null;
          filter.and.push({[key]: {eq: value}});
        });
      });
    }

    //Sort part
    if (sortObject) {
      const keys = Object.keys(sortObject);
      orderBy = [];
      keys.map(key => {
        const sortOrder = sortObject[key];
        orderBy.push(`${key} ${sortOrder}`);
      });
    }

    // Paging part
    if (pagingObject) {
      top = pagingObject.sizePerPage;
      skip = (pagingObject.page - 1) * pagingObject.sizePerPage;
    }

    const query = buildQuery({filter, orderBy, top, skip, expand});
    return this.httpService.get(`${BASE_URL}properties${query}`);
  }

  public getCSVPropertyWithFilter(searchObjects: any) {
    let filter: any = {or: [], and: []};

    //Search part
    if (searchObjects) {
      const concatProperties = Object.keys(searchObjects);
      concatProperties.map(concatProperty => {
        const keys = Object.keys(searchObjects[concatProperty]);
        keys.map(key => {
          const value = searchObjects[concatProperty][key] ? encodeURI(searchObjects[concatProperty][key].replace(/[/\\!@#$%^&*)(+=.,_-]/g, '').replace(/`/g, `'`).toLowerCase()) : null;
          filter.and.push({[key]: {eq: value}});
        });
      });
    }

    const query = buildQuery({filter});
    return this.httpService.get(`${BASE_URL}properties/csv${query}`);
  }

  public publishProperty(id: number, body: any) {
    return this.httpService.put(`${BASE_URL}properties/publish/${id}`, body);
  }

  public unpublishProperty(id: number) {
    return this.httpService.put(`${BASE_URL}properties/unpublish/${id}`, {});
  }

  public deleteProperty(id: string | number) {
    return this.httpService.delete(`${BASE_URL}properties/${id}`, null);
  }
}

export default ListPageServices;
