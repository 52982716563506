import { Card, Col, Row } from "antd";
import React from "react";
import { ApplicationsStats } from "../../../models/ApplicationStatsModel";
import style from '../../../assets/styles/statsbar.module.css';
import { ApplicationStatuses, StatusColors } from "../../../constants/applicationStatuses";

export const ApplicationsStatsBar: React.VFC<{ stats: ApplicationsStats | null }> = ({ stats }) => {
  return (
    <Row gutter={16} className={style.container}>
      <Col>
        <Card className={style.card} style={{ backgroundColor: StatusColors[ApplicationStatuses.client].color }}>
          <h4>Customers</h4>
          <span>{stats?.customer}</span>
        </Card>
      </Col>
      <Col>
        <Card className={style.card} style={{ backgroundColor: StatusColors[ApplicationStatuses.financingPGL].color }}>
          <h4>Financing</h4>
          <span>{stats?.financing}</span>
        </Card>
      </Col>
      <Col>
        <Card className={style.card} style={{ backgroundColor: StatusColors[ApplicationStatuses.certificateIssued].color }}>
          <h4>Certificate</h4>
          <span>{stats?.certificate}</span>
        </Card>
      </Col>
      <Col>
        <Card className={style.card} style={{ backgroundColor: StatusColors[ApplicationStatuses.firstDrip].color }}>
          <h4>Dripped</h4>
          <span>{stats?.dripped}</span>
        </Card>
      </Col>
      <Col>
        <Card className={style.card} style={{ backgroundColor: '#66a995' }}>
          <h4>Other</h4>
          <span>{stats?.other}</span>
        </Card>
      </Col>
      <Col>
        <Card className={style.card} style={{ backgroundColor: '#929292' }}>
          <h4>Abandon</h4>
          <span>{stats?.abandon}</span>
        </Card>
      </Col>
      <Col>
        <Card className={style.card} style={{ backgroundColor: '#fc6554' }}>
          <h4>Rejected</h4>
          <span>{stats?.rejected}</span>
        </Card>
      </Col>
    </Row>
  );
}