import React, {createContext, useReducer, useEffect, useContext} from 'react';
import {initialState, reducer} from '../reducers/navReducer';
import DictionariesServices from '../services/DictionariesServices';
import {SET_TECH_TYPES} from '../constants/actionTypes/appConstants';
import {AppContext} from '../contexts/AppContextProvider';

const dictionariesServices = new DictionariesServices();

export const NavContext = createContext(({} as any));

const NavContextProvider: React.FC = (props: any) => {
    const [nav, navDispatch] = useReducer(reducer, initialState);
    const {app, appDispatch} = useContext(AppContext);

    useEffect(() => {
        if (app.isAuthenticated) {
            dictionariesServices.getTechTypes().then((res: any) => {
                appDispatch({
                    type: SET_TECH_TYPES,
                    techTypes: res
                });
            });
        }
    }, []);

    return (
        <NavContext.Provider value={{ nav, navDispatch }}>
            {props.children}
        </NavContext.Provider>
    );
};

export default NavContextProvider;