import React from 'react';
import { TechTypes } from '../../../reducers/appReducer';
import AppartmentCard, { AppartmentCardModel } from './AppartmentCard';
import BuildingCard, { BuildingCardModel } from './BuildingCard';
import GroupCard from './GroupCard';

interface PropertyCardModel extends BuildingCardModel, AppartmentCardModel {
    techType: TechTypes;
}

const PropertyCard: React.VFC<PropertyCardModel> = ({
    isLoading,
    statuses,
    types,
    brokers,
    groups,
    orientations,
    features,
    wobblers,
    isShowAddSelect,
    handleShowSelect,
    propertyFields,
    item,
    techType,
    isGroupsTreeLoading,
    setAddressModalData,
}) => {
    switch (techType) {
    case TechTypes.BUILDING: 
        return <BuildingCard
            isGroupsTreeLoading={isGroupsTreeLoading}
            isLoading={isLoading}
            groups={groups}
        />
    case TechTypes.GROUP:
        return <GroupCard
            isLoading={isLoading}
            statuses={statuses}
            brokers={brokers}
            setAddressModalData={setAddressModalData}
            item={item}
        />
    default:
        return <AppartmentCard
        isGroupsTreeLoading={isGroupsTreeLoading}
        isLoading={isLoading}
        statuses={statuses}
        types={types}
        brokers={brokers}
        groups={groups}
        orientations={orientations}
        features={features}
        wobblers={wobblers}
        isShowAddSelect={isShowAddSelect}
        handleShowSelect={handleShowSelect}
        propertyFields={propertyFields}
        item={item}
        setAddressModalData={setAddressModalData}
    />
    }

}

export default PropertyCard;