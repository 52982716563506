import React, {createContext, useEffect} from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import ReactGA from 'react-ga';

import LoginPage from './views/common/LoginPage';
import DefaultLayout from './containers/DefaultLayout/DefaultLayout';
import AppContextProvider from './contexts/AppContextProvider';
import {createBrowserHistory} from 'history';
import RegistrationPage from './views/common/RegistrationPage';
import ForgotPasswordPage from './views/common/ForgotPasswordPage';
import ChangePasswordPage from './views/common/ResetPasswordPage';

export const AppContext = createContext(({} as any));

const enableTracking = process.env.REACT_APP_ENABLE_TRACKING === 'true';

if (enableTracking) {
  const trakingId = process.env.REACT_APP_GA_TRAKING_ID || '';
  ReactGA.initialize(trakingId);
}

const history = createBrowserHistory();
history.listen(location => {
  if (enableTracking) {
    const pathname = location.pathname + (location.search ? location.search : '');
    ReactGA.set({ page: pathname });
    ReactGA.pageview(pathname);
  }
});

const App: React.FC = () => {

  useEffect(() => {
    if (enableTracking) {
      const pathname = window.location.pathname + (window.location.search ? window.location.search : '');
      ReactGA.pageview(pathname);
    }
  }, []);

  return (
    <Router history={history}>
      <AppContextProvider>
          <Switch>
            <Route exact path="/new-pass" name="Change password" component={ChangePasswordPage} />
            <Route exact path="/forgot-pass" name="Forgot password" component={ForgotPasswordPage} />
            <Route exact path="/registration" name="Registration" component={RegistrationPage} />
            <Route exact path="/login" name="Login Page" component={LoginPage} />
            <Route path="/" name="Home" component={DefaultLayout} />
          </Switch>
      </AppContextProvider>
    </Router>
  );
}

export default App;
