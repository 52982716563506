import React, {useContext, useEffect, useState} from 'react';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {Button, Dropdown, Menu, notification, Table} from 'antd';
import { getColumns } from './components/PropertiesColumns';
import ListPageServices from '../../services/PropertiesService';
import {ListContext} from '../../contexts/ListContextProvider';
import {AppContext} from '../../contexts/AppContextProvider';
import { PageContext } from '../../contexts/PageContextProvider';
import {NavLink} from 'react-router-dom';

import {LIST_TECH_TYPE} from '../../constants/actionTypes/listConstants';
import {PROPERTY} from '../../constants/actionTypes/pageConstants';

import PropertyTechTypes from '../../models/enums/PropertyTechTypes';
import { TechTypes } from '../../reducers/appReducer';
import { PropertyModel } from '../../models/PropertyModel';

const propertiesService = new ListPageServices();

interface MenuProps {
  setApartmentTechType: () => void;
  setBuildingTechType: () => void;
  setGroupTechType: () => void;
}

const menu = (props: MenuProps) => (
  <Menu>
    <Menu.Item>
      <NavLink to={`/property?type=${TechTypes.GROUP}`} onClick={props.setGroupTechType}>Project</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to={`/property?type=${TechTypes.BUILDING}`} onClick={props.setBuildingTechType}>Building</NavLink>
    </Menu.Item>
    <Menu.Item>
      <NavLink to={`/property?type=${TechTypes.APARTMENT}`} onClick={props.setApartmentTechType}>Apartment</NavLink>
    </Menu.Item>
  </Menu>
);

const initialBreadCrumbMap = {
  '/properties': 'Properties',
}

const ListPage = (props: any) => {
  const listServes = new ListPageServices();
  const [items, setItems] = useState<PropertyModel[]>([]);
  // const [group, setGroup] = useState(false);
  // const [building, setBuilding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const {list, listDispatch} = useContext(ListContext);
  const {app} = useContext(AppContext);
  const [page, pageDispatch] = useContext(PageContext);
  const [breadcrumbNameMap, setBreeadCrumbNameMap] = useState(initialBreadCrumbMap);
  const [isGeneratingCsv, setIsGeneratingCsv] = useState(false);

  useEffect(() => {
    getData();
    getBreadcrumbs();
  }, [props.history.location.pathname]);

  const setApartmentTechType = () => {
    listDispatch({
      type: LIST_TECH_TYPE,
      techType: app.techTypes.APARTMENT
    });
  };

  const setBuildingTechType = () => {
    listDispatch({
      type: LIST_TECH_TYPE,
      techType: app.techTypes.BUILDING
    });
  };

  const setGroupTechType = () => {
    listDispatch({
      type: LIST_TECH_TYPE,
      techType: app.techTypes.GROUP
    });
  };

  const setPage = (page: any) => {
    pageDispatch({
      type: PROPERTY,
      page
    });
  };

  const getNestedElement = (data: any) => {
    const id = data.id;
    const name = data.code;
    if(data.childrens.length) {
      setIsLoading(true);
      listServes.getNested(id)
        .then(data => {
          if (data.length !== 0) {
              props.history.push(`${props?.history?.location?.pathname}/${name}:${id}`);
          } else {
          notification.info({
            message: 'Info',
            description: 'No nested elements'
          });
        }
        })
        .catch(e => {
          notification.error({
            message: e.message,
          });
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      notification.info({
        message: 'Info',
        description: 'No nested elements'
      });
    }
  };

  const getBreadcrumbs = () => {
    const items = props.history.location.pathname.replace('/properties', '').split('/');
    setBreeadCrumbNameMap(
      items.slice(1).reduce((nameMap: any, curr: string, index: number) => ({
        ...nameMap,
        [[Object.keys(nameMap)[index], curr].join('/')]: curr.split(':').shift(),
      }), initialBreadCrumbMap)
    );
  };

  const keySetter = (data: any) => {
    let modified = [];
    for (let i = 0; i < data.length; i++) {
      data[i].key = data[i].code;
      modified.push(data[i])
    }
    return modified
  };

  const getData = () => {
    const id = props.history.location.pathname.split(':').pop();
    if (isNaN(Number(id))) {
      setIsLoading(true);
      listServes.getPropertyWithFilter(null, {or: {parent: null}}, null, null).then(data => {
        setItems(keySetter(data) as any);
        setIsLoading(false);
      });
    } else {
      setIsLoading(true);
      listServes.getNested(id).then(data => {
        const dataWithKeys: any = keySetter(data);
        if (data.length !== 0) {
          setItems(dataWithKeys);
        } else {
          notification.info({
            message: 'Info',
            description: 'No nested elements'
          });
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  };

  const deleteProperty = async (id: number) => {
    try {
      setIsLoading(true);
      await propertiesService.deleteProperty(id);
      getData();
    } catch (e) {
      notification.error(e.message ? e : {
        message: `Error while deleting the property`,
      });
    }
  }

  const exportToCSV = async () => {
    setIsGeneratingCsv(true);

    listServes.getCSVPropertyWithFilter({or: {deletedDate: null}}).then((csv) => {
      const link = document.createElement("a");
      link.href = csv;
      link.download = 'properties.csv';
      link.click();
      setIsGeneratingCsv(false);
      notification.success({ message: 'Csv generated.' });
    }).catch(() => {
      setIsGeneratingCsv(false);
      notification.error({ message: 'Error while generating csv.' });
    });
  };

  return (
    <>
      <HeaderTable title='Properties list' breadcrumb={breadcrumbNameMap}>
        <span>
          <Button className="primary-button" onClick={exportToCSV} loading={isGeneratingCsv} style={{ width: 100, marginRight: '10px' }} disabled={false}>Export CSV</Button>
          <Dropdown overlay={() => menu({
            setApartmentTechType,
            setBuildingTechType,
            setGroupTechType,
          })} placement="bottomRight">
            <Button type="primary" style={{ width: 100 }}>+ New</Button>
          </Dropdown>
        </span>
      </HeaderTable>
      <Table
        rowClassName={(record) => record.childrens.length ? 'has-children' : ''}
        columns={getColumns(deleteProperty)}
        loading={isLoading}
        dataSource={items}
        showHeader={true}
        pagination={{
          defaultPageSize: 10, 
          showSizeChanger: true, 
          pageSizeOptions: ['10', '30', '50', '100'] ,
          current: page.pageProp,
          onChange: (page => setPage(page))
        }}
        rowKey={record => record.id}
        onRow={(record: any) => ({
          onDoubleClick: () => getNestedElement(record)
        })}
      />
    </>
  );
};

export default ListPage;
