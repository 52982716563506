import React from 'react';
import {Card, Col, Form, Input, Row, Select, Switch, DatePicker} from 'antd';
import {InputInteger} from '../../../components/common/antdFormatter';
import { redirectToGoogleMaps } from '../../../helpers/OpenUrlInNewTab';
import {
  EyeOutlined,
  EditOutlined,
} from '@ant-design/icons';

const { Option } = Select;

interface GroupCardModel {
    isLoading: boolean,
    statuses: any[],
    brokers: any[],
    setAddressModalData: React.Dispatch<any>,
    item: any,
}

const GroupCard: React.VFC<GroupCardModel> = ({
    isLoading,
    statuses,
    brokers,
    setAddressModalData,
    item,
}) => {
    return <Card style={{marginBottom: '24px'}} loading={isLoading}>
    <Row gutter={12}>
      <Col span={12}>
        <Form.Item label="Status" name={["status", "code"]}>              
          <Select>
            {statuses.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
          </Select>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Code" name="code" 
          rules={[{required: true, message: 'Please input property code'}]}>
          <Input/>
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Price" name="price"rules={[
              {
                required: true,
                message: 'Please input property price',
              }]}>
          <InputInteger length={10} prefix={<span>₣</span>} suffix={<span>CHF</span>}/>
        </Form.Item>
      </Col>
      <Col span={9}>
        <Form.Item label="Broker:" name={["broker", "id"]}>
            <Select style={{width: '100%'}}>
              {brokers.map((item: any) => <Option key={item.id} value={item.id}>{[item.firstName, item.lastName].join(' ')}</Option>)}
            </Select>
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item label={<br/>} valuePropName="checked" name={["showBrokerInfo"]}>
          <Switch style={{width: '100%'}} checkedChildren="Show" unCheckedChildren="Hide"/>
        </Form.Item>
      </Col>
      <Col span={12}>
      <Form.Item label="Location" name={["address", "addressFull"]}>
          <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["address"])} />}
            {...(item?.address?.lat && item?.address?.lng ? {
              addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                item?.address?.lat,
                item?.address?.lng
              )} />
            } : {})} />
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item label="Availability date" name="availabilityDate">
            <DatePicker style={{width: '100%'}}/>
        </Form.Item>
      </Col>
    </Row>
  </Card>
}

export default GroupCard;