import HttpService from './common/httpService';
import buildQuery from 'odata-query';
import { SubscriberModel, SubscriberModelDto } from '../models/SubscriberModel';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class SubscribersService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  private createQuery(searchObjects?: any, pagingObject?: any) {
    let orderBy: any = null;
    let filter: any = {and: []};
    let top: any = null;
    let skip: any = null;
    let count: boolean = true;

    filter.and.push(
      'deletedDate eq null'
    );
    //Search part
    if (searchObjects) {
      if(searchObjects.fullName){
        const value = encodeURI(searchObjects.fullName.replace(/[/\\!@#$%^&*)(+=.,_-]/g, '').replace(/`/g, `'`).toLowerCase());
        filter.and.push(
          {[`tolower(fullName)`]: {contains: value}}
        );
      }
      if(searchObjects.email){
        const value = encodeURI(searchObjects.email);
        filter.and.push(
          {[`tolower(email)`]: {contains: value}}
        );
      }
      if(searchObjects.type){
        filter.and.push(
          {[`type`]: {eq: searchObjects.type}}
        );
      }
      if(searchObjects?.submitDateRange){
        filter.and.push({and: [
          {[`submitDate`]: {ge: searchObjects.submitDateRange[0].toISOString()}},
          {[`submitDate`]: {le: searchObjects.submitDateRange[1].toISOString()}}
        ]});
      }
    }

    // Paging part
    if (pagingObject) {
      top = pagingObject.sizePerPage;
      skip = (pagingObject.page - 1) * pagingObject.sizePerPage;
    }

    return buildQuery({filter, orderBy, top, skip, count});
  }

  public getItems(searchObjects: any, pagingObject: any) {
    const query = this.createQuery(searchObjects, pagingObject);
    return this.httpService.get(`${BASE_URL}subscribers${query}`);
  }

  public getItemsCsv(searchObjects: any) {
    const query = this.createQuery(searchObjects);
    return this.httpService.get(`${BASE_URL}subscribers/csv${query}`);
  }

  public getItem(id: number): Promise<SubscriberModel> {
    return this.httpService.get(`${BASE_URL}subscribers/${id}`);
  }

  public saveItem(values: SubscriberModelDto) {
    return values.id ?  this.httpService.put(`${BASE_URL}subscribers/${values.id}`, values) : this.httpService.post(`${BASE_URL}subscribers`, values);
  }

  public deleteItem(id: number) {
    return this.httpService.delete(`${BASE_URL}subscribers/${id}`, {});
  }
}

export default SubscribersService;
