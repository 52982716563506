import HttpService from './common/httpService';
import buildQuery from 'odata-query';
import { v4 as uuidv4 } from 'uuid';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

const toDataURL = (url: any) => fetch(url, {
  headers: {}
}).then(response => response.blob());

class BlogService {
  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public getBlog() {
    return this.httpService.get(`${BASE_URL}blog`);
  }

  public getBlogItemById(id: any) {
    return this.httpService.get(`${BASE_URL}blog/${id}`)
  }

  public saveBlogItem(values: any, id?: number) {
    return this.httpService.post(`${BASE_URL}blog`, values);
  }

  public async deleteImage(fileList: any[]) {
    return this.httpService.delete(`${BASE_URL}image`, fileList);
  }

  public async saveImage(fileList: any[]) {
    const formData = new FormData();

    formData.append('file', new File(
      [fileList[0]?.originFileObj], 
      `${uuidv4()}.${fileList[0]?.originFileObj?.name.split('.')[1]}`,
      { type: fileList[0]?.originFileObj.type, lastModified: fileList[0]?.originFileObj.lastModified}
    )); 

    return this.httpService.postFile(`${BASE_URL}image`, formData);
  }
}

export default BlogService;
