import HttpService from "./common/httpService";

interface RegistrationRequestPayload {
    login: string;
    firstName: string;
    lastName: string;
    password: string;
    token: string;
}

interface ConfirmTokenResponse { 
  createDate: string;
  email: string;
  lastUpdatedDate: string;
  tokenHash: string;
}

interface InviteResponse {
  email: string;
  reject_reason: string | null;
  status: string;
  _id: string;
}

interface ResetPasswordPayload {
  login: string;
  password: string;
  token: string;
}

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

class ConsumerRegistrationService {

  private httpService: HttpService;

  constructor() {
    this.httpService = new HttpService();
  }

  public async inviteConsumer(applicationId: string): Promise<InviteResponse> {
    return this.httpService.get(`${BASE_URL}invite/${applicationId}`);
  }

  public async remindConsumer(applicationId: string): Promise<InviteResponse> {
    return this.httpService.get(`${BASE_URL}consumer/remind/${applicationId}`);
  }

  public async confirmToken(token: string): Promise<ConfirmTokenResponse> {
    return this.httpService.get(`${BASE_URL}consumer/confirm/${token}`);
  }

  public async register(data: RegistrationRequestPayload): Promise<any> {
    return this.httpService.post(`${BASE_URL}consumer/registration`, data);
  }

  public async resetPassword(email: string): Promise<any> {
    return this.httpService.post(`${BASE_URL}consumer/resetPassword`, { email });
  }

  public async changePassword(data: ResetPasswordPayload): Promise<any> {
    return this.httpService.post(`${BASE_URL}consumer/changePassword`, data);
  }
}

export default ConsumerRegistrationService;