import React from 'react';
import {Layout} from 'antd';
import moment from 'moment';

const {Footer} = Layout;

const FooterComponent: React.FC = (props) => {
    const currentYear = moment().year();
    
    return(
        <Footer
            style={{
                textAlign: 'center',
                width: '100%' }}
        >
            (c) 2020-{currentYear} Created by EVA
        </Footer>
    );
};

export default FooterComponent;
