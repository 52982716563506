import HttpService from './common/httpService';
import { SessionsReportDataModel, UsersReportDataModel } from '../models/ReportDataModel';

const BASE_URL = process.env.REACT_APP_BASE_REQUEST;

interface ReportArgs {
    startDate: string, // string date in format YYYY-MM-DD
    endDate: string, // string date in format YYYY-MM-DD
    dimension: string, 
    metrics: string[]
}

class GoogleAnalyticsService {
    private httpService: HttpService;

    constructor() {
        this.httpService = new HttpService();
    }

    public getReport(request: ReportArgs) {
        return this.httpService.post(`${BASE_URL}ga-report`, request);
    }

    /**
     * 
     * @param startDate string date in format YYYY-MM-DD
     * @param endDate string date in format YYYY-MM-DD
     * @returns {Promise} Promise object represents Google Analytics Reporting Data
     */
    public getUsersReport(startDate: string, endDate: string): Promise<UsersReportDataModel[]> {
        return this.getReport({ startDate, endDate, dimension: 'date', metrics: ['activeUsers', 'sessions']});
    }

    /**
     * 
     * @param startDate string date in format YYYY-MM-DD
     * @param endDate string date in format YYYY-MM-DD
     * @returns {Promise} Promise object represents Google Analytics Reporting Data
     */
    public getSessionsReport(startDate: string, endDate: string): Promise<SessionsReportDataModel[]> {
        return this.getReport({ startDate, endDate, dimension: 'sessionMedium', metrics: ['sessions']});
    }
}

export default GoogleAnalyticsService;
