import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import HeaderTable from '../../components/HeaderTable/HeaderTable';
import {
    Button,
    Row,
    Col,
    Card,
    Form,
    Input,
    Select,
    DatePicker,
    notification,
    Statistic,
    Tabs,
    Modal,
    Spin,
    Upload,
    Divider,
    Tooltip,
} from 'antd';
import ApplicationService from '../../services/ApplicationService';
import DictionariesServices from '../../services/DictionariesServices';
import { redirectToGoogleMaps } from '../../helpers/OpenUrlInNewTab';
import { calculateFields } from './components/ApplicationsCalculations';
import { objectToValidFormat } from '../../helpers/objectToValidFormat';
import { validateMessages } from '../../constants/validateMessages';
import AddressModal from './../../components/AddressModal/AddressModal';
import { BACKEND_DATE_TIME_FORMAT } from './../../constants/formats';
import {
    EyeOutlined,
    EditOutlined,
    FileDoneOutlined,
    CheckOutlined,
    CloseOutlined,
    ClockCircleOutlined,
    UploadOutlined,
    DeleteOutlined,
    BellOutlined,
} from '@ant-design/icons';
import { AppContext } from '../../contexts/AppContextProvider';
import CustomerService from '../../services/CustomerService';
import { ApplicationHomebuyerDocument, ApplicationHomebuyerDocumentFile, ApplicationModel } from '../../models/ApplicationModel';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile } from 'antd/lib/upload/interface';
import { getHomebuyerFullname } from '../../helpers/getHomebuyerFullname';
import { useTranslation } from 'react-i18next';
import i18n, { getLanguage } from '../../utils/i18n';
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';

const { Option } = Select;
const { TabPane } = Tabs;

export const getEffortRateColor = (value: number) =>
    value <= 33 ? "green" : value <= 45 ? "#ffba00" : "#ee220c";

export const getPropertyToRevenueRatioColor = (value: number) =>
    value <= 5.5 ? "green" : value <= 6.5 ? "#ffba00" : "#ee220c";

export const getDepositRateColor = (value: number) =>
    value >= 10 ? "green" : "#ee220c";


const service = new ApplicationService();
const dictionariesService = new DictionariesServices();
const customerService = new CustomerService();

const tooltipProps: TooltipPropsWithTitle = {
    placement: 'right',
    title: '',
    overlayClassName: 'document-status-tooltip',
}

const checkAllHomebuyerDocumentsUpload = (documents: ApplicationHomebuyerDocument[]) => {
    if(!documents || documents.length === 0)
        return false;


    for(const doc of documents) {
        if(doc.files.length === 0)
            return false;
    }

    return true;
}

const CustomerApplicationPage: React.VFC<any> = (props) => {
    const { app } = useContext(AppContext);
    const { user } = app;
    const { t, i18n: { language } } = useTranslation();
    const [form] = Form.useForm();

    const [item, setItem] = useState(null as any);

    const [dictionaries, setDictionaries] = useState({
        applicationPropertyClassification: [],
        applicationPropertyConstructionType: [],
        applicationPropertyType: [],
        applicationCivilStatus: [],
        applicationHomebuyerMatrimonialPropertyRegimes: [],
        applicationHomebuyerSalutations: [],
        applicationHomebuyerEmploymentStatuses: [],
    });

    const [isSaving, setIsSaving] = useState(false);

    const [addressModalData, setAddressModalData] = useState(null as any);

    useEffect(() => {
        form.setFieldsValue(item);
    }, [item]);

    useEffect(() => {
        getDictionaries();
    }, [language]);


    const applicationId = props?.match?.params?.id;
    useEffect(() => {
        if (applicationId) {
            getData();
            return;
        }

        if (user) {
            customerService.getCustomerApplications(user.login)
                .then(data => {
                    if (data[0])
                        props.history.push(`/application/${data[0].id}`)
                })
                .catch((e) => {
                    console.error(e);
                })
        }

    }, [applicationId, user, language])

    const getData = () => {
        service.getItem(applicationId).then(data => {
            const calculatedFields = calculateFields(data);
            setItem({
                ...data,
                ...calculatedFields.application,
                homebuyer: {
                    ...data.homebuyer,
                    birthdate: data?.homebuyer?.birthdate ? moment(data?.homebuyer?.birthdate) : null,
                    childrens: data?.homebuyer?.childrens?.map((child: ApplicationModel['homebuyer']) => {
                        return { ...child, birthdate: child.birthdate ? moment(child.birthdate) : null }
                    })
                },
                finances: {
                    ...data.finances,
                    ...calculatedFields.finances
                },
                eva: {
                    ...data.eva,
                    ...calculatedFields.eva
                },
                mortgage: {
                    ...data.mortgage,
                    ...calculatedFields.mortgage
                }
            });
            getLanguage(data.language);
        })
        .catch((e) => {
            if (e.status === 404)
                props.history.push('/application')
            else {
                notification.error(e.message ? e : {
                    message: t('application.message.error.whileLoading'),
                  });
            }
        });
    };

    const getDictionaries = () => {
        Object.keys(dictionaries).forEach(key => dictionariesService.getDictionary(key).then((data) => setDictionaries((prev: any) => ({ ...prev, [key]: data }))));
    };

    const onFinish = (values: any) => {
        setIsSaving(true);
        saveItem(values).then((data) => {
            if (!applicationId) {
                props.history.push(`/application/${data.id}`);
            } else {
                getData();
            }
            notification.success({ message: t('application.message.updated') });
            setIsSaving(false);
        }).catch(() => {
            notification.error({ message: t('application.message.error.updated') });
            setIsSaving(false);
        });
    }

    const saveItem = (values: any) => service.saveItem(objectToValidFormat({
        ...(item?.id ? { id: item.id } : {}),
        ...values,
        property: { ...item.property, ...values?.property, address: { ...item?.property?.address, ...values?.property?.address } },
        homebuyer: {
            ...item.homebuyer,
            ...values.homebuyer,
            birthdate: values?.homebuyer?.birthdate ? moment(values?.homebuyer?.birthdate).toISOString() : null,
            address: { ...item?.homebuyer?.address, ...values?.homebuyer?.address },
            employerAddress: { ...item?.homebuyer?.employerAddress, ...values?.homebuyer?.employerAddress },
            childrens: item.homebuyer?.childrens?.map((child: any, index: number) => {
                const valueChild = values.homebuyer?.childrens && values.homebuyer?.childrens[index];
                return {
                    ...child,
                    ...valueChild,
                    birthdate: valueChild?.birthdate ? moment(valueChild.birthdate).toISOString() : null,
                    address: { ...child.address, ...valueChild?.address },
                    employerAddress: { ...child.employerAddress, ...valueChild?.employerAddress },
                    documents: child.documents || [],
                }
            }) || [],
        },
        finances: { ...item.finances, ...values.finances },
        eva: { ...item.eva, ...values.eva },
        mortgage: { ...item.mortgage, ...values.mortgage },
        rates: { ...item.rates, ...values.rates },
        otherInformation: { ...item.otherInformation, ...values.otherInformation }
    }), applicationId);

    const updateItem = (newItem: any) => {
        const formItem = form.getFieldsValue();
        setItem({
            ...item,
            ...formItem,
            eva: {
                ...item.eva,
                ...formItem.eva,
            },
            finances: {
                ...item.finances,
                ...formItem.finances,
            },
            mortgage: {
                ...item.mortgage,
                ...formItem.mortgage,
            },
            property: {
                ...item.property,
                ...formItem.property,
            },
            rates: {
                ...item.rates,
                ...formItem.rates
            },
            otherInformation: {
                ...item.otherInformation,
                ...formItem.otherInformation,
            },
            homebuyer: {
                ...item.homebuyer,
                ...formItem.homebuyer,
                childrens: newItem.homebuyer?.childrens?.map((child: any, index: number) => {
                    const valueChild = formItem.homebuyer?.childrens && formItem.homebuyer.childrens[index];
                    return {
                        ...child,
                        ...valueChild,
                        birthdate: valueChild?.birthdate ? moment(valueChild.birthdate) : null,
                        address: { ...child.address, ...valueChild?.address },
                        employerAddress: { ...child.employerAddress, ...valueChild?.employerAddress },
                        documents: child.documents || [],
                    }
                }) || [],
                documents: newItem.homebuyer?.documents,
            }
        })
    }

    const [isUploading, setIsUploading] = useState(false);
    const handleUploadChange = (document: ApplicationHomebuyerDocument) => (data: UploadChangeParam<UploadFile<any>>) => {
        if (item?.id) {
            const { file, fileList } = data;
            if (fileList.find((x) => x.name === file.name)) {
                uploadFile(item.id, document.id, file)
            } else {
                deleteFile(file as any, document);
            }

        }
    }

    const uploadFile = (applicationId: ApplicationModel['id'], documentId: ApplicationHomebuyerDocument['id'], file: UploadFile<any>) => {
        setIsUploading(true);
        const formData = new FormData();
        formData.append('file', file as any);
        service.uploadDocumentFile(applicationId, documentId, formData)
            .then(() => {
                return service.getItem(item.id)
            })
            .then((res) => {
                updateItem(res);
                notification.success({
                    message: t('application.message.fileUploaded'),
                });

                const homebuyer = (res as ApplicationModel | undefined)?.homebuyer;
                if(homebuyer) {
                    const secondHomebyuer: ApplicationModel['homebuyer'] | undefined = homebuyer.childrens[0];
                    if(secondHomebyuer) {
                        if(checkAllHomebuyerDocumentsUpload(homebuyer.documents) && checkAllHomebuyerDocumentsUpload(secondHomebyuer.documents)) {
                            Modal.success({
                                title: t("application.message.allFilesUploaded.title"),
                                content: t("application.message.allFilesUploaded.content"),
                            });
                        }
                    } else {
                        if(checkAllHomebuyerDocumentsUpload(homebuyer.documents)) {
                            Modal.success({
                                title: t("application.message.allFilesUploaded.title"),
                                content: t("application.message.allFilesUploaded.content"),
                            });
                        }
                    }
                }
            })
            .catch((e) => {
                console.error(e);
                notification.error({
                    message: e.message || t('application.message.error.fileUploaded')
                })
            })
            .finally(() => {
                setIsUploading(false);
            })
    }

    const deleteFile = (file: ApplicationHomebuyerDocumentFile, document: ApplicationHomebuyerDocument) => {
        setIsUploading(true);
        service.deleteDocumentFile(file.id)
            .then(() => {
                return service.getItem(item.id)
            })
            .then((res) => {
                updateItem(res);
                notification.success({
                    message: t('application.message.fileDeleted'),
                })
            })
            .catch((e) => {
                notification.error({
                    message: e.message || t('application.message.error.fileDeleted')
                })
            })
            .finally(() => {
                setIsUploading(false);
            })
    }

    const handleFileDeleteButton = (file: ApplicationHomebuyerDocumentFile, document: ApplicationHomebuyerDocument) => () => {
        deleteFile(file, document);
    }


    const handleFileDownload = (file: ApplicationHomebuyerDocumentFile) => () => {
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);

        service.getDocumentFile(file.doName)
            .then(response => {
                let objectUrl = window.URL.createObjectURL(response);

                anchor.href = objectUrl;
                anchor.download = file.name;
                anchor.click();

                window.URL.revokeObjectURL(objectUrl);
                document.body.removeChild(anchor);
            })
    }

    const [isNotifyingBroker, setIsNotifyngBroker] = useState(false);
    const notifyBroker = () => {
        if (item?.id) {
            setIsNotifyngBroker(true);
            service.notifyBroker(item.id)
                .then(() => {
                    notification.success({
                        message: t('application.message.agentReceive'),
                    })
                })
                .catch(() => {
                    notification.error({
                        message: t('application.message.error.agentReceive'),
                    })
                })
                .finally(() => {
                    setIsNotifyngBroker(false);
                })
        }
    }

    const multipleHomebuyersExist = !!(item?.homebuyer?.childrens && item.homebuyer.childrens[0]);

    const getDocumentStatusIcon = (isValidated: boolean | null) => {
        if (isValidated === null) {
            return (
                <Tooltip {...tooltipProps} title={t('application.documents.status.waiting')}>
                    <ClockCircleOutlined style={{ color: "#949494", fontSize: '20px' }} />
                </Tooltip>
            );
        }

        if (!isValidated) {
            return (
                <Tooltip {...tooltipProps} title={t('application.documents.status.rejected')}>
                    <CloseOutlined style={{ color: "#EF6961", fontSize: '20px' }} />
                </Tooltip>
            );
        }

        return (
            <Tooltip {...tooltipProps} title={t('application.documents.status.approved')}>
                <CheckOutlined style={{ color: "#63A995", fontSize: '20px' }} />
            </Tooltip>
        );
    }

    return (
        <div>
            <Spin spinning={!applicationId}>
                <AddressModal
                    item={item}
                    path={addressModalData}
                    handleCancel={() => setAddressModalData(null)}
                    handleOk={(item) => {
                        setItem(item);
                        setAddressModalData(null);
                    }} />
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={item}
                    name="nest-messages"
                    validateMessages={validateMessages}
                    onFinishFailed={() => notification.warning({ message: t('application.message.fillAllFields') })}
                    // onValuesChange={(field, newItem) => {
                    //     const calculatedFields = calculateFields({
                    //         ...newItem,
                    //         eva: { ...(newItem?.eva || {}) },
                    //         rates: { ...(newItem?.rates || {}) },
                    //         mortgage: { ...(newItem?.mortgage || {}) },
                    //         property: { ...(newItem?.property || {}) },
                    //         finances: { ...(newItem?.finances || {}) }
                    //     });
                    //     form.setFieldsValue({
                    //         ...newItem,
                    //         ...calculatedFields.application,
                    //         finances: { ...(newItem?.finances || {}), ...calculatedFields.finances },
                    //         eva: { ...(newItem?.eva || {}), ...calculatedFields.eva },
                    //         mortgage: { ...(newItem?.mortgage || {}), ...calculatedFields.mortgage },
                    //         rates: { ...(newItem.rates || {}) }
                    //     });
                    // }}
                    onFinish={onFinish}
                >
                    <HeaderTable
                        title={`${applicationId ? t('application.edit') : t('application.create')} ${t('application')}`} breadcrumb={{
                            '/applications': t('Applications'),
                            '/application': `${applicationId ? t('application.edit') : t('application.create')} ${t('application')}`
                        }}
                    >
                        <div>
                            <Button name="submitButton" loading={isSaving} type="primary" htmlType="submit">
                                {t('application.save')}
                            </Button>
                        </div>
                    </HeaderTable>
                    <Row gutter={30}>
                        <Col span={5}>
                            <div className="application-right-card">
                                <Card title={
                                    <Row>
                                        <Col span={4}>{item?.id ? `№${item.id}` : ''}</Col>
                                        <Col span={20} className='text-right application-generated-input-label'>
                                            {!!item?.createdDate && moment(item.createdDate).format(BACKEND_DATE_TIME_FORMAT)}
                                        </Col>
                                    </Row>
                                } type="inner" loading={!item && !!applicationId} className='application-main-card'>
                                    <Card.Grid style={{ width: '100%' }} hoverable={false}>
                                        <Row >
                                            <Col span={24}>
                                                <Form.Item shouldUpdate style={{ marginBottom: '0px' }}>
                                                    <Statistic value={[form.getFieldValue(["homebuyer", "firstName"]), form.getFieldValue(["homebuyer", "lastName"])].filter(Boolean).join(' ') || 'N/A'} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={["property", "price"]}>
                                                    <Statistic groupSeparator={'\''} prefix="CHF" title={t('application.propertyPrice')} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name={["eva", "totalWithFee"]} style={{ marginBottom: 0 }}>
                                                    <Statistic groupSeparator={'\''} prefix="CHF" title={t('application.monthlyPayment')} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card.Grid>
                                </Card>
                            </div>
                        </Col>
                        <Col span={19}>
                            <div className="application-tab-container">
                                <Tabs
                                    defaultActiveKey="prospect"
                                    type="card"
                                >
                                    <TabPane tab={t('application.prospect')} key="prospect" forceRender>
                                        <Card bordered={false} loading={!item && !!applicationId}>
                                            <Row gutter={30}>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label={t('application.prospect.salutation')} name={["homebuyer", "salutation", "code"]}>
                                                            <Select >
                                                                {dictionaries.applicationHomebuyerSalutations.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.firstName')} name={["homebuyer", "firstName"]}>
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.lastName')} name={["homebuyer", "lastName"]}>
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.birthDate')} name={["homebuyer", "birthdate"]}>
                                                            <DatePicker style={{ width: '100%' }}  />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item name={["homebuyer", "email"]} label={t('application.prospect.email')}rules={[{ type: 'email' }]}>
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.phone')} name={["homebuyer", "phone"]}>
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.livingAddress')} name={["homebuyer", "address", "addressFull"]}>
                                                            <Input  addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "address"])} />}
                                                                {...(item?.homebuyer?.address?.lat && item?.homebuyer?.address?.lng ? {
                                                                    addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                        item?.homebuyer?.address?.lat,
                                                                        item?.homebuyer?.address?.lng
                                                                    )} />
                                                                } : {})} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label={t('application.prospect.nationality')} name={["homebuyer", "nationality"]} >
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.permit')} name={["homebuyer", "permit"]} >
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.civilStatus')} name={["homebuyer", "civilStatus", "code"]}>
                                                            <Select allowClear >
                                                                {dictionaries.applicationCivilStatus.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.matrimonialRegime')} name={["homebuyer", "matrimonialPropertyRegime", "code"]}>
                                                            <Select allowClear >
                                                                {dictionaries.applicationHomebuyerMatrimonialPropertyRegimes.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label={t('application.prospect.employmentStatus')} name={["homebuyer", "employmentStatus", "code"]}>
                                                            <Select >
                                                                {dictionaries.applicationHomebuyerEmploymentStatuses.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.occupation')} name={["homebuyer", "occupation"]}>
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.employer')} name={["homebuyer", "employer"]}>
                                                            <Input  />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.prospect.employerAddress')} name={["homebuyer", "employerAddress", "addressFull"]}>
                                                            <Input  addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "employerAddress"])} />}
                                                                {...(item?.homebuyer?.employerAddress?.lat && item?.homebuyer?.employerAddress?.lng ? {
                                                                    addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                        item?.homebuyer?.employerAddress?.lat,
                                                                        item?.homebuyer?.employerAddress?.lng
                                                                    )} />
                                                                } : {})} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                {/* <Col span={24}>
                                                    <div className="application-col">
                                                        <Form.Item label="Notes" name={["homebuyer", "notes"]}>
                                                            <Input.TextArea />
                                                        </Form.Item>
                                                    </div>
                                                </Col> */}
                                            </Row>
                                        </Card>
                                        {item?.homebuyer?.childrens && item?.homebuyer?.childrens[0] &&
                                            <>
                                                <Divider></Divider>
                                                <Card bordered={false}>
                                                    <Row gutter={30}>
                                                        <Col span={6}>
                                                            <div className="application-col">
                                                                <Form.Item label={t('application.prospect.salutation')} name={["homebuyer", "childrens", 0, "salutation", "code"]}>
                                                                    <Select >
                                                                        {dictionaries.applicationHomebuyerSalutations.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.firstName')} name={["homebuyer", "childrens", 0, "firstName"]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.lastName')} name={["homebuyer", "childrens", 0, "lastName"]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.birthDate')} name={["homebuyer", "childrens", 0, "birthdate"]}>
                                                                    <DatePicker style={{ width: '100%' }}  />
                                                                </Form.Item>
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <div className="application-col">
                                                                <Form.Item name={["homebuyer", "childrens", 0, "email"]} label={t('application.prospect.email')} rules={[{ type: 'email' }]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.phone')} name={["homebuyer", "childrens", 0, "phone"]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                {/* <Form.Item label="Living address" name={["homebuyer", "childrens", 0, "address", "addressFull"]}>
                                                            <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "childrens", "[0]", "address"])} />}
                                                                {...(item?.homebuyer?.address?.lat && item?.homebuyer?.address?.lng ? {
                                                                    addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                        item?.homebuyer?.address?.lat,
                                                                        item?.homebuyer?.address?.lng
                                                                    )} />
                                                                } : {})} />
                                                        </Form.Item> */}
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <div className="application-col">
                                                                <Form.Item label={t('application.prospect.nationality')} name={["homebuyer", "childrens", 0, "nationality"]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.permit')} name={["homebuyer", "childrens", 0, "permit"]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.civilStatus')} name={["homebuyer", "childrens", 0, "civilStatus", "code"]}>
                                                                    <Select allowClear >
                                                                        {dictionaries.applicationCivilStatus.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.matrimonialRegime')} name={["homebuyer", "childrens", 0, "matrimonialPropertyRegime", "code"]}>
                                                                    <Select allowClear >
                                                                        {dictionaries.applicationHomebuyerMatrimonialPropertyRegimes.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                                    </Select>
                                                                </Form.Item>
                                                            </div>
                                                        </Col>
                                                        <Col span={6}>
                                                            <div className="application-col">
                                                                <Form.Item label={t('application.prospect.employmentStatus')} name={["homebuyer", "childrens", 0, "employmentStatus", "code"]}>
                                                                    <Select >
                                                                        {dictionaries.applicationHomebuyerEmploymentStatuses.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                                    </Select>
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.occupation')} name={["homebuyer", "childrens", 0, "occupation"]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                <Form.Item label={t('application.prospect.employer')} name={["homebuyer", "childrens", 0, "employer"]}>
                                                                    <Input  />
                                                                </Form.Item>
                                                                {/* <Form.Item label={t('application.prospect.employerAddress')} name={["homebuyer", "childrens", 0, "employerAddress", "addressFull"]}>
                                                            <Input addonAfter={<EditOutlined onClick={() => setAddressModalData(["homebuyer", "childrens", "[0]", "employerAddress"])} />}
                                                                {...(item?.homebuyer?.employerAddress?.lat && item?.homebuyer?.employerAddress?.lng ? {
                                                                    addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                        item?.homebuyer?.employerAddress?.lat,
                                                                        item?.homebuyer?.employerAddress?.lng
                                                                    )} />
                                                                } : {})} />
                                                        </Form.Item> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </>
                                        }
                                    </TabPane>
                                    <TabPane tab={t('application.property')} key="property" forceRender>
                                        <Card bordered={false} loading={!item && !!applicationId}>
                                            <Row gutter={30}>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label={t('application.property.price')} name={["property", "price"]} rules={[{ required: true, message: 'Please enter property price!' }]}>
                                                            <Input disabled={true} className="input-align-right" prefix={<span>CHF</span>} {...(item?.property?.property?.id ? {
                                                                addonAfter: <EyeOutlined onClick={() => null} />
                                                            } : {})} />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.type')} name={["property", "type", "code"]}>
                                                            <Select allowClear disabled={true}>
                                                                {dictionaries.applicationPropertyType.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.residenceClassification')} name={["property", "classification", "code"]}>
                                                            <Select allowClear disabled={true}>
                                                                {dictionaries.applicationPropertyClassification.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.constructionType')} name={["property", "constructionType", "code"]}>
                                                            <Select allowClear disabled={true}>
                                                                {dictionaries.applicationPropertyConstructionType.map((status: any) => <Option key={status.code} value={status.code}>{status.description}</Option>)}
                                                            </Select>
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.address')} name={["property", "address", "addressFull"]}>
                                                            <Input disabled={true} addonAfter={<EditOutlined />}
                                                                {...(item?.property?.address?.lat && item?.property?.address?.lng ? {
                                                                    addonBefore: <EyeOutlined onClick={() => redirectToGoogleMaps(
                                                                        item?.property?.address?.lat,
                                                                        item?.property?.address?.lng
                                                                    )} />
                                                                } : {})} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label={t('application.property.url')} name={["property", "url"]}>
                                                            <Input disabled={true} />
                                                        </Form.Item>
                                                        {/* <Form.Item label="Planned investments after" name={["property", "plannedInvestmentsAfter"]}>
                                                            <Input />
                                                        </Form.Item> */}
                                                        <Form.Item label={t('application.property.yearBuilt')} name={["property", "yearBuilt"]}>
                                                            <Input disabled={true} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        <Form.Item label={t('application.property.surfaceNet')} name={["property", "surfaceNet"]}>
                                                            <Input disabled={true} className="input-align-right" suffix={<span>m<sup>2</sup></span>} />
                                                        </Form.Item>
                                                        <Form.Item className="input-align-right" label={t('application.property.surfaceTerrace')} name={["property", "surfaceTerrace"]}>
                                                            <Input disabled={true} suffix={<span>m<sup>2</sup></span>} />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.surfaceTotal')} name={["property", "surfaceTotal"]}>
                                                            <Input disabled={true} className="input-align-right" suffix={<span>m<sup>2</sup></span>} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                                <Col span={6}>
                                                    <div className="application-col">
                                                        {/* <Form.Item label="Property segment" name={["property", "propertySegment"]}>
                                                            <Input />
                                                        </Form.Item> */}
                                                        <Form.Item label={t('application.property.bathrooms')} name={["property", "bathrooms"]}>
                                                            <Input disabled={true} />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.bedrooms')} name={["property", "bedrooms"]}>
                                                            <Input disabled={true} />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.numberOfApts')} name={["property", "numberOfAptsInBuilding"]}>
                                                            <Input disabled={true} />
                                                        </Form.Item>
                                                        <Form.Item label={t('application.property.condominiumValueProportion')} name={["property", "condominiumValueProportion"]}>
                                                            <Input disabled={true} />
                                                        </Form.Item>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </TabPane>
                                    <TabPane tab={t('application.documents')} key="documents">
                                        <Card bordered={false} loading={(!item && !!applicationId)}>
                                            <Spin spinning={isUploading}>
                                                <Row gutter={40} >
                                                    <Col span={24}>
                                                        <div className="application-col">
                                                            <Row style={{ justifyContent: 'space-between', marginBottom: '1em' }}>
                                                                <Col xs={24} lg={18}>
                                                                    <Row>
                                                                        <Col span={24}><h2 className='application-documents-title'>{t('application.documents.notifyAgent.title')}</h2></Col>
                                                                        {t('application.documents.notifyAgent.text')}
                                                                    </Row>
                                                                </Col>
                                                                <Col xs={24} lg={6}>
                                                                    <Row justify="end">
                                                                        <Button
                                                                            className="primary-button"
                                                                            icon={<BellOutlined />}
                                                                            loading={isNotifyingBroker}
                                                                            onClick={notifyBroker}
                                                                        >
                                                                            {t('application.documents.notifyAgent')}
                                                                        </Button>
                                                                    </Row>
                                                                </Col>
                                                            </Row>
                                                            {!multipleHomebuyersExist && (item?.homebuyer?.documents as ApplicationHomebuyerDocument[])?.sort((a, b) => a.type.ord - b.type.ord).map((document) => {
                                                                return (
                                                                    <Row className="application-document-files">
                                                                        <Row className="application-document-title">
                                                                            <Col className="application-document-title-name">{document.type.description}</Col>
                                                                        </Row>
                                                                        <Col span={22}>
                                                                            <Upload
                                                                                beforeUpload={() => false}
                                                                                onChange={handleUploadChange(document)}
                                                                                fileList={document.files?.map(file => ({ uid: file.id.toString(), size: file.ord, name: file.name, type: '', id: file.id, url: file.doName })) || []}
                                                                                showUploadList={false}
                                                                            >
                                                                                <Button type="primary" icon={<UploadOutlined />}>{t('application.documents.clickToUpload')}</Button>
                                                                            </Upload>
                                                                        </Col>
                                                                        <Col span={2}>
                                                                            {!!document.files?.length &&
                                                                                <Col className="application-document-title-buttons">
                                                                                    {getDocumentStatusIcon(document.isValidated)}
                                                                                </Col>
                                                                            }
                                                                        </Col>
                                                                        {!!document.files?.length &&
                                                                            <Col span={24}>
                                                                                {document.files.map(file => {
                                                                                    return (
                                                                                        <Row className="application-document-file">
                                                                                            <Col className="application-document-file-title">
                                                                                                <FileDoneOutlined />
                                                                                                <span className="name" onClick={handleFileDownload(file)}>{file.name}</span>
                                                                                            </Col>
                                                                                            {!document.isValidated &&
                                                                                                <Col className="application-document-file-buttons">
                                                                                                    <Button
                                                                                                        // type="primary"
                                                                                                        size="large"
                                                                                                        className="delete"
                                                                                                        icon={<DeleteOutlined />}
                                                                                                        onClick={handleFileDeleteButton(file, document)}
                                                                                                    />
                                                                                                </Col>
                                                                                            }
                                                                                        </Row>
                                                                                    );
                                                                                })}
                                                                            </Col>
                                                                        }
                                                                    </Row>
                                                                )
                                                            })}
                                                            {multipleHomebuyersExist &&
                                                                <Row gutter={8}>
                                                                    <Col xs={24} md={24} lg={12}>
                                                                        <Card
                                                                            bordered={false}
                                                                            title={getHomebuyerFullname(item.homebuyer)}
                                                                        >
                                                                            {(item.homebuyer.documents as ApplicationHomebuyerDocument[])?.sort((a, b) => a.type.ord - b.type.ord).map((document, index) => {
                                                                                return (
                                                                                    <Row className="application-document-files">
                                                                                      {index !== 0 && 
                                                                                        <Divider className='application-document-divider'/>
                                                                                      }
                                                                                        <Row className="application-document-title">
                                                                                            <Col className="application-document-title-name">{document.type.description}</Col>
                                                                                        </Row>
                                                                                        <Col span={22}>
                                                                                            <Upload
                                                                                                beforeUpload={() => false}
                                                                                                onChange={handleUploadChange(document)}
                                                                                                fileList={document.files?.map(file => ({ uid: file.id.toString(), size: file.ord, name: file.name, type: '', id: file.id, url: file.doName })) || []}
                                                                                                showUploadList={false}
                                                                                            >
                                                                                                <Button type="primary" style={document.isValidated ? {background: '#63a995b8', backgroundColor: '#63a995b8'}: {}} icon={<UploadOutlined />}>{t("application.documents.clickToUpload")}</Button>
                                                                                            </Upload>
                                                                                        </Col>
                                                                                        <Col span={2}>
                                                                                            {( document.isValidated !== null || !!document.files?.length) &&
                                                                                                <Col className="application-document-title-buttons">
                                                                                                    {getDocumentStatusIcon(document.isValidated)}
                                                                                                </Col>
                                                                                            }
                                                                                        </Col>
                                                                                        {!!document.files?.length &&
                                                                                            <Col span={24}>
                                                                                                {document.files.map(file => {
                                                                                                    return (
                                                                                                        <Row className="application-document-file">
                                                                                                            <Col className="application-document-file-title">
                                                                                                                <FileDoneOutlined />
                                                                                                                <span className="name" onClick={handleFileDownload(file)}>{file.name}</span>
                                                                                                            </Col>
                                                                                                            {!document.isValidated &&
                                                                                                                <Col className="application-document-file-buttons">
                                                                                                                    <Button
                                                                                                                        // type="primary"
                                                                                                                        size="large"
                                                                                                                        className="delete"
                                                                                                                        icon={<DeleteOutlined />}
                                                                                                                        onClick={handleFileDeleteButton(file, document)}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                            }
                                                                                                        </Row>
                                                                                                    );
                                                                                                })}
                                                                                            </Col>
                                                                                        }
                                                                                    </Row>
                                                                                )
                                                                            })}
                                                                        </Card>
                                                                    </Col>
                                                                    <Col xs={24} md={24} lg={12}>
                                                                        <Card
                                                                            bordered={false}
                                                                            title={getHomebuyerFullname(item.homebuyer.childrens[0])}
                                                                        >
                                                                            {(item.homebuyer.childrens[0].documents as ApplicationHomebuyerDocument[])?.sort((a, b) => a.type.ord - b.type.ord).map((document, index) => {
                                                                                return (
                                                                                    <Row className="application-document-files">
                                                                                        {index !== 0 && 
                                                                                          <Divider className='application-document-divider'/>
                                                                                        }
                                                                                        <Row className="application-document-title">
                                                                                            <Col className="application-document-title-name">{document.type.description}</Col>
                                                                                        </Row>
                                                                                        <Col span={22}>
                                                                                            <Upload
                                                                                                beforeUpload={() => false}
                                                                                                onChange={handleUploadChange(document)}
                                                                                                fileList={document.files?.map(file => ({ uid: file.id.toString(), size: file.ord, name: file.name, type: '', id: file.id, url: file.doName })) || []}
                                                                                                showUploadList={false}
                                                                                            >
                                                                                                <Button type="primary" icon={<UploadOutlined />}>{t("application.documents.clickToUpload")}</Button>
                                                                                            </Upload>
                                                                                        </Col>
                                                                                        <Col span={2}>
                                                                                            {( document.isValidated !== null || !!document.files?.length) &&
                                                                                                <Col className="application-document-title-buttons">
                                                                                                    {getDocumentStatusIcon(document.isValidated)}
                                                                                                </Col>
                                                                                            }
                                                                                        </Col>
                                                                                        {!!document.files?.length &&
                                                                                            <Col span={24}>
                                                                                                {document.files.map(file => {
                                                                                                    return (
                                                                                                        <Row className="application-document-file">
                                                                                                            <Col className="application-document-file-title">
                                                                                                                <FileDoneOutlined />
                                                                                                                <span className="name" onClick={handleFileDownload(file)}>{file.name}</span>
                                                                                                            </Col>
                                                                                                            {!document.isValidated &&
                                                                                                                <Col className="application-document-file-buttons">
                                                                                                                    <Button
                                                                                                                        // type="primary"
                                                                                                                        size="large"
                                                                                                                        className="delete"
                                                                                                                        icon={<DeleteOutlined />}
                                                                                                                        onClick={handleFileDeleteButton(file, document)}
                                                                                                                    />
                                                                                                                </Col>
                                                                                                            }
                                                                                                        </Row>
                                                                                                    );
                                                                                                })}
                                                                                            </Col>
                                                                                        }
                                                                                    </Row>
                                                                                )
                                                                            })}
                                                                        </Card>
                                                                    </Col>
                                                                </Row>
                                                            }
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Spin>
                                        </Card>
                                    </TabPane>
                                </Tabs>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </div>
    );
};

export default CustomerApplicationPage;