import React, { useEffect, useState } from 'react';
import { Form, Button, Input, Card, Row, Col, notification, Spin, Alert } from 'antd';
import logo from '../../assets/images/logo.svg';
import loginPic from '../../assets/images/blog-big.svg';
import ConsumerRegistrationService from '../../services/RegistrationService';
import { UserOutlined } from '@ant-design/icons';

const Logo = () => (
    <img src={logo} alt="Logo" className="logo-login" />
);

const consumerRegistrationService = new ConsumerRegistrationService();

interface ForgotPasswordFields {
    email: string
}

const ForgotPasswordPage: React.FC = (props: any) => {
    const [form] = Form.useForm<ForgotPasswordFields>();

    const [isLoading, setIsLoading] = useState(false);
    const [isRequestSucceed, setIsRequestSucceed] = useState(false);

    const handleSubmit = (values: ForgotPasswordFields) => {
        setIsLoading(true);
        consumerRegistrationService.resetPassword(values.email)
            .then(() => {
                setIsRequestSucceed(true);
                notification.success({
                    message: 'Success',
                });
                // props.history.push('/login');
            })
            .catch(e => {
                notification.error({
                    message: 'Error',
                    description: e.message,
                })
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <Row
            justify="center"
            align="middle"
            className="login-page"
        >
            <div className="login-page_in">
                <h1>Welcome to the Eva</h1>
                <Spin spinning={isLoading}>
                <div className="section-login_row">
                    <figure className="section-login_pic"><img src={loginPic} alt="" /></figure>
                        <Col className="section-login_form">
                            <Card className="section-login_card" title={<Logo />}>
                                {isRequestSucceed ?
                                    <Alert
                                        message={
                                            <p>
                                                We've sent you an email with a secure link to restore your password.<br />
                                                Please check your emails to continue
                                            </p>
                                        }
                                    />
                                    :
                                    <Form
                                        onFinish={handleSubmit}
                                        className="login-form"
                                        form={form}
                                    >
                                        <div>
                                            <p>Please, provide your email to continue</p>
                                            <Form.Item
                                                name="email"
                                                rules={[{ required: true, message: 'Please input your email!' }]}
                                            >
                                                <Input
                                                    prefix={
                                                        <UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />
                                                    }
                                                    placeholder="Email" />
                                            </Form.Item>
                                        </div>
                                        <div style={{ height: '100%', float: 'right' }}>
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                className="login-form-button"
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </Form>
                                }
                            </Card>
                        </Col>
                    </div>
                </Spin>
            </div>
        </Row>
    );
};

export default ForgotPasswordPage;
